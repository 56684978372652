import { POST, getRefreshToken } from './HttpService';

export const isTokenExpired = (expiryTime, thresholdSeconds = 600) => {
	const currentTimestamp = Math.floor(Date.now() / 1000);
	return expiryTime - currentTimestamp < thresholdSeconds;
};

export const decodeJwt = (token) => {
	const decodedToken = JSON.parse(atob(token.split('.')[1]));
	return decodedToken?.exp ? decodedToken.exp : null;
};

export const checkAndUpdateAccessToken = (token) => {
	if (token) {
		const expiryTime = decodeJwt(token);
		if (expiryTime && isTokenExpired(expiryTime)) {
			try {
				POST({
					url: 'accounts/refresh',
					data: { token: getRefreshToken() },
				})
					.then((response) => {
						const { data: { accessToken, refreshToken } = {} } = response;
						localStorage.setItem('app_token', accessToken);
						localStorage.setItem('refresh_token', refreshToken);
					})
					.catch((error) => {
						if (error.config && error.config.url.includes('accounts/refresh')) {
							localStorage.clear();
							window.location.href = '/';
						}
					});
			} catch (error) {
				// Handle token refresh error
			}
		}
	}
};

export const isChatAuthExpired = (token) => {
	if (token) {
		const expiryTime = decodeJwt(token);
		return expiryTime && isTokenExpired(expiryTime);
	}
};
