/**
 * Any one of the values should be present in the .env file
 * [REACT_APP_PROJECT_ENV=dev | qa | uat | prod]
 * If not present, dev instance will be used
 */

export const instances = {
	dev: 'dev',
	qa: 'qa',
	staging: 'uat',
	prod: 'prod',
	poc: 'poc',
};

const config = {
	[instances.dev]: {
		API_ENDPOINT: 'https://diabetes-api-dev.joinwecare.dev/api/v1/',
		WEB_ENDPOINT: 'https://wecare-dev.joinwecare.dev/api/v1/',
		MIXPANEL_TOKEN: '22f8205fe6ef1ddf1fd3cf10e73022f8',
	},
	[instances.qa]: {
		API_ENDPOINT: 'https://diabetes-api-qa.joinwecare.dev/api/v1/',
		WEB_ENDPOINT: 'https://wecare-qa.joinwecare.dev/api/v1/',
		MIXPANEL_TOKEN: '7bf9288a6cf8d73654831672b3df951a',
	},
	[instances.prod]: {
		API_ENDPOINT: 'https://wc-api.joinwecare.app/api/v1/',
		WEB_ENDPOINT: 'https://wecare-uat.joinwecare.dev/api/v1/',
		MIXPANEL_TOKEN: '138120b8ef919996f75b80eee571fd2f',
	},
};

export default {
	currentInstance: process.env.REACT_APP_PROJECT_ENV || instances.dev,
	// sentryDSN: process.env.REACT_APP_SENTRY_DSN || '',
	// MIXPANEL_TOKEN:process.env.REACT_APP_MIX_PANEL_TOKEN || '',
	mixPanelToken() {
		return config[this.currentInstance].MIXPANEL_TOKEN;
	},
	latestAppVersion: '1.4.b138',
	baseURL() {
		return config[this.currentInstance].API_ENDPOINT;
	},
};
