import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const TabsNavLink = styled.li`
	margin: 0;
	list-style: none;
	font-size: 16px;
	color: ${colors.Grey87};
	min-height: 40px;
	padding: 0px 10px;
	cursor: pointer;
	transition: ease-in all 0.4s;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 860px) {
		padding: 0px 23px;
	}
	@media screen and (max-width: 768px) {
		font-size: 14px;
		padding: 0px 15px;
	}
	span {
		margin-left: 8px;
	}
	&:after {
		transition: ease-in all 0.4s;
		width: 0px;
		height: 2px;
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		background: ${colors.BlueLight};
	}
	&:hover {
		color: ${colors.BlueBase};
		&:after {
			width: 100%;
		}
	}
	${({ active }) =>
		active &&
		`color: ${colors.BlueBase};
		font-family: ${fontMedium.familyName};
		font-weight:500;
		&:after{
			width: 100%;
		}
	`}
	${({ spacingList }) =>
		spacingList &&
		`padding: 0 25px;
		white-space: nowrap;
	`}
`;

export default TabsNavLink;
