import React from 'react';

import { vitalKeys } from '../../../helpers/Constants';

import normal from '../../../stories/assets/images/icons/Minus.svg';
import upArrow from '../../../stories/assets/images/icons/Up-Arrow.svg';
import downArrow from '../../../stories/assets/images/icons/Down-Arrow.svg';

export const progressLookUpKeys = {
	low: 'LOW',
	high: 'HIGH',
	normal: 'NORMAL',
};

/**
 * The keys LOW, HIGH, NORMAL are same as that in API
 */
export const progress = {
	[progressLookUpKeys.low]: {
		src: downArrow,
		alt: 'going down',
	},
	[progressLookUpKeys.high]: {
		src: upArrow,
		alt: 'going up',
	},
	[progressLookUpKeys.normal]: {
		src: normal,
		alt: 'normal',
	},
};

function UnitType(unitType) {
	// Here "celcius" is spelled wrongly; Once the spelling is changed in API then change here as well.
	if (unitType === 'celcius')
		return (
			<>
				<sup style={{ fontSize: '11px' }}>o</sup>C
			</>
		);
	if (unitType === 'fahrenheit')
		return (
			<>
				<sup style={{ fontSize: '11px' }}>o</sup>F
			</>
		);
	if (unitType === 'mt') return '/mt';
	if (unitType === 'percentage') return '%';
	return unitType;
}

export function NormalValues(vital) {
	if ([vitalKeys.systolic, vitalKeys.diastolic].includes(vital)) return `90/60mmHg - 130/90mmHg`;
	if (vitalKeys.temperature === vital)
		return (
			<>
				93{UnitType('fahrenheit')} - 100
				{UnitType('fahrenheit')}
			</>
		);
	if (vitalKeys.heartRate === vital) return `60bpm - 100bpm`;
	if (vitalKeys.respiratoryRate === vital) return `10${UnitType('mt')} - 25${UnitType('mt')}`;
	if (vitalKeys.oxygenSaturation === vital)
		return `95${UnitType('percentage')} - 100${UnitType('percentage')}`;
}

export default UnitType;
