import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getWebNotifications } from '../../dashboard.selectors';
import { getNotifications, resetReadStatus } from '../../../Login/login.store';

import Notifications from '.';
import bellIcon from '../../../../stories/assets/images/icons/bell-icon.svg';
import { Button } from '../../../../stories';

function BellIcon() {
	const dispatch = useDispatch();
	const wrapperRef = useRef(null);
	const [notificationView, toggleNotificationView] = useState(false);

	const { data: { unreadCount = 0 } = {} } = useSelector(({ AuthStore }) =>
		getWebNotifications(AuthStore)
	);

	function closeNotification(value) {
		toggleNotificationView(value);
	}

	function resetCount() {
		if (unreadCount > 0) {
			dispatch(getNotifications({ params: { markRead: true } }, true));
		}
		closeNotification(!notificationView);
	}

	function markAllAsRead() {
		dispatch(resetReadStatus());
	}

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			closeNotification(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false);
		return () => document.removeEventListener('click', handleClickOutside, false);
	}, []);

	return (
		<div
			// role="button"
			// tabIndex={0}
			style={{ position: 'relative', margin: '0 25px' }}

			// aria-hidden="true"
		>
			<div ref={wrapperRef}>
				<Button iconButtonSMTL iconPrimary margin="0 5px 0 0" onClick={() => resetCount()}>
					<img src={bellIcon} alt="bellIcon" style={{ cursor: 'pointer' }} />
				</Button>

				{unreadCount > 0 && (
					<div
						style={{
							position: 'absolute',
							top: 0,
							right: 0,
							borderRadius: '50%',
							height: '9px',
							width: '9px',
							background: '#E86764',
						}}
					/>
				)}

				{notificationView && (
					<Notifications
						closeNotification={(val) => closeNotification(val)}
						markAllAsRead={() => markAllAsRead()}
					/>
				)}
			</div>
		</div>
	);
}

export default BellIcon;
