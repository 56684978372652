import styled from 'styled-components';
import { colors } from '../colors';

const ChatRetry = styled.button`
	width: auto;
	padding: 5px 10px;
	border-radius: 14px;
	border: 0px;
	background: rgba(63, 63, 63, 0.5);
	color: ${colors.White};
	font-size: 11px;
	line-height: 13px;
	position: absolute;
	right: 20px;
	bottom: 20px;
	transition: ease all 0.4s;
	&:hover {
		background: rgba(63, 63, 63, 0.8);
	}
	span {
		padding-left: 3px;
	}
`;

export default ChatRetry;
