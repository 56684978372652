import { delay, GET, POST, PUT, DELETE } from '../../service/HttpService';

import { snackBarErrorTypes } from '../../helpers/Constants';
import { extractMessageFrom } from '../../helpers/Utilities';

import { isUnauthorized } from '../Login/login.store';
import { showSnackbar } from '../../components/Snackbar/snackbar.store';
import {
	changingDietPlan,
	saveDietPlan,
	getPatientDetails,
} from '../HealthVault/health.vault.store';

const FILE = 'FILE://DIET_MANAGEMENT';

export const stateKeys = {
	dietPlans: 'dietPlans',
	selectedDietPlan: 'selectedDietPlan',
};

const generateActionType = (type) => `${FILE}/${type}`;

const FETCH_DIET_PLANS = generateActionType('FETCH_DIET_PLANS');
const SAVE_FETCHED_DIET_PLANS = generateActionType('SAVE_FETCHED_DIET_PLANS');
const FETCH_SELECTED_DIET_PLAN = generateActionType('FETCH_SELECTED_DIET_PLAN');
const SAVE_SELECTED_DIET_PLAN_DETAILS = generateActionType('SAVE_SELECTED_DIET_PLAN_DETAILS');
const CREATE_NEW_PLAN = generateActionType('CREATE_NEW_PLAN');
const RE_ORDER_DIET_PLAN = generateActionType('RE_ORDER_DIET_PLAN');

export function reOrderMeals(meals) {
	return {
		type: RE_ORDER_DIET_PLAN,
		meals,
	};
}

function createPlan(isCreating) {
	return {
		type: CREATE_NEW_PLAN,
		isCreating,
	};
}

export function newPlanCreation() {
	return async function (dispatch) {
		const key = stateKeys.selectedDietPlan;
		dispatch({ type: FETCH_SELECTED_DIET_PLAN, key, isFetching: true });
		await delay(1000);
		dispatch(createPlan(true));
		dispatch({ type: FETCH_SELECTED_DIET_PLAN, key, isFetching: false });
	};
}

async function getDietPlans({ params = {} }) {
	const dietConfig = { params: { limit: 1000, offset: 0, ...params }, url: `/diets` };
	const response = await GET(dietConfig);
	return response;
}

async function getPlan({ id }) {
	const dietConfig = { url: `/diets/${id}` };
	const response = await GET(dietConfig);
	return response;
}

async function updatePlan({ id, data }) {
	const dietConfig = { data, url: `/diets/${id}` };
	const response = await PUT(dietConfig);
	return response;
}

async function deleteMealPlan({ id, detailsId }) {
	const dietConfig = { url: `/diets/${id}/details/${detailsId}` };
	const response = await DELETE(dietConfig);
	return response;
}

async function createNewDietPlan({ data }) {
	const dietConfig = { data, url: `/diets` };
	const response = await POST(dietConfig);
	return response;
}

async function deleteDietPlan({ id }) {
	const dietConfig = { url: `/diets/${id}` };
	const response = await DELETE(dietConfig);
	return response;
}

async function cloneDietPlan({ data }) {
	const dietConfig = { data, url: `/diets/clone` };
	const response = await POST(dietConfig);
	return response;
}

async function shareTemplates({ data }) {
	const dietConfig = { data, url: `/diets/share` };
	const response = await POST(dietConfig);
	return response;
}

async function linkDiet({ data }) {
	const dietConfig = { data, url: `/diets/assign` };
	const response = await POST(dietConfig);
	return response;
}

async function unAssignDiet({ id, patientId }) {
	const dietConfig = { url: `diets/${id}/patients/${patientId}` };
	const response = await DELETE(dietConfig);
	return response;
}

export function getDietPlan({ ...args }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: FETCH_SELECTED_DIET_PLAN,
				key: stateKeys.selectedDietPlan,
				isFetching: true,
			});
			const { data = {} } = await getPlan(args);
			dispatch({
				type: SAVE_SELECTED_DIET_PLAN_DETAILS,
				key: stateKeys.selectedDietPlan,
				data,
			});
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({
				type: FETCH_SELECTED_DIET_PLAN,
				key: stateKeys.selectedDietPlan,
				isFetching: false,
			});
		}
	};
}

export function getDietPlansList({
	defaultDietId,
	showLoader = true,
	doLoadDefault,
	isCopyOperation = false,
}) {
	return async function (dispatch) {
		try {
			if (showLoader)
				dispatch({ type: FETCH_DIET_PLANS, key: stateKeys.dietPlans, isFetching: true });
			const { data = {} } = await getDietPlans({});
			dispatch({ type: SAVE_FETCHED_DIET_PLANS, key: stateKeys.dietPlans, data });
			const { results = [] } = data;
			if (isCopyOperation) {
				dispatch(getDietPlan({ id: results[0].isDefault ? results[1].id : results[0].id }));
			} else if (!doLoadDefault && results.length > 0) {
				dispatch(getDietPlan({ id: defaultDietId || results[0].id }));
			}
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			if (showLoader)
				dispatch({ type: FETCH_DIET_PLANS, key: stateKeys.dietPlans, isFetching: false });
		}
	};
}

export function updateDietPlan({ id, data, save }) {
	const saveDiet = {
		type: FETCH_DIET_PLANS,
		key: stateKeys.dietPlans,
	};
	const saveMeal = {
		type: FETCH_SELECTED_DIET_PLAN,
		key: stateKeys.selectedDietPlan,
	};
	const actionType = save ? saveDiet : saveMeal;
	return async function (dispatch) {
		try {
			dispatch({
				...actionType,
				isFetching: true,
			});
			await updatePlan({ id, data });
			dispatch(getDietPlansList({ defaultDietId: id, showLoader: save }));
			dispatch(
				showSnackbar(
					save ? 'Diet section updated' : 'Meal section updated',
					snackBarErrorTypes.success
				)
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
			dispatch({
				...actionType,
				isFetching: false,
			});
		}
	};
}

export function deleteMeal({ id, detailsId }) {
	return async function (dispatch) {
		try {
			dispatch({
				type: FETCH_SELECTED_DIET_PLAN,
				key: stateKeys.selectedDietPlan,
				isFetching: true,
			});
			await deleteMealPlan({ id, detailsId });
			dispatch(getDietPlan({ id }));
			dispatch(showSnackbar('Meal plan has been deleted', snackBarErrorTypes.success));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({
				type: FETCH_SELECTED_DIET_PLAN,
				key: stateKeys.selectedDietPlan,
				isFetching: false,
			});
			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
		}
	};
}

export function createDietPlan(args, firstDiet) {
	const forFirstPlanOnly = {
		type: FETCH_DIET_PLANS,
		key: stateKeys.dietPlans,
	};
	const forNewPlans = {
		type: FETCH_SELECTED_DIET_PLAN,
		key: stateKeys.selectedDietPlan,
	};
	const actionType = firstDiet ? forFirstPlanOnly : forNewPlans;

	return async function (dispatch) {
		try {
			dispatch({ ...actionType, isFetching: true });
			const { data: { id } = {} } = await createNewDietPlan(args);
			dispatch(getDietPlansList({ defaultDietId: id }));
			dispatch(showSnackbar('Created new diet plan', snackBarErrorTypes.success));
			dispatch({ ...actionType, isFetching: false });
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch({ ...actionType, isFetching: false });

			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
		}
	};
}

export function removeDietPlan({ id }) {
	const dietPlanType = {
		type: FETCH_DIET_PLANS,
		key: stateKeys.dietPlans,
	};
	return async function (dispatch) {
		try {
			dispatch({ ...dietPlanType, isFetching: true });
			await deleteDietPlan({ id });
			dispatch(getDietPlansList({}));
			dispatch(showSnackbar('Diet plan has been deleted', snackBarErrorTypes.success));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
			dispatch({ ...dietPlanType, isFetching: false });
		}
	};
}

export function copyDietPlan({ data }) {
	const dietPlanType = {
		type: FETCH_DIET_PLANS,
		key: stateKeys.dietPlans,
	};
	return async function (dispatch) {
		try {
			dispatch({ ...dietPlanType, isFetching: true });
			await cloneDietPlan({ data });
			dispatch(getDietPlansList({ isCopyOperation: true }));
			dispatch(showSnackbar('Clone has been created', snackBarErrorTypes.success));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
			dispatch({ ...dietPlanType, isFetching: false });
		}
	};
}

export function registerTemplates({ data }) {
	const dietPlanType = {
		type: FETCH_DIET_PLANS,
		key: stateKeys.dietPlans,
	};
	return async function (dispatch) {
		try {
			dispatch({ ...dietPlanType, isFetching: true });
			await shareTemplates({ data });
			dispatch(getDietPlansList({}));
			dispatch(showSnackbar('Diet plan has been shared', snackBarErrorTypes.success));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch(showSnackbar(extractMessageFrom(error), snackBarErrorTypes.error));
			dispatch({ ...dietPlanType, isFetching: false });
		}
	};
}

export function linkDietToPatient({ patientId, dietPlanId }) {
	return async function (dispatch) {
		try {
			dispatch(changingDietPlan(true));
			await linkDiet({ data: { patientId, dietPlanId } });
			const { data = {} } = await getPatientDetails({ patientId });
			dispatch(saveDietPlan(data));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch(changingDietPlan(false));
		}
	};
}

export function unAssignDietPlan({ patientId, id }) {
	return async function (dispatch) {
		try {
			dispatch(changingDietPlan(true));
			await unAssignDiet({ patientId, id });
			const { data = {} } = await getPatientDetails({ patientId });
			dispatch(saveDietPlan(data));
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;
			dispatch(changingDietPlan(false));
		}
	};
}

function getInitialState() {
	return {
		[stateKeys.dietPlans]: {
			fetching: true,
			details: {},
		},
		createNewPlan: {
			isCreating: false,
		},
		[stateKeys.selectedDietPlan]: {
			fetching: true,
			details: {},
		},
	};
}

function reducer(state = getInitialState(), action = null) {
	switch (action.type) {
		case RE_ORDER_DIET_PLAN:
			return {
				...state,
				[stateKeys.selectedDietPlan]: {
					...state[stateKeys.selectedDietPlan],
					details: {
						...state[stateKeys.selectedDietPlan].details,
						repast: action.meals || [],
					},
				},
			};
		case CREATE_NEW_PLAN:
			return {
				...state,
				createNewPlan: {
					isCreating: action.isCreating,
				},
				[stateKeys.selectedDietPlan]: {
					...state[stateKeys.selectedDietPlan],
					details: {},
				},
			};
		case FETCH_DIET_PLANS:
		case FETCH_SELECTED_DIET_PLAN:
			return {
				...state,
				[action.key]: {
					...state[action.key],
					fetching: action.isFetching,
				},
			};
		case SAVE_FETCHED_DIET_PLANS:
		case SAVE_SELECTED_DIET_PLAN_DETAILS:
			return {
				...state,
				[action.key]: {
					...state[action.key],
					fetching: false,
					details: action.data,
				},
			};
		default:
			return state;
	}
}

export default reducer;
