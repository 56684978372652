import { progressLookUpKeys } from '../Miscellaneous';
import { vitalKeys, BP } from '../../../../helpers/Constants';

const highLabel = 'Highest reading';
const lowLabel = 'Lowest reading';

export function resolver(sys, dia) {
	switch (true) {
		case sys > 130:
			return progressLookUpKeys.high;
		case sys < 90:
			return progressLookUpKeys.low;
		default: {
			if (dia >= 60 && dia <= 90) return progressLookUpKeys.normal;
			if (dia > 90) return progressLookUpKeys.high;
			if (dia < 90) return progressLookUpKeys.low;
		}
	}
}

export function BpStatus(data = []) {
	if (data && !Array.isArray(data)) {
		return {
			lookupKey: BP,
		};
	}

	const systolicData = data.find((item) => item.lookupKey === vitalKeys.systolic);
	const diastolicData = data.find((item) => item.lookupKey === vitalKeys.diastolic);

	if (systolicData && diastolicData) {
		return {
			lookupKey: BP,
			value: `${systolicData.value}/${diastolicData.value}`,
			unitType: `${systolicData.unitType}`,
			reportDate: `${systolicData.reportDate}`,
			normalcy: `${resolver(Number(systolicData.value), Number(diastolicData.value))}`,
			isUpdated: systolicData.isUpdated,
			updatedAt: `${systolicData.updatedAt}`,
		};
	}
	return {
		lookupKey: BP,
	};
}

export function convertToFahrenheit({ value, unitType, roundOf }) {
	if (unitType === 'celcius') return roundOf((value * 9) / 5 + 32, 1);

	return roundOf(value, 1);
}

export function formatDate(value) {
	return `0${value}`.slice(-2);
}

function unitMapper({ vitalKeys, vital }) {
	if (vitalKeys.temperature === vital) return 'f';
	if (vitalKeys.heartRate === vital) return 'bpm';
	if (vitalKeys.respiratoryRate === vital) return '/mt';
	if (vitalKeys.oxygenSaturation === vital) return '%';
}

function sortData(entries = [], vital = '') {
	entries.sort((vitalsOne, vitalsTwo) => {
		const one = vitalsOne.vitals.find((item) => item.lookupKey === vital);
		const two = vitalsTwo.vitals.find((item) => item.lookupKey === vital);
		if (one && two) return one.value - two.value;
		return true;
	});

	return entries;
}

const getSystolicLess = (sysDataMin, sysDataMax) => {
	let sysData = null;

	if (sysDataMin[0] && sysDataMax[0]) {
		sysData =
			new Date(sysDataMin[0].date).getTime() <= new Date(sysDataMax[0].date).getTime()
				? sysDataMin[0]
				: sysDataMax[0];
		if (sysData.vitals.find((item) => item.lookupKey === vitalKeys.systolic)) {
			const sysDataVal =
				new Date(sysDataMin[0].date).getTime() <= new Date(sysDataMax[0].date).getTime()
					? sysDataMin[0]
					: sysDataMax[0];

			return sysDataVal.vitals.find((item) => item.lookupKey === vitalKeys.systolic).value;
		}
		return '';
	}
	return '';
};

const getSystolicGreater = (sysDataMin, sysDataMax) => {
	let sysData = null;

	if (sysDataMin[0] && sysDataMax[0]) {
		sysData =
			new Date(sysDataMin[0].date).getTime() > new Date(sysDataMax[0].date).getTime()
				? sysDataMin[0]
				: sysDataMax[0];
		if (sysData.vitals.find((item) => item.lookupKey === vitalKeys.systolic)) {
			const sysDataVal =
				new Date(sysDataMin[0].date).getTime() > new Date(sysDataMax[0].date).getTime()
					? sysDataMin[0]
					: sysDataMax[0];

			return sysDataVal.vitals.find((item) => item.lookupKey === vitalKeys.systolic).value;
		}
		return '';
	}
	return '';
};

const getDiastolicLess = (sysDataMin, sysDataMax) => {
	let sysData = null;

	if (sysDataMin[0] && sysDataMax[0]) {
		sysData =
			new Date(sysDataMin[0].date).getTime() <= new Date(sysDataMax[0].date).getTime()
				? sysDataMin[0]
				: sysDataMax[0];
		if (sysData.vitals.find((item) => item.lookupKey === vitalKeys.diastolic)) {
			const sysDataVal =
				new Date(sysDataMin[0].date).getTime() <= new Date(sysDataMax[0].date).getTime()
					? sysDataMin[0]
					: sysDataMax[0];

			return sysDataVal.vitals.find((item) => item.lookupKey === vitalKeys.diastolic).value;
		}
		return '';
	}
	return '';
};

const getDiastolicGreater = (sysDataMin, sysDataMax) => {
	let sysData = null;

	if (sysDataMin[0] && sysDataMax[0]) {
		sysData =
			new Date(sysDataMin[0].date).getTime() > new Date(sysDataMax[0].date).getTime()
				? sysDataMin[0]
				: sysDataMax[0];
		if (sysData.vitals.find((item) => item.lookupKey === vitalKeys.diastolic)) {
			const sysDataVal =
				new Date(sysDataMin[0].date).getTime() > new Date(sysDataMax[0].date).getTime()
					? sysDataMin[0]
					: sysDataMax[0];

			return sysDataVal.vitals.find((item) => item.lookupKey === vitalKeys.diastolic).value;
		}
		return '';
	}
	return '';
};

function getObjectsForBp({
	month,
	entriesForSys = [],
	entriesForDia = [],
	vitalKeys,
	dateFormatter,
	dateSeparatorConfig,
}) {
	const measurementUnit = 'mmHg';

	if (entriesForSys.length === 1) {
		return [
			{
				name: vitalKeys.systolic,
				data: [
					{
						category: month,
						hoverLabel: dateFormatter(entriesForSys[0].date, dateSeparatorConfig.d_m),
						value: entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
							? entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic).value
							: '',
						max: {
							[vitalKeys.systolic]: {
								at: dateFormatter(entriesForSys[0].date, dateSeparatorConfig.defaultRecentDatetime),
								label: highLabel,
								value: `${
									entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
										? entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												.value
										: ''
								}/${
									entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
										? entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												.value
										: ''
								}`,
							},
							[vitalKeys.diastolic]: {
								at: dateFormatter(entriesForDia[0].date, dateSeparatorConfig.defaultRecentDatetime),
								label: highLabel,
								value: `${
									entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
										? entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												.value
										: ''
								}/${
									entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
										? entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												.value
										: ''
								}`,
							},
						},
						measurementUnit,
					},
				],
			},
			{
				name: vitalKeys.diastolic,
				data: [
					{
						category: month,
						hoverLabel: dateFormatter(entriesForSys[0].date, dateSeparatorConfig.d_m),
						value: entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
							? entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic).value
							: '',
						max: {
							[vitalKeys.systolic]: {
								at: dateFormatter(entriesForSys[0].date, dateSeparatorConfig.defaultRecentDatetime),
								label: highLabel,
								value: `${
									entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
										? entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												.value
										: ''
								}/${
									entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
										? entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												.value
										: ''
								}`,
							},
							[vitalKeys.diastolic]: {
								at: dateFormatter(entriesForDia[0].date, dateSeparatorConfig.defaultRecentDatetime),
								label: highLabel,
								value: `${
									entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
										? entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												.value
										: ''
								}/${
									entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
										? entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												.value
										: ''
								}`,
							},
						},
						measurementUnit,
					},
				],
			},
		];
	}
	if (entriesForSys.length > 1) {
		const sysDataMin = entriesForSys
			.filter((sys) => {
				const { vitals = [] } = sys;
				const firstSysValue = vitals.find((item) => item.lookupKey === vitalKeys.systolic)
					? vitals.find((item) => item.lookupKey === vitalKeys.systolic).value
					: null;
				return (
					firstSysValue &&
					entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic) &&
					entriesForSys[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic).value ===
						firstSysValue
				);
			})
			.sort((vitalsOne, vitalsTwo) => vitalsOne.reportDate - vitalsTwo.reportDate)
			.reverse();
		const sysDataMax = entriesForSys
			.filter((sys) => {
				const { vitals = [] } = sys;
				const firstSysValue = vitals.find((item) => item.lookupKey === vitalKeys.systolic)
					? vitals.find((item) => item.lookupKey === vitalKeys.systolic).value
					: null;
				return (
					firstSysValue &&
					entriesForSys[entriesForSys.length - 1].vitals.find(
						(item) => item.lookupKey === vitalKeys.systolic
					) &&
					entriesForSys[entriesForSys.length - 1].vitals.find(
						(item) => item.lookupKey === vitalKeys.systolic
					).value === firstSysValue
				);
			})
			.sort((vitalsOne, vitalsTwo) => vitalsOne.reportDate - vitalsTwo.reportDate)
			.reverse();
		const diaDataMin = entriesForDia
			.filter((sys) => {
				const { vitals = [] } = sys;
				const firstDiaValue = vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
					? vitals.find((item) => item.lookupKey === vitalKeys.diastolic).value
					: null;
				return (
					firstDiaValue &&
					entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic) &&
					entriesForDia[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic).value ===
						firstDiaValue
				);
			})
			.sort((vitalsOne, vitalsTwo) => vitalsOne.reportDate - vitalsTwo.reportDate)
			.reverse();
		const diaDataMax = entriesForDia
			.filter((sys) => {
				const { vitals = [] } = sys;
				const firstDiaValue = vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
					? vitals.find((item) => item.lookupKey === vitalKeys.diastolic).value
					: null;
				return (
					firstDiaValue &&
					entriesForDia[entriesForDia.length - 1].vitals.find(
						(item) => item.lookupKey === vitalKeys.diastolic
					) &&
					entriesForDia[entriesForDia.length - 1].vitals.find(
						(item) => item.lookupKey === vitalKeys.diastolic
					).value === firstDiaValue
				);
			})
			.sort((vitalsOne, vitalsTwo) => vitalsOne.reportDate - vitalsTwo.reportDate)
			.reverse();

		return [
			{
				name: vitalKeys.systolic,
				data: [
					{
						category: month,
						hoverLabel: sysDataMin[0]
							? dateFormatter(sysDataMin[0].date, dateSeparatorConfig.d_m)
							: '',
						value: getSystolicLess(sysDataMin, sysDataMax),
						min: {
							[vitalKeys.systolic]: {
								at: sysDataMin[0]
									? dateFormatter(sysDataMin[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: sysDataMin[0]
									? `${
											sysDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? sysDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											sysDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? sysDataMin[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: lowLabel,
							},
							[vitalKeys.diastolic]: {
								at: diaDataMin[0]
									? dateFormatter(diaDataMin[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMin[0]
									? `${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMin[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: lowLabel,
							},
						},
						max: {
							[vitalKeys.systolic]: {
								at: sysDataMax[0]
									? dateFormatter(sysDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: sysDataMax[0]
									? `${
											sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? sysDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: highLabel,
							},
							[vitalKeys.diastolic]: {
								at: diaDataMax[0]
									? dateFormatter(diaDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMax[0]
									? `${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: highLabel,
							},
						},
						measurementUnit,
					},
					{
						category: month,
						hoverLabel: sysDataMax[0]
							? dateFormatter(sysDataMax[0].date, dateSeparatorConfig.d_m)
							: '',
						value: getSystolicGreater(sysDataMin, sysDataMax),
						min: {
							[vitalKeys.systolic]: {
								at: sysDataMax[0]
									? dateFormatter(sysDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: sysDataMax[0]
									? `${
											sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? sysDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: lowLabel,
							},
							[vitalKeys.diastolic]: {
								at: diaDataMax[0]
									? dateFormatter(diaDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMax[0]
									? `${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: lowLabel,
							},
						},
						max: {
							[vitalKeys.systolic]: {
								at: sysDataMax[0]
									? dateFormatter(sysDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: sysDataMax[0]
									? `${
											sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											sysDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? sysDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: highLabel,
							},
							[vitalKeys.diastolic]: {
								at: diaDataMax[0]
									? dateFormatter(diaDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMax[0]
									? `${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: highLabel,
							},
						},
						measurementUnit,
					},
				],
			},
			{
				name: vitalKeys.diastolic,
				data: [
					{
						category: month,
						hoverLabel: diaDataMin[0]
							? dateFormatter(diaDataMin[0].date, dateSeparatorConfig.d_m)
							: '',
						value: getDiastolicLess(diaDataMin, diaDataMax),
						min: {
							[vitalKeys.systolic]: {
								at: diaDataMin[0]
									? dateFormatter(diaDataMin[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMin[0]
									? `${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMin[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: lowLabel,
							},
							[vitalKeys.diastolic]: {
								at: diaDataMin[0]
									? dateFormatter(diaDataMin[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMin[0]
									? `${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMin[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: lowLabel,
							},
						},
						max: {
							[vitalKeys.systolic]: {
								at: diaDataMin[0]
									? dateFormatter(diaDataMin[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMin[0]
									? `${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMin[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: highLabel,
							},
							[vitalKeys.diastolic]: {
								at: diaDataMin[0]
									? dateFormatter(diaDataMin[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMin[0]
									? `${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMin[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMin[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: highLabel,
							},
						},
						measurementUnit,
					},
					{
						category: month,
						hoverLabel: diaDataMax[0]
							? dateFormatter(diaDataMax[0].date, dateSeparatorConfig.d_m)
							: '',
						value: getDiastolicGreater(diaDataMin, diaDataMax),
						min: {
							[vitalKeys.systolic]: {
								at: diaDataMax[0]
									? dateFormatter(diaDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMax[0]
									? `${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: lowLabel,
							},
							[vitalKeys.diastolic]: {
								at: diaDataMax[0]
									? dateFormatter(diaDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMax[0]
									? `${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: lowLabel,
							},
						},
						max: {
							[vitalKeys.systolic]: {
								at: diaDataMax[0]
									? dateFormatter(diaDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMax[0]
									? `${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: highLabel,
							},
							[vitalKeys.diastolic]: {
								at: diaDataMax[0]
									? dateFormatter(diaDataMax[0].date, dateSeparatorConfig.defaultRecentDatetime)
									: '',
								value: diaDataMax[0]
									? `${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
												? diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.systolic)
														.value
												: ''
										}/${
											diaDataMax[0].vitals.find((item) => item.lookupKey === vitalKeys.diastolic)
												? diaDataMax[0].vitals.find(
														(item) => item.lookupKey === vitalKeys.diastolic
													).value
												: ''
										}`
									: '',
								label: highLabel,
							},
						},
						measurementUnit,
					},
				],
			},
		];
	}

	if (entriesForSys.length === 0) {
		return [];
	}
}

function getObjectsForVitals({
	month,
	entries = [],
	vitalName: vital,
	vitalKeys,
	dateFormatter,
	dateSeparatorConfig,
}) {
	const measurementUnit = unitMapper({ vital, vitalKeys });

	if (entries.length === 1) {
		return [
			{
				name: vital,
				data: [
					{
						category: month,
						hoverLabel: dateFormatter(entries[0].date, dateSeparatorConfig.d_m),
						max: {
							at: dateFormatter(entries[0].date, dateSeparatorConfig.defaultRecentDatetime),
							value: entries[0].vitals.find((item) => item.lookupKey === vital)
								? entries[0].vitals.find((item) => item.lookupKey === vital).value
								: '',
							label: highLabel,
						},
						value: entries[0].vitals.find((item) => item.lookupKey === vital)
							? entries[0].vitals.find((item) => item.lookupKey === vital).value
							: '',
						measurementUnit,
					},
				],
			},
		];
	}
	if (entries.length > 1) {
		const minDataSet = entries
			.filter((sys) => {
				const { vitals = [] } = sys;
				const firstValue = vitals.find((item) => item.lookupKey === vital)
					? vitals.find((item) => item.lookupKey === vital).value
					: null;
				return (
					firstValue &&
					entries[0].vitals.find((item) => item.lookupKey === vital).value === firstValue
				);
			})
			.sort((vitalsOne, vitalsTwo) => vitalsOne.reportDate - vitalsTwo.reportDate)
			.reverse();

		const maxDataSet = entries
			.filter((sys) => {
				const { vitals = [] } = sys;
				const firstValue = vitals.find((item) => item.lookupKey === vital)
					? vitals.find((item) => item.lookupKey === vital).value
					: null;
				return (
					firstValue &&
					entries[entries.length - 1].vitals.find((item) => item.lookupKey === vital).value ===
						firstValue
				);
			})
			.sort((vitalsOne, vitalsTwo) => vitalsOne.reportDate - vitalsTwo.reportDate)
			.reverse();

		return [
			{
				name: vital,
				data: [
					{
						category: month,
						hoverLabel: dateFormatter(entries[0].date, dateSeparatorConfig.d_m),
						min: {
							at: dateFormatter(minDataSet[0].date, dateSeparatorConfig.defaultRecentDatetime),
							value: minDataSet[0].vitals.find((item) => item.lookupKey === vital)
								? minDataSet[0].vitals.find((item) => item.lookupKey === vital).value
								: '',
							label: lowLabel,
						},
						max: {
							at: dateFormatter(maxDataSet[0].date, dateSeparatorConfig.defaultRecentDatetime),
							value: maxDataSet[0].vitals.find((item) => item.lookupKey === vital)
								? maxDataSet[0].vitals.find((item) => item.lookupKey === vital).value
								: '',
							label: highLabel,
						},
						value: entries[
							new Date(minDataSet[0].date).getTime() <=
							new Date(entries[entries.length - 1].date).getTime()
								? 0
								: entries.length - 1
						].vitals.find((item) => item.lookupKey === vital)
							? entries[
									new Date(minDataSet[0].date).getTime() <=
									new Date(entries[entries.length - 1].date).getTime()
										? 0
										: entries.length - 1
								].vitals.find((item) => item.lookupKey === vital).value
							: '',
						measurementUnit,
					},
					{
						category: month,
						hoverLabel: dateFormatter(entries[entries.length - 1].date, dateSeparatorConfig.d_m),
						min: {
							at: dateFormatter(entries[0].date, dateSeparatorConfig.defaultRecentDatetime),
							value: entries[0].vitals.find((item) => item.lookupKey === vital)
								? entries[0].vitals.find((item) => item.lookupKey === vital).value
								: '',
							label: lowLabel,
						},
						max: {
							at: dateFormatter(
								entries[entries.length - 1].date,
								dateSeparatorConfig.defaultRecentDatetime
							),
							value: entries[entries.length - 1].vitals.find((item) => item.lookupKey === vital)
								? entries[entries.length - 1].vitals.find((item) => item.lookupKey === vital).value
								: '',
							label: highLabel,
						},
						value: entries[
							new Date(minDataSet[0].date).getTime() >
							new Date(entries[entries.length - 1].date).getTime()
								? 0
								: entries.length - 1
						].vitals.find((item) => item.lookupKey === vital)
							? entries[
									new Date(minDataSet[0].date).getTime() >
									new Date(entries[entries.length - 1].date).getTime()
										? 0
										: entries.length - 1
								].vitals.find((item) => item.lookupKey === vital).value
							: '',
						measurementUnit,
					},
				],
			},
		];
	}
	if (entries.length === 0) {
		return [];
	}
}

function initialFormatting({
	records = [],
	vitalName,
	vitalKeys,
	roundOf,
	MONTHS_IN_A_YEAR,
	dateFormatter,
	dateSeparatorConfig,
}) {
	return records.map((record) => {
		let vitals = record.vitals || [];

		if (vitalName === vitalKeys.temperature) {
			vitals = vitals.map((vital) => ({
				...vital,
				value: parseFloat(
					convertToFahrenheit({ value: vital.value, unitType: vital.unitType, roundOf })
				),
			}));
		}
		return {
			...record,
			vitals,
			newFormattedDate: dateFormatter(
				new Date(record.date),
				dateSeparatorConfig.defaultDate,
				false
			),
			month: MONTHS_IN_A_YEAR[new Date(record.reportDate).getMonth()],
		};
	});
}

/**
 * add month against each object in the obtained array
 * group month wise
 * sort each month wise array
 * take first and last vitals info for min and max respectively
 */
export function vitalAnnualInfo({
	masterData = {},
	vitalName,
	formatters: {
		vitalKeysMapper,
		vitalKeys,
		MONTHS_IN_A_YEAR,
		roundOf,
		dateFormatter,
		dateSeparatorConfig,
	},
}) {
	const vitalsMappers = vitalKeysMapper(vitalKeys);
	const { start, data: records = [] } = masterData;
	const newData = initialFormatting({
		records,
		vitalName,
		vitalKeys,
		roundOf,
		MONTHS_IN_A_YEAR,
		dateFormatter,
		dateSeparatorConfig,
	});

	const groupedData = newData.reduce((groups, data) => {
		const monthValue = data.month;

		if (!groups[monthValue]) groups[monthValue] = [];
		groups[monthValue].push(data);

		return groups;
	}, {});

	const obj = {
		[vitalKeys.systolic]: {},
		[vitalKeys.diastolic]: {},
	};
	const bp = {
		[vitalsMappers[vitalKeys.systolic]]: [],
		[vitalsMappers[vitalKeys.diastolic]]: [],
	};
	const vitalMapper = (function (vital) {
		return { [vitalsMappers[vital]]: [] };
	})(vitalName);

	for (const [month, entries] of Object.entries(groupedData)) {
		if ([vitalKeys.systolic].includes(vitalName)) {
			obj[vitalKeys.systolic][month] = sortData(
				JSON.parse(JSON.stringify(entries)),
				vitalKeys.systolic
			);
			obj[vitalKeys.diastolic][month] = sortData(
				JSON.parse(JSON.stringify(entries)),
				vitalKeys.diastolic
			);
		} else {
			// obj[month] = sortData(JSON.parse(JSON.stringify(entries)), vitalName);
			obj[month] = sortData(JSON.parse(JSON.stringify(entries)), vitalName);
		}
	}

	const startMonthValue = new Date(start).getMonth() + 1;
	const endMonthValue = startMonthValue + 12;

	for (let i = startMonthValue; i < endMonthValue; i += 1) {
		const month = MONTHS_IN_A_YEAR[i % 12];
		const entries = obj[MONTHS_IN_A_YEAR[i % 12]] || [];

		if ([vitalKeys.systolic].includes(vitalName)) {
			const entriesForSys = obj[vitalKeys.systolic][MONTHS_IN_A_YEAR[i % 12]] || [];
			const entriesForDia = obj[vitalKeys.diastolic][MONTHS_IN_A_YEAR[i % 12]] || [];
			const mappedValues = getObjectsForBp({
				month,
				entriesForSys,
				entriesForDia,
				vitalKeys,
				dateFormatter,
				dateSeparatorConfig,
			});

			const sys = mappedValues.find((item) => item.name === vitalKeys.systolic);
			const dys = mappedValues.find((item) => item.name === vitalKeys.diastolic);

			if (sys) {
				bp[vitalsMappers[vitalKeys.systolic]].push(...sys.data);
				bp[vitalsMappers[vitalKeys.diastolic]].push(...dys.data);
			}
		} else {
			const mappedValues = getObjectsForVitals({
				month,
				entries,
				vitalName,
				vitalKeys,
				dateFormatter,
				dateSeparatorConfig,
			});
			const values = mappedValues.find((item) => item.name === vitalName);
			if (values) {
				vitalMapper[vitalsMappers[vitalName]].push(...values.data);
			}
		}
	}

	const modifiedObj = [];

	if ([vitalKeys.systolic].includes(vitalName)) {
		for (const [name, data] of Object.entries(bp)) {
			modifiedObj.push({ name, data });
		}
	} else {
		for (const [name, data] of Object.entries(vitalMapper)) {
			modifiedObj.push({ name, data });
		}
	}

	return modifiedObj;
}

/**
 *
 * group month wise
 * sort each month wise array
 * take first and last vitals info for min and max respectively
 *
 */
export function vitalMonthlyInfo({
	masterData = {},
	vitalName,
	formatters: {
		getInterval,
		dateFormatter,
		dateSeparatorConfig,
		vitalKeysMapper,
		vitalKeys,
		MONTHS_IN_A_YEAR,
		roundOf,
	},
}) {
	const vitalsMappers = vitalKeysMapper(vitalKeys);
	const { start, data: records = [] } = masterData;
	const newData = initialFormatting({
		records,
		vitalName,
		vitalKeys,
		roundOf,
		MONTHS_IN_A_YEAR,
		dateFormatter,
		dateSeparatorConfig,
	});

	const weekObj = {};

	for (let i = 0; i <= 3; i += 1) {
		const weekStartInterval = getInterval(-7 * i, new Date(start));
		const weekStart = dateFormatter(weekStartInterval, dateSeparatorConfig.defaultDate, false);
		const weekEndInterval = getInterval(-6, weekStart);
		const weekEnd = dateFormatter(weekEndInterval, dateSeparatorConfig.defaultDate, false);

		const key = `${weekStart}/${weekEnd}`;
		weekObj[key] = [];

		for (let i = 0; i < newData.length; i += 1) {
			const date = new Date(
				dateFormatter(newData[i].newFormattedDate, dateSeparatorConfig.defaultDate, false)
			);
			// new Date(newData[i].newFormattedDate);

			if (
				new Date(weekStart).getTime() <= date.getTime() &&
				date.getTime() <= new Date(weekEnd).getTime()
			) {
				weekObj[key].push(newData[i]);
			}
		}
	}

	const obj = {};
	const bp = {
		[vitalsMappers[vitalKeys.systolic]]: [],
		[vitalsMappers[vitalKeys.diastolic]]: [],
	};
	const vitalMapper = (function (vital) {
		return { [vitalsMappers[vital]]: [] };
	})(vitalName);

	for (const [month, entries] of Object.entries(weekObj)) {
		const [start, end] = month.split('/');
		const [startDate, startMonth] = [
			formatDate(new Date(start).getDate()),
			new Date(start).getMonth(),
		];
		const [endDate, endMonth] = [formatDate(new Date(end).getDate()), new Date(end).getMonth()];
		const displayDate =
			startMonth === endMonth
				? `${MONTHS_IN_A_YEAR[endMonth]} ${startDate}-${endDate}`
				: `${MONTHS_IN_A_YEAR[startMonth]} ${startDate} - ${MONTHS_IN_A_YEAR[endMonth]} ${endDate}`;
		// const displayDate = `${startDate} ${MONTHS_IN_A_YEAR[startMonth]}-${endDate} ${MONTHS_IN_A_YEAR[endMonth]}`;
		obj[displayDate] = sortData(entries, vitalName);
	}

	for (const [month, entries] of Object.entries(obj)) {
		if ([vitalKeys.systolic].includes(vitalName)) {
			const entriesForSys =
				sortData(JSON.parse(JSON.stringify(obj[month])), vitalKeys.systolic) || [];
			const entriesForDia =
				sortData(JSON.parse(JSON.stringify(obj[month])), vitalKeys.diastolic) || [];
			const mappedValues = getObjectsForBp({
				month,
				entriesForSys,
				entriesForDia,
				vitalKeys,
				dateFormatter,
				dateSeparatorConfig,
			});
			const sys = mappedValues.find((item) => item.name === vitalKeys.systolic);
			const dys = mappedValues.find((item) => item.name === vitalKeys.diastolic);
			if (sys) {
				bp[vitalsMappers[vitalKeys.systolic]].push(...sys.data);
				bp[vitalsMappers[vitalKeys.diastolic]].push(...dys.data);
			}
		} else {
			const mappedValues = getObjectsForVitals({
				month,
				entries,
				vitalName,
				vitalKeys,
				dateFormatter,
				dateSeparatorConfig,
			});
			const values = mappedValues.find((item) => item.name === vitalName);
			if (values) {
				vitalMapper[vitalsMappers[vitalName]].push(...values.data);
			}
		}
	}

	const modifiedObj = [];

	if ([vitalKeys.systolic].includes(vitalName)) {
		for (const [name, data] of Object.entries(bp)) {
			modifiedObj.push({ name, data });
		}
	} else {
		for (const [name, data] of Object.entries(vitalMapper)) {
			modifiedObj.push({ name, data });
		}
	}

	return modifiedObj;
}

export function vitalWeeklyInfo({
	masterData = {},
	vitalName,
	formatters: {
		getInterval,
		dateFormatter,
		dateSeparatorConfig,
		vitalKeysMapper,
		vitalKeys,
		MONTHS_IN_A_YEAR,
		roundOf,
	},
}) {
	const vitalsMappers = vitalKeysMapper(vitalKeys);
	const { start, data: records = [] } = masterData;
	const newData = initialFormatting({
		records,
		vitalName,
		vitalKeys,
		roundOf,
		MONTHS_IN_A_YEAR,
		dateFormatter,
		dateSeparatorConfig,
	});
	const weekObj = {};

	for (let i = 0; i < 7; i += 1) {
		const weekStartInterval = getInterval(-1 * i, new Date(start));
		const weekStart = dateFormatter(weekStartInterval, dateSeparatorConfig.defaultDate, false);

		const key = `${weekStart}`;
		weekObj[key] = [];

		for (let i = 0; i < newData.length; i += 1) {
			const date = dateFormatter(
				newData[i].newFormattedDate,
				dateSeparatorConfig.defaultDate,
				false
			);

			if (weekStart === date) {
				weekObj[key].push(newData[i]);
			}
		}
	}

	const obj = {};

	const bp = {
		[vitalsMappers[vitalKeys.systolic]]: [],
		[vitalsMappers[vitalKeys.diastolic]]: [],
	};
	const vitalMapper = (function (vital) {
		return { [vitalsMappers[vital]]: [] };
	})(vitalName);

	for (const [month, entries] of Object.entries(weekObj)) {
		obj[dateFormatter(new Date(month), dateSeparatorConfig.d_m)] = sortData(
			JSON.parse(JSON.stringify(entries)),
			vitalName
		);
	}

	for (const [month, entries] of Object.entries(obj)) {
		if ([vitalKeys.systolic].includes(vitalName)) {
			const entriesForSys =
				sortData(JSON.parse(JSON.stringify(obj[month])), vitalKeys.systolic) || [];
			const entriesForDia =
				sortData(JSON.parse(JSON.stringify(obj[month])), vitalKeys.diastolic) || [];
			const mappedValues = getObjectsForBp({
				month,
				entriesForSys,
				entriesForDia,
				vitalKeys,
				dateFormatter,
				dateSeparatorConfig,
			});

			const sys = mappedValues.find((item) => item.name === vitalKeys.systolic);
			const dys = mappedValues.find((item) => item.name === vitalKeys.diastolic);
			if (sys) {
				bp[vitalsMappers[vitalKeys.systolic]].push(...sys.data);
				bp[vitalsMappers[vitalKeys.diastolic]].push(...dys.data);
			}
		} else {
			const mappedValues = getObjectsForVitals({
				month,
				entries,
				vitalName,
				vitalKeys,
				dateFormatter,
				dateSeparatorConfig,
			});
			const values = mappedValues.find((item) => item.name === vitalName);
			if (values) {
				vitalMapper[vitalsMappers[vitalName]].push(...values.data);
			}
		}
	}

	const modifiedObj = [];

	if ([vitalKeys.systolic].includes(vitalName)) {
		for (const [name, data] of Object.entries(bp)) {
			modifiedObj.push({ name, data });
		}
	} else {
		for (const [name, data] of Object.entries(vitalMapper)) {
			modifiedObj.push({ name, data });
		}
	}

	return modifiedObj;
}

export function vitalDailyInfo({
	masterData = {},
	vitalName,
	startDate = new Date(),
	endDate = new Date(),
	formatters: {
		// getInterval,
		dateFormatter,
		dateSeparatorConfig,
		vitalKeysMapper,
		vitalKeys,
		MONTHS_IN_A_YEAR,
		roundOf,
	},
}) {
	const vitalsMappers = vitalKeysMapper(vitalKeys);
	const { data: records = [] } = masterData;
	const newData = initialFormatting({
		records,
		vitalName,
		vitalKeys,
		roundOf,
		MONTHS_IN_A_YEAR,
		dateFormatter,
		dateSeparatorConfig,
	});
	const weekObj = {};

	const currentDate = new Date(
		new Date(startDate).getFullYear(),
		new Date(startDate).getMonth(),
		new Date(startDate).getDate()
	);

	const endDateValue = new Date(
		new Date(endDate).getFullYear(),
		new Date(endDate).getMonth(),
		new Date(endDate).getDate()
	);

	// for (let i = 0; i < 7; i += 1) {
	// 	const weekStartInterval = getInterval(-1 * i, new Date(start));
	// 	const weekStart = dateFormatter(weekStartInterval, dateSeparatorConfig.yyyy_mm_dd);

	// 	const key = `${weekStart}`;
	// 	weekObj[key] = [];

	// 	for (let i = 0; i < newData.length; i += 1) {
	// 		const date = newData[i].newFormattedDate;

	// 		if (weekStart === date) {
	// 			weekObj[key].push(newData[i]);
	// 		}
	// 	}
	// }

	for (let j = currentDate; j <= endDateValue; j.setDate(j.getDate() + 1)) {
		const weekStart = dateFormatter(j, dateSeparatorConfig.defaultDate, false);
		const key = `${weekStart}`;
		weekObj[key] = [];
		for (let i = 0; i < newData.length; i += 1) {
			const dateNext = dateFormatter(
				new Date(newData[i].date),
				dateSeparatorConfig.defaultDate,
				false
			);
			// const date = newData[i].date.split('T')[0];

			if (weekStart === dateNext) {
				weekObj[key].push(newData[i]);
			}
		}
		// currentDate = addDays(currentDate, 1);
	}

	const obj = {};

	const bp = {
		[vitalsMappers[vitalKeys.systolic]]: [],
		[vitalsMappers[vitalKeys.diastolic]]: [],
	};
	const vitalMapper = (function (vital) {
		return { [vitalsMappers[vital]]: [] };
	})(vitalName);

	for (const [month, entries] of Object.entries(weekObj)) {
		obj[dateFormatter(new Date(month), dateSeparatorConfig.d_m)] = sortData(
			JSON.parse(JSON.stringify(entries)),
			vitalName
		);
	}

	for (const [month, entries] of Object.entries(obj)) {
		if ([vitalKeys.systolic].includes(vitalName)) {
			const entriesForSys =
				sortData(JSON.parse(JSON.stringify(obj[month])), vitalKeys.systolic) || [];
			const entriesForDia =
				sortData(JSON.parse(JSON.stringify(obj[month])), vitalKeys.diastolic) || [];
			const mappedValues = getObjectsForBp({
				month,
				entriesForSys,
				entriesForDia,
				vitalKeys,
				dateFormatter,
				dateSeparatorConfig,
			});

			const sys = mappedValues.find((item) => item.name === vitalKeys.systolic);
			const dys = mappedValues.find((item) => item.name === vitalKeys.diastolic);
			if (sys) {
				bp[vitalsMappers[vitalKeys.systolic]].push(...sys.data);
				bp[vitalsMappers[vitalKeys.diastolic]].push(...dys.data);
			}
		} else {
			const mappedValues = getObjectsForVitals({
				month,
				entries,
				vitalName,
				vitalKeys,
				dateFormatter,
				dateSeparatorConfig,
			});
			const values = mappedValues.find((item) => item.name === vitalName);
			if (values) {
				vitalMapper[vitalsMappers[vitalName]].push(...values.data);
			}
		}
	}

	const modifiedObj = [];

	if ([vitalKeys.systolic].includes(vitalName)) {
		for (const [name, data] of Object.entries(bp)) {
			modifiedObj.push({ name, data });
		}
	} else {
		for (const [name, data] of Object.entries(vitalMapper)) {
			modifiedObj.push({ name, data });
		}
	}

	return modifiedObj;
}
