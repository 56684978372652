/**
 * Reference
 * 1. https://firebase.google.com/docs/reference/js/firebase.messaging.Messaging
 * 2. https://firebase.google.com/docs/cloud-messaging/js/client
 */

import fireBase from '../helpers/firebase.push';

const PushService = {
	messaging: null,
	fcmToken: null,

	initialize() {
		if (!this.messaging) {
			this.messaging = fireBase.messaging;
		}

		return this;
	},

	// This method might not be required as getToken itself while ask for permission as required.
	async requestPermission() {
		if (this.messaging) {
			const userPermission = await Notification.requestPermission();
			return userPermission;
		}

		return null;
	},

	// This method returns FCM token. This will also ask for user permission as required.
	async getToken() {
		if (this.messaging) {
			const fcmToken = await this.messaging.getToken();
			this.fcmToken = fcmToken;
			return fcmToken;
		}

		return null;
	},

	async onTokenRefresh(callback) {
		if (this.messaging) {
			// this.messaging.onTokenRefresh(() => {
			callback();
			// });
		}

		return () => {};
	},

	async deleteToken() {
		// token
		if (this.messaging) {
			try {
				// const isDeleted = await this.messaging.deleteToken(token);
				this.messaging = null;
				return true;
			} catch {
				return false;
			}
		}

		return false;
	},

	onMessage(callback) {
		if (this.messaging) {
			this.messaging.onMessage(callback);
		}

		return null;
	},
};

export default PushService;
