import React, { memo } from 'react';

import './spinner.css';

export function Loader() {
	return (
		<div className="spinner">
			<div className="bounce1" />
			<div className="bounce2" />
			<div className="bounce3" />
		</div>
	);
}

function Spinner() {
	return (
		<div className="spinner-container">
			<Loader />
		</div>
	);
}

export function SpinnerCenter() {
	return (
		<div className="spinner-center">
			<div className="bounce1" />
			<div className="bounce2" />
			<div className="bounce3" />
		</div>
	);
}

export function LoaderSingle() {
	return (
		<div className="spinner-single">
			<div className="bounce1" />
			<div className="bounce2" />
			<div className="bounce3" />
		</div>
	);
}

export default memo(Spinner);
