import styled from 'styled-components';
import { fontBold } from '../../assets/styles/typography';
import * as colors from '../colors';

const HeadingPageName = styled.h3`
	font-size: 28px;
	line-height: 33px;
	color: ${colors.BlueDark};
	font-family: ${fontBold.familyName};
	font-weight: 800;
	margin-bottom: 38px;
	color: ${(props) => props.password && colors.Grey400};
	margin-bottom: ${(props) => props.marginBottom};
`;

export default HeadingPageName;
