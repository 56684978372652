import MixPanel from 'mixpanel-browser';

import envConfig from '../config/environment';

// MixPanel.init(envConfig.mixPanelToken);

const envCheck = envConfig.currentInstance;

const actions = {
	identify: (id) => {
		if (envCheck) MixPanel.identify(id);
	},
	alias: (id) => {
		if (envCheck) MixPanel.alias(id);
	},
	track: (name, props) => {
		if (envCheck) MixPanel.track(name, props);
	},
	people: {
		set: (name, props) => {
			if (envCheck) MixPanel.people.set(name, props);
		},
		resetUser: () => {
			if (envCheck) MixPanel.reset();
		},
		incrementViewCount: (prop, by) => {
			if (envCheck) MixPanel.people.increment(prop, by, null);
		},
	},
	super: {
		set: (props) => {
			if (envCheck) MixPanel.register(props);
		},
	},
};

export const initializeMixPanel = async () => {
	await MixPanel.init(envConfig.mixPanelToken(), { persistence: 'localStorage' });
	MixPanel.register({ app_flavor: envCheck, referring_domain: envConfig.baseURL() });
};

export const MixPanelService = actions;
