import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';

const LoginLeft = styled.div`
	display: inline-block;
	position: relative;
	max-width: 450px;
	max-width: 450px;
	min-height: 660px;
	width: 100%;
	background: white;
	padding: 30px 52px 30px 52px;
	/* border-radius: 50% / 3%; */
	border-radius: 8px;
	@media screen and (max-width: 1200px) {
		max-width: 380px;
		min-width: 380px;
		min-height: auto;
	}
	@media screen and (max-width: 600px) {
		max-width: 300px;
		min-width: 300px;
		padding: 40px 20px 60px 20px;
	}

	${({ forgotPassword }) =>
		forgotPassword &&
		`button {
			bottom: 110px;
		}
		a{
			font-size: 16px;
			line-height: 22px;
			text-align: center;
			color: #536DFE;
			position: absolute;
			bottom: 50px;
			width: 100%;
			left: 0;
			font-family: ${fontMedium.familyName};
			font-weight:300;
			text-decoration: none;
			cursor: pointer;
			transition: ease all 0.4s;
			@media screen and (max-width: 1200px) {
				width: 100%;
				position: static;
				display: inline-block;
				padding-top: 30px;
			}
		}
    `}
`;

export default LoginLeft;
