import styled from 'styled-components';
import { fontBold } from '../../assets/styles/typography';
import { colors } from '../colors';

const BredcrumbHeading = styled.h2`
	font-size: 20px;
	line-height: 23px;
	background: ${colors.BlueLight};
	font-family: ${fontBold.familyName};
	font-weight: 800;
`;

export default BredcrumbHeading;
