import { lazy } from 'react';

const Login = lazy(() => import('../pages/Login'));
const PatientLogin = lazy(() => import('../pages/Login/PatientLogin'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/ChangePassword'));

export default [
	{
		exact: true,
		name: 'Login',
		path: '/',
		component: Login,
		element: Login,
	},
	{
		exact: true,
		name: 'PatientLogin',
		path: '/patient',
		component: PatientLogin,
		element: PatientLogin,
	},
	{
		exact: true,
		name: 'ForgotPassword',
		path: '/forgot-password',
		component: ForgotPassword,
		element: ForgotPassword,
	},
	{
		exact: true,
		name: 'ResetPassword',
		path: '/reset-password',
		component: ResetPassword,
		element: ResetPassword,
	},
];
