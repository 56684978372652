import styled from 'styled-components';
import { fontBold, fontLight } from '../../assets/styles/typography';
import * as colors from '../colors';

const HeadingWelcome = styled.h2`
	font-size: 56px;
	font-family: ${fontBold.familyName};
	font-weight: 800;
	line-height: 66px;
	color: ${colors.BlueDark};
	/* margin-bottom: 15px; */
	span {
		font-size: 50px;
		font-family: ${fontLight.familyName}, sans-serif;
		font-weight: 300;
		color: ${colors.BlueBase};
		@media screen and (max-width: 1024px) {
			font-size: 45px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 50px;
	}
`;

export default HeadingWelcome;
