import { lazy } from 'react';

import { Roles } from '../helpers/Constants';
import layoutProps from './route.params';

const DashBoard = lazy(() => import('../pages/DashBoard'));
const Chat = lazy(() => import('../pages/Chat'));
const Profile = lazy(() => import('../pages/Profile'));
const ClinicalNotes = lazy(() => import('../pages/ClinicalNotes'));
const Patients = lazy(() => import('../pages/PatientList'));
const DoctorManagement = lazy(() => import('../pages/DoctorManagement'));
const DoctorViewDetails = lazy(() => import('../pages/DoctorManagement/Controller/ViewController'));
const CoachManagement = lazy(() => import('../pages/CoachManagement'));
const CoachViewDetails = lazy(() => import('../pages/CoachManagement/Controller/ViewController'));
const ClinicManagement = lazy(() => import('../pages/ClinicManagement'));
const ClinicViewDetails = lazy(() => import('../pages/ClinicManagement/Controller/ViewController'));
const PatientManagement = lazy(() => import('../pages/PatientManagement'));
const PatientViewDetails = lazy(
	() => import('../pages/PatientManagement/Controller/ViewController')
);
const PatientInvitation = lazy(() => import('../pages/PatientInvitation'));
const HealthCard = lazy(() => import('../pages/HealthCard'));
const HealthCardView = lazy(() => import('../pages/HealthCard/Controller/ViewController'));
const PatientDetails = lazy(() => import('../pages/PatientList/Components/PatientDetails'));
const ClinicalNotesAlerts = lazy(
	() => import('../pages/DashBoard/components/Listing/ClinicalNotesAlerts')
);
const ClinicalNotifications = lazy(
	() => import('../pages/DashBoard/components/Listing/ClinicalNotifications')
);
const Unauthorized = lazy(() => import('../pages/Unauthorized'));
const DietManagement = lazy(() => import('../pages/DietManagement'));
const ClinicAdminAsDoctorDashBoard = lazy(
	() => import('../pages/DashBoard/components/ClinicAdminAndDoctor')
);
const ClinicAdminAsDoctorPatient = lazy(
	() => import('../pages/DashBoard/components/ClinicAdminAndDoctor/PatientList')
);
const ClinicAdminAsDoctorPatientInvitees = lazy(
	() => import('../pages/DashBoard/components/ClinicAdminAndDoctor/PatientInvitation')
);
const ClinicAdminAsDoctorList = lazy(
	() => import('../pages/DashBoard/components/ClinicAdminAndDoctor/DoctorList')
);
const ClinicAdminAsDoctorView = lazy(
	() => import('../pages/DashBoard/components/ClinicAdminAndDoctor/DoctorView')
);
const NotificationResolver = lazy(
	() => import('../pages/DashBoard/components/NotificationResolver')
);

const PatientVault = lazy(() => import('../pages/PatientVault'));
const InsulinRBSChart = lazy(() => import('../pages/InsulinRBSChart'));
const CustomerSupportManagement = lazy(() => import('../pages/CustomerSupportManagement'));
const CustomerSupportViewDetails = lazy(
	() => import('../pages/CustomerSupportManagement/Controller/ViewController')
);
const DeleteAccount = lazy(() => import('../pages/DeleteAccount/deleteAccount'));

const InsulinPrescriptionUpdates = lazy(
	() => import('../pages/InsulinPrescriptionUpdates/insulinPrescriptionUpdates')
);

export default [
	{
		exact: true,
		name: 'Unauthorized',
		path: '/unauthorized',
		component: Unauthorized,
		element: Unauthorized,
		visibleTo: [
			Roles.coach,
			Roles.doctor,
			Roles.weCareAdmin,
			Roles.clinicAdmin,
			Roles.customerSupport,
		],
	},
	{
		exact: true,
		name: 'DashBoard',
		path: '/dashboard',
		component: DashBoard,
		element: DashBoard,
		visibleTo: [
			Roles.coach,
			Roles.doctor,
			Roles.weCareAdmin,
			Roles.clinicAdmin,
			Roles.customerSupport,
		],
	},
	{
		exact: true,
		name: 'PatientList',
		path: '/patients',
		component: Patients,
		element: Patients,
		visibleTo: [
			Roles.coach,
			Roles.doctor,
			Roles.weCareAdmin,
			Roles.clinicAdmin,
			Roles.customerSupport,
		],
	},
	{
		exact: true,
		name: 'Chat',
		path: '/chat',
		component: Chat,
		element: Chat,
		visibleTo: [Roles.coach],
	},
	{
		exact: true,
		name: 'Profile',
		path: '/profile',
		component: Profile,
		element: Profile,
		visibleTo: [
			Roles.coach,
			Roles.doctor,
			Roles.weCareAdmin,
			Roles.clinicAdmin,
			Roles.customerSupport,
		],
	},
	{
		exact: true,
		name: 'ClinicalNotes',
		path: '/clinical-notes/:id',
		component: ClinicalNotes,
		element: ClinicalNotes,
		visibleTo: [Roles.coach, Roles.doctor],
	},
	{
		exact: true,
		name: 'ClinicalNotes listing',
		path: '/clinical-notes',
		component: ClinicalNotesAlerts,
		element: ClinicalNotesAlerts,
		visibleTo: [Roles.coach, Roles.doctor],
	},
	{
		exact: true,
		name: 'ClinicalNotifications listing',
		path: '/clinical-notifications',
		component: ClinicalNotifications,
		element: ClinicalNotifications,
		visibleTo: [Roles.coach, Roles.doctor],
	},
	{
		exact: true,
		name: 'Patient detail',
		path: '/patient/:patientId',
		component: PatientDetails,
		element: PatientDetails,
		visibleTo: [Roles.coach, Roles.doctor],
		layoutProps: layoutProps.patientManagement,
	},
	{
		exact: true,
		name: 'DoctorManagement',
		path: '/doctor-management',
		component: DoctorManagement,
		element: DoctorManagement,
		visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'DoctorViewDetails',
		path: '/doctor-management/:id',
		component: DoctorViewDetails,
		element: DoctorViewDetails,
		visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'PatientInvitation',
		path: '/patient-invitation',
		component: PatientInvitation,
		element: PatientInvitation,
		visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'CoachManagement',
		path: '/coach-management',
		component: CoachManagement,
		element: CoachManagement,
		visibleTo: [Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'CoachViewDetails',
		path: '/coach-management/:id',
		component: CoachViewDetails,
		element: CoachViewDetails,
		visibleTo: [Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'ClinicManagement',
		path: '/clinic-management',
		component: ClinicManagement,
		element: ClinicManagement,
		visibleTo: [Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'ClinicViewDetails',
		path: '/clinic-management/:id',
		component: ClinicViewDetails,
		element: ClinicViewDetails,
		visibleTo: [Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'DietManagement',
		path: '/diets',
		component: DietManagement,
		element: DietManagement,
		visibleTo: [Roles.coach, Roles.doctor, Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'PatientManagement',
		path: '/patient-management',
		component: PatientManagement,
		element: PatientManagement,
		visibleTo: [Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		exact: true,
		name: 'PatientViewDetails',
		path: '/patient-management/:id',
		component: PatientViewDetails,
		element: PatientViewDetails,
		visibleTo: [Roles.weCareAdmin, Roles.customerSupport, Roles.clinicAdmin, Roles.doctor],
	},
	{
		exact: true,
		name: 'HealthCard',
		path: '/health-card',
		component: HealthCard,
		element: HealthCard,
		visibleTo: [],
	},
	{
		exact: true,
		name: 'HealthCardView',
		path: '/health-card/:categoryID/:id',
		component: HealthCardView,
		element: HealthCardView,
		visibleTo: [],
	},
	{
		exact: true,
		name: 'ClinicAdminAsDoctor - DashBoard',
		path: '/dashboard/view',
		component: ClinicAdminAsDoctorDashBoard,
		element: ClinicAdminAsDoctorDashBoard,
		visibleTo: [Roles.doctor],
	},
	{
		exact: true,
		name: 'ClinicAdminAsDoctor - Patient list',
		path: '/patients/view',
		component: ClinicAdminAsDoctorPatient,
		element: ClinicAdminAsDoctorPatient,
		visibleTo: [Roles.doctor],
	},
	{
		exact: true,
		name: 'ClinicAdminAsDoctor - Patient invitee list',
		path: '/patients/invitees/view',
		component: ClinicAdminAsDoctorPatientInvitees,
		element: ClinicAdminAsDoctorPatientInvitees,
		visibleTo: [Roles.doctor],
	},
	{
		exact: true,
		name: 'ClinicAdminAsDoctor - Doctor list',
		path: '/doctors/view',
		component: ClinicAdminAsDoctorList,
		element: ClinicAdminAsDoctorList,
		visibleTo: [Roles.doctor],
	},
	{
		exact: true,
		name: 'ClinicAdminAsDoctor - Doctor list',
		path: '/doctors/view/:id',
		component: ClinicAdminAsDoctorView,
		element: ClinicAdminAsDoctorView,
		visibleTo: [Roles.doctor],
	},
	{
		exact: true,
		name: 'Resolve doctor management page - only for ClinicAdminAsDoctor',
		path: '/view/page/:id',
		component: NotificationResolver,
		element: NotificationResolver,
		visibleTo: [Roles.doctor, Roles.clinicAdmin],
	},
	{
		exact: true,
		name: 'Patient vault',
		path: '/patientvault',
		component: PatientVault,
		element: PatientVault,
		visibleTo: [Roles.doctor, Roles.coach],
	},
	{
		exact: true,
		name: 'InsulinRBSChart',
		path: '/insulin-rbs-chart',
		component: InsulinRBSChart,
		element: InsulinRBSChart,
		visibleTo: [Roles.coach, Roles.doctor],
	},
	{
		exact: true,
		name: 'CustomerSupportManagement',
		path: '/customer-support',
		component: CustomerSupportManagement,
		element: CustomerSupportManagement,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'CustomerSupportViewDetails',
		path: '/customer-support/:id',
		component: CustomerSupportViewDetails,
		element: CustomerSupportViewDetails,
		visibleTo: [Roles.weCareAdmin],
	},
	{
		exact: true,
		name: 'DeleteAccount',
		path: '/delete-account',
		component: DeleteAccount,
		element: DeleteAccount,
		visibleTo: [Roles.patient],
	},
	{
		exact: true,
		name: 'InsulinPrescription',
		path: '/insulin-prescription',
		component: InsulinPrescriptionUpdates,
		element: InsulinPrescriptionUpdates,
		visibleTo: [Roles.patient],
	},
];
