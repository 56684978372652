import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const PaginationItem = styled.li`
	margin: 0px;
	padding: 0px;
	list-style: none;
	color: ${colors.Black};
	font-size: 13px;
	line-height: 15px;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${fontMedium.familyName};
	font-weight: 300;
	cursor: pointer;
	transition: ease-in all 0.4s;
	margin-right: 2px;
	&:hover {
		color: ${colors.Black};
		background: ${colors.GreyEC};
	}
	${({ active }) =>
		active &&
		`color: ${colors.White};
		background: ${colors.BlueLight};
	`}
	${({ prevnext }) =>
		prevnext &&
		`color: ${colors.Black};
		background: ${colors.Grey100};
	`}
`;
export default PaginationItem;
