import HeartRateSvg from '../../../../stories/assets/images/icons/Heart-Rate.svg';
import TemperatureSvg from '../../../../stories/assets/images/icons/Temperature.svg';
import BloodPressureSvg from '../../../../stories/assets/images/icons/Blood-Pressure.svg';
import RespiratoryRateSvg from '../../../../stories/assets/images/icons/Respiratory-Rate.svg';
import OxygenSaturationSvg from '../../../../stories/assets/images/icons/Oxygen-Saturation.svg';
import { convertFromAPIDateFormat } from '../../../../helpers/Utilities';

export const vitalCategory = {
	bp: 'bp',
	oxygen: 'oxygen',
	respiratory: 'respiratory',
	heart: 'heart',
	temperature: 'temperature',
};

export function vitalKeysMapper(vitalKeys) {
	return {
		[vitalKeys.systolic]: 'Systolic',
		[vitalKeys.diastolic]: 'Diastolic',
		[vitalKeys.temperature]: 'Temperature',
		[vitalKeys.heartRate]: 'Heart rate',
		[vitalKeys.respiratoryRate]: 'Respiratory rate',
		[vitalKeys.oxygenSaturation]: 'Oxygen saturation',
	};
}

export default {
	bp: {
		heading: 'Blood pressure',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true },
		image: {
			src: BloodPressureSvg,
			alt: 'Blood pressure marker',
		},
	},
	temperature: {
		heading: 'Temperature',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true },
		image: {
			src: TemperatureSvg,
			alt: 'Temperature marker',
		},
	},
	heart: {
		heading: 'Heart rate',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true },
		image: {
			src: HeartRateSvg,
			alt: 'Heart rate marker',
		},
	},
	respiratory: {
		heading: 'Respiratory rate',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true },
		image: {
			src: RespiratoryRateSvg,
			alt: 'Respiratory rate marker',
		},
	},
	oxygen: {
		heading: 'Oxygen saturation',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true, margin: '0px' },
		image: {
			src: OxygenSaturationSvg,
			alt: 'Oxygen saturation marker',
		},
	},
};

function isLeapYear(year) {
	return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}

export function daysInAYear(year) {
	return isLeapYear(year) ? 366 : 365;
}

export const activities = {
	diet: 'Diet',
	exercise: 'Exercise',
	medication: 'Medication',
	fluid: 'Fluid',
};

export function getInterval(interval = 0, period = Date.now()) {
	const date = new Date(period);
	date.setDate(date.getDate() - interval);
	return date;
}

export function startTimeOffset(date) {
	const arrivedDate = new Date(date);
	arrivedDate.setHours(0);
	arrivedDate.setMinutes(0);
	arrivedDate.setSeconds(0);

	return arrivedDate.toISOString();
}

export function endTimeOffset(date) {
	const arrivedDate = new Date(date);
	arrivedDate.setHours(23, 59, 59, 999);

	return arrivedDate.toISOString();
}

export function extractDayVaultChart({
	values,
	start,
	end,
	formatters: { dateFormatter, dateSeparatorConfig },
}) {
	const { data = [] } = values;
	const graphs = [];

	const currentDate = new Date(
		new Date(start).getFullYear(),
		new Date(start).getMonth(),
		new Date(start).getDate()
	);

	const endDateValue = new Date(
		new Date(end).getFullYear(),
		new Date(end).getMonth(),
		new Date(end).getDate()
	);

	for (let j = currentDate; j <= endDateValue; j.setDate(j.getDate() + 1)) {
		const weekStart = dateFormatter(j, dateSeparatorConfig.defaultDate, false);

		const respectiveActivity = data.filter(
			(item) =>
				dateFormatter(
					convertFromAPIDateFormat(item.date, item.date.includes('T')),
					dateSeparatorConfig.defaultDate,
					false
				) === weekStart
		);

		if (respectiveActivity.length) {
			const dietValue =
				respectiveActivity[0][activities.diet.toLowerCase()] &&
				respectiveActivity[0][activities.diet.toLowerCase()].following
					? 1
					: 0;
			const exerciseValue =
				respectiveActivity[0][activities.exercise.toLowerCase()] &&
				respectiveActivity[0][activities.exercise.toLowerCase()].activity !== 'INACTIVE'
					? 1
					: 0;
			const medicationValue =
				respectiveActivity[0][activities.medication.toLowerCase()] &&
				respectiveActivity[0][activities.medication.toLowerCase()].following
					? 1
					: 0;

			graphs.push({
				name: convertFromAPIDateFormat(
					respectiveActivity[0].date,
					respectiveActivity[0].date.includes('T')
				),
				[activities.diet]: dietValue,
				[activities.medication]: medicationValue,
				[activities.exercise]: exerciseValue,
			});
		} else {
			graphs.push({
				name: weekStart,
				[activities.diet]: 0,
				[activities.medication]: 0,
				[activities.exercise]: 0,
			});
		}
	}

	// for (let i = 0; i < 7; i += 1) {
	// 	const startDate = new Date(start);

	// 	const limit = dateFormatter(getInterval(i * -1, startDate), dateSeparatorConfig.yyyy_mm_dd);

	// 	const respectiveActivity = data.filter((item) => item.date === limit);

	// 	if (respectiveActivity.length) {
	// 		const dietValue =
	// 			respectiveActivity[0][activities.diet.toLowerCase()] &&
	// 			respectiveActivity[0][activities.diet.toLowerCase()].following
	// 				? 1
	// 				: 0;
	// 		const exerciseValue =
	// 			respectiveActivity[0][activities.exercise.toLowerCase()] &&
	// 			respectiveActivity[0][activities.exercise.toLowerCase()].activity !== 'INACTIVE'
	// 				? 1
	// 				: 0;
	// 		const medicationValue =
	// 			respectiveActivity[0][activities.medication.toLowerCase()] &&
	// 			respectiveActivity[0][activities.medication.toLowerCase()].following
	// 				? 1
	// 				: 0;

	// 		graphs.push({
	// 			name: respectiveActivity[0].date,
	// 			[activities.diet]: dietValue,
	// 			[activities.medication]: medicationValue,
	// 			[activities.exercise]: exerciseValue,
	// 		});
	// 	} else {
	// 		graphs.push({
	// 			name: limit,
	// 			[activities.diet]: 0,
	// 			[activities.medication]: 0,
	// 			[activities.exercise]: 0,
	// 		});
	// 	}
	// }

	return graphs.map((item) => ({
		...item,
		name: dateFormatter(item.name, dateSeparatorConfig.d_m),
	}));
}

function extractWeekVaultChart({ values, formatters: { dateFormatter, dateSeparatorConfig } }) {
	const { data = [], start } = values;
	const graphs = [];

	for (let i = 0; i < 7; i += 1) {
		const startDate = new Date(start);

		const limit = dateFormatter(
			getInterval(i * -1, startDate),
			dateSeparatorConfig.defaultDate,
			false
		);

		const respectiveActivity = data.filter(
			(item) => dateFormatter(item.date, dateSeparatorConfig.defaultDate, false) === limit
		);

		if (respectiveActivity.length) {
			const dietValue =
				respectiveActivity[0][activities.diet.toLowerCase()] &&
				respectiveActivity[0][activities.diet.toLowerCase()].following
					? 1
					: 0;
			const exerciseValue =
				respectiveActivity[0][activities.exercise.toLowerCase()] &&
				respectiveActivity[0][activities.exercise.toLowerCase()].activity !== 'INACTIVE'
					? 1
					: 0;
			const medicationValue =
				respectiveActivity[0][activities.medication.toLowerCase()] &&
				respectiveActivity[0][activities.medication.toLowerCase()].following
					? 1
					: 0;

			graphs.push({
				name: respectiveActivity[0].date,
				[activities.diet]: dietValue,
				[activities.medication]: medicationValue,
				[activities.exercise]: exerciseValue,
			});
		} else {
			graphs.push({
				name: limit,
				[activities.diet]: 0,
				[activities.medication]: 0,
				[activities.exercise]: 0,
			});
		}
	}

	return graphs.map((item) => ({
		...item,
		name: dateFormatter(item.name, dateSeparatorConfig.d_m),
	}));
}

export function extractMonthVaultChart({
	values,
	start,
	formatters: { dateFormatter, dateSeparatorConfig },
}) {
	const { data = [] } = values;

	const graphs = [];

	for (let i = 0; i <= 3; i += 1) {
		const weekStart = dateFormatter(
			getInterval(-7 * i, new Date(start)),
			dateSeparatorConfig.defaultDate,
			false
		);
		const weekEnd = dateFormatter(
			getInterval(-7, weekStart),
			dateSeparatorConfig.defaultDate,
			false
		);

		const weekWiseData = extractWeekVaultChart({
			values: {
				data,
				start: weekStart,
				end: weekEnd,
			},
			formatters: { dateFormatter, dateSeparatorConfig },
		});

		const [startDate, startMonth] = weekWiseData[0].name.split(' ');
		const [endDate, endMonth] = weekWiseData[weekWiseData.length - 1].name.split(' ');

		const labelStart =
			startMonth === endMonth
				? `${startMonth} ${startDate}-${endDate}`
				: `${startMonth} ${startDate}-${endMonth} ${endDate}`;

		// const labelStart = `${weekWiseData[0].name}-${weekWiseData[weekWiseData.length - 1].name}`;

		const dietWeekTotal = weekWiseData
			.map((item) => item[activities.diet])
			.reduce((total, value) => total + value, 0);
		const medicationWeekTotal = weekWiseData
			.map((item) => item[activities.medication])
			.reduce((total, value) => total + value, 0);
		const exerciseWeekTotal = weekWiseData
			.map((item) => item[activities.exercise])
			.reduce((total, value) => total + value, 0);

		graphs.push({
			name: labelStart,
			[activities.diet]: dietWeekTotal,
			[activities.medication]: medicationWeekTotal,
			[activities.exercise]: exerciseWeekTotal,
		});
	}

	return graphs;
}

function generateMonth(start = new Date(), interval = 0) {
	return new Date(start).setMonth(new Date(start).getMonth() + interval);
}

export function extractYearVaultChart({
	values,
	formatters: { dateFormatter, dateSeparatorConfig, MONTHS_IN_A_YEAR },
}) {
	const graphs = [];
	const { data = [], start, end } = values;

	let startYear = start;
	if (new Date(start).getMonth() === new Date(end).getMonth()) {
		startYear = dateFormatter(generateMonth(start, 1), dateSeparatorConfig.defaultDate, false);
	}

	for (let i = 0; i < 12; i += 1) {
		const date = new Date(generateMonth(startYear, i));
		const firstDay = dateFormatter(
			new Date(date.getFullYear(), date.getMonth(), 1),
			dateSeparatorConfig.defaultDate,
			false
		);
		const lastDay = dateFormatter(
			new Date(date.getFullYear(), date.getMonth() + 1, 0),
			dateSeparatorConfig.defaultDate,
			false
		);

		const filteredData = data.filter((item) => {
			const recordedTime = new Date(item.date).getTime();
			return (
				recordedTime >= new Date(firstDay).getTime() && recordedTime <= new Date(lastDay).getTime()
			);
		});

		if (filteredData.length) {
			const dietPlans = filteredData
				.filter((item) => item[activities.diet.toLowerCase()])
				.map((item) => item[activities.diet.toLowerCase()])
				.filter((item) => item.following);
			const exercisePlans = filteredData
				.filter((item) => item[activities.exercise.toLowerCase()])
				.map((item) => item[activities.exercise.toLowerCase()])
				.filter((item) => item.activity !== 'INACTIVE');
			const medicationPlans = filteredData
				.filter((item) => item[activities.medication.toLowerCase()])
				.map((item) => item[activities.medication.toLowerCase()])
				.filter((item) => item.following);

			graphs.push({
				name: `${MONTHS_IN_A_YEAR[new Date(firstDay).getMonth()]}`,
				[activities.diet]: dietPlans.length,
				[activities.medication]: medicationPlans.length,
				[activities.exercise]: exercisePlans.length,
			});
		} else {
			graphs.push({
				name: `${MONTHS_IN_A_YEAR[new Date(firstDay).getMonth()]}`,
				[activities.diet]: 0,
				[activities.medication]: 0,
				[activities.exercise]: 0,
			});
		}
	}

	return graphs;
}
