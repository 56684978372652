import { useSelector, useDispatch } from 'react-redux';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import AppSpinner from './components/Spinner';
import LoggedOut from './components/LoggedOut';
import AppLayout from './components/AppLayout';

import useUsers from './pages/Chat/hooks/useUsers';
import usePushNotification from './pages/Chat/hooks/usePushNotification';

import PrivateRoutes from './routes/private.routes';
import PublicRoutes from './routes/public.routes';

import { APP_TOKEN, REFRESH_TOKEN, Roles } from './helpers/Constants';
import { preLogout, getUserProfile, getNotifications } from './pages/Login/login.store';
import { MixPanelService } from './service/MixpanelService';

const PageNotFound = lazy(() => import('./pages/PageNotFound'));

function AppRoutes() {
	const dispatch = useDispatch();
	const [sessionOutModal, setSessionOutModal] = useState(false);

	const { isLoggedIn, authenticating, authenticated, userDetails } = useSelector(
		({ AuthStore }) => {
			const { isLoggedIn, authenticating, authenticated, userDetails = {} } = AuthStore;
			return { isLoggedIn, authenticating, authenticated, userDetails };
		}
	);

	let userRole = localStorage.getItem('userRole') || userDetails.role;
	if (localStorage.getItem('userRole')) {
		userRole = localStorage.getItem('userRole');
	} else {
		userRole = userDetails.role;
		localStorage.setItem('userRole', userDetails.role);
	}

	useUsers(isLoggedIn);
	usePushNotification(isLoggedIn);

	useEffect(() => {
		if (isLoggedIn) {
			dispatch(getUserProfile());
			dispatch(getNotifications({ params: { limit: 1000, offset: 0 } }));
		}
		return () => {};
	}, [isLoggedIn]);

	useEffect(() => {
		function storageHandler({ key, newValue }) {
			if ([APP_TOKEN, REFRESH_TOKEN].includes(key) && !newValue) {
				setSessionOutModal(true);
				window.removeEventListener('storage', storageHandler);
			}
		}

		if (isLoggedIn) {
			window.addEventListener('storage', storageHandler);
		}

		return () => {};
	}, [isLoggedIn]);

	if (authenticating && !authenticated) return <AppSpinner />;
	return (
		<>
			{sessionOutModal && <LoggedOut />}
			<Router>
				<Suspense fallback={<AppSpinner />}>
					<Routes>
						{PublicRoutes.map(({ component: Component, ...rest }, index) => (
							<Route key={index} {...rest} element={<Component />} />
						))}
						{PrivateRoutes.map((route, index) => (
							<Route
								key={index}
								{...route}
								element={
									<PrivateRoute
										key={index}
										isLoggedIn={isLoggedIn}
										{...route}
										visibleTo={route.visibleTo}
										role={userRole}
									/>
								}
							/>
						))}
						<Route path="*" exact element={<PageNotFound />} />
						{/* <Route element={<PageNotFound />} /> */}
					</Routes>
				</Suspense>
			</Router>
		</>
	);
}

function PrivateRoute({ component: Component, isLoggedIn, visibleTo, role, layoutProps, ...rest }) {
	const dispatch = useDispatch();

	function appLogout() {
		dispatch(preLogout());
		MixPanelService.track('user_logout');
	}

	function appPatientLogout() {
		localStorage.clear();
		localStorage.setItem('deleteFrom', Roles.patient);
		window.location.href = '/patient';
	}

	if (
		window.location.pathname.includes('insulin-prescription') &&
		localStorage.getItem('userRole') !== Roles.patient
	) {
		localStorage.clear();
		localStorage.setItem('redirectTo', 'insulin-prescription');
		window.location.href = '/patient';
		return;
	}

	if (isLoggedIn && !visibleTo.includes(role)) {
		return <Navigate to="/" />;
	}

	if (!isLoggedIn) {
		return <Navigate to="/" />;
	}

	return (
		<AppLayout
			isLoggedIn={isLoggedIn}
			appPatientLogout={() => appPatientLogout()}
			appLogout={() => appLogout()}
			layoutProps={layoutProps}
		>
			<Component />
		</AppLayout>
	);
}

export default AppRoutes;
