/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../colors';

import HambergerMenu from './hambergerMenu';
import { Icon } from '../..';
import SidebarList from './sidebarList';
import SidebarLogo from './sidebarLogo';
import SidebarClose from './sidebarClose';
import SidebarHamberger from './sidebarHamberger';

const SidebarMenu = styled.section`
	width: 62px;
	height: 100vh;
	background: ${colors.BlueBase};
	transition: ease-in all 0.4s;
	position: relative;
	z-index: 99;
	@media screen and (max-width: 767px) {
		position: fixed;
		left: 0;
		z-index: 1000;
		height: auto;
		transition: ease-in all 0s;
	}
	ul {
		transition: ease-in all 0.4s;
		@media screen and (max-width: 767px) {
			display: none;
		}
		ul {
			display: none;
			transition: ease-in all 0.4s;
		}
		li {
			&:hover {
				ul {
					display: inline-block;
					position: absolute;
					left: 62px;
					width: 200px;
					z-index: 9;
					a {
						padding-left: 25px;
					}
				}
				a {
					> div {
						position: absolute;
						height: 48px;
						left: 62px;
						z-index: 9;
						background: ${colors.BlueDarkX};
						width: 200px;
						padding-left: 25px;
						display: flex;
						align-items: center;
						&:hover {
							color: ${colors.BlueLight};
							transition: ease-in all 0.4s;
						}
					}
				}
			}
		}
	}
	${({ open }) =>
		open &&
		`width: 245px;
		@media screen and (max-width: 767px) {
			width: 100%;
			transition: ease-in all 0s;
			height: 100vh;
		}
		span{
			@media screen and (max-width: 767px) {
				color: ${colors.White};
				background: transparent;
				&:hover{
					background: transparent;
				}
			}
		}
		ul {
			transition: ease-in all 0.4s;
			@media screen and (max-width: 767px) {
				display: block;
			}
			ul {
				transition: ease-in all 0.4s;
				display:inline-block;

			}
			li{
				&:hover{
					ul {
						transition: ease-in all 0.4s;
						position: initial;
						width: 100%;
						a {
							padding-left: 60px;
						}
					}
					a{
						> div{
							width: auto;
							background: transparent;
							padding-left: 0;
							color: ${colors.White} !Important;
						}
					}
				}
				a{
					> div{
						position: inherit !important;
					}
				}
			}
		}
	`}
`;

function Sidebar(props) {
	const { open = false, children } = props;

	const [isOpen, setIsOpen] = useState(open);

	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<SidebarMenu open={isOpen}>
			<HambergerMenu>
				{!isOpen && (
					<SidebarHamberger onClick={toggleOpen} role="button" tabIndex="0" onKeyDown={toggleOpen}>
						<Icon iconName="icon-lhamberger" />
					</SidebarHamberger>
				)}
				{isOpen && (
					<>
						<SidebarLogo />
						<SidebarClose onClick={toggleOpen} role="button" tabIndex="0" onKeyDown={toggleOpen}>
							<Icon onClick={toggleOpen} iconName="icon-close" />
						</SidebarClose>
					</>
				)}
			</HambergerMenu>
			<SidebarList>{children}</SidebarList>
		</SidebarMenu>
	);
}

export default Sidebar;
