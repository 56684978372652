import React, { useState, useEffect, memo } from 'react';

import { Modal, ModalInnerWrapper, ModalHeader, ModalBody } from '../stories/components/modal';
import { Button, Heading, NoData, Paragraph } from '../stories';

import SearchSVG from '../stories/assets/images/icons/Search.svg';

function LoggedOut() {
	const [timer, setTimer] = useState(11);
	let interval = null;

	useEffect(() => {
		interval = setInterval(() => setTimer((previousState) => previousState - 1), 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	function reloadPage() {
		clearInterval(interval);
		document.location.reload();
	}

	if (timer === 0) {
		reloadPage();
	}

	return (
		<Modal show>
			<ModalInnerWrapper>
				<ModalHeader>
					<Heading pageName>Session logged out</Heading>
				</ModalHeader>
				<ModalBody>
					<NoData Height="300px">
						<img src={SearchSVG} alt="LoggedOut" />
						<Paragraph textCenter textBlueLG>
							You have logged out from the application
						</Paragraph>
						<Paragraph textCenter textBlue>
							<span>You will be redirected to login page in {timer} seconds</span>
						</Paragraph>
					</NoData>
					<Button ButtonHeightSmall primary margin="0 auto" onClick={() => reloadPage()}>
						Login again
					</Button>
				</ModalBody>
			</ModalInnerWrapper>
		</Modal>
	);
}

export default memo(LoggedOut);
