import ExerciseIconSVG from '../stories/assets/images/icons/card-exercise-icon.svg';
import DietIconSVG from '../stories/assets/images/icons/card-diet-icon.svg';
import MedicationIconSVG from '../stories/assets/images/icons/card-medication-icon.svg';
import FluidIntakeIconSVG from '../stories/assets/images/icons/card-fluid-icon.svg';
import MoodIconSVG from '../stories/assets/images/icons/card-smile-icon.svg';
import SleepIconSVG from '../stories/assets/images/icons/card-sleep-icon.svg';

import ExerciseHeaderIconSVG from '../stories/assets/images/icons/activity-exercise-icon.svg';
import DietHeaderIconSVG from '../stories/assets/images/icons/activity-diet-icon.svg';
import MedicationHeaderIconSVG from '../stories/assets/images/icons/activity-medication-icon.svg';
import FluidHeaderIconSVG from '../stories/assets/images/icons/activity-fluid-icon.svg';
import MoodHeaderIconSVG from '../stories/assets/images/icons/activity-mood-icon.svg';
import SleepHeaderIconSVG from '../stories/assets/images/icons/activity-sleep-icon.svg';

import InsulinDinnerIcon from '../stories/assets/images/icons/insulin-dinner-icon.svg';
import InsulinNightIcon from '../stories/assets/images/icons/insulin-night-icon.svg';
import InsulinNoonIcon from '../stories/assets/images/icons/insulin-noon-icon.svg';
import InsulinMorningIcon from '../stories/assets/images/icons/insulin-morning-icon.svg';
import InsulinSnacksIcon from '../stories/assets/images/icons/insulin-snacks-icon.svg';
import InsulinNoMealIcon from '../stories/assets/images/icons/insulin-no-meal-icon.svg';

import EthiopiaFlag from '../assets/images/Ethiopia-Flag.png';

import ZambiaFlag from '../assets/images/Zambia-Flag.png';
import JapanFlag from '../assets/images/Japan-Flag.png';
import ColombiaFlag from '../assets/images/Colombia-Flag.png';
import KenyaFlag from '../assets/images/Kenya-Flag.png';
import GhanaFlag from '../assets/images/Ghana-Flag.png';
import IndianFlag from '../assets/images/Indian-Flag.png';
import MalaysiaFlag from '../assets/images/Malaysia-Flag.png';
import MyanmarFlag from '../assets/images/Myanmar-Flag.png';
import NigeriaFlag from '../assets/images/Nigeria-Flag.png';
import PeruFlag from '../assets/images/Peru-Flag.png';
import PhilippinesFlag from '../assets/images/Philippines-Flag.png';
import ThaiFlag from '../assets/images/Thailand-Flag.png';
import UzbekistanFlag from '../assets/images/Uzbekistan.png';
import VientaFlag from '../assets/images/Vietna-Flag.png';
import TanzaniaFlag from '../assets/images/Tanzania-Flag.png';
import UgandaFlag from '../assets/images/Uganda-Flag.png';

export const MILLISECONDS_IN_A_DAY = 86400000;

export const MAXIMUM_UPLOAD_FILE_SIZE = 5000000;
export const MAXIMUM_UPLOAD_VIDEO_SIZE = 16000000;

export const MAXIMUM_CHARACTERS_ALLOWED = 10000;

export const MAXIMUM_MESSAGE = 30;

export const PER_PAGE = 10;

export const UserStatus = {
	pending: 'pending',
	confirmed: 'confirmed',
};

export const MONTHS_IN_A_YEAR = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const dateSeparatorConfig = {
	chat: 'T_D_DMY', // Example: 03:15 PM/YESTERDAY/01 Apr 2020
	hh_mm_a: 'hh_mm_a', // Example: 03:15 PM
	L_D_M_Y: 'LDMY', // Example: 1 April 2020
	l_d_m_y: 'ldmy', // Example: 01 Apr 2020
	hh_mm: 'hh_mm', // Example: 03:15
	default: 'default', // Example: 01 Apr 2020, 03:15 PM
	defaultDate: 'defaultDate', // Example: 01 Apr 2020
	defaultTime: 'defaultTime', // Example: 03:15 PM
	defaultRecentDate: 'defaultRecentDate', // Example: Today OR Yesterday OR 01 Apr 2020
	defaultRecentDatetime: 'defaultRecentDatetime', // Example: Today, 03:15 PM OR Yesterday, 03:15 PM OR 01 Apr 2020, 03:15 PM
	yyyy_mm_dd: 'yyyy_mm_dd', // Example: 2020-02-29
	d_m: 'd_m', // 05 Apr
};

export const APP_TOKEN = 'APP_TOKEN';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const UID = 'UID';

export const FCM_TOKEN = 'TOKEN';

export const RolesName = {
	Coach: 'Coach',
	Doctor: 'Doctor',
	WeCareAdmin: 'Super admin',
	ClinicAdmin: 'Clinic admin',
	CustomerSupport: 'Support admin',
	Patient: 'Patient',
};

export const Roles = {
	coach: 'Coach',
	doctor: 'Doctor',
	weCareAdmin: 'WeCareAdmin',
	clinicAdmin: 'ClinicAdmin',
	customerSupport: 'CustomerSupport',
	patient: 'Patient',
};

// Here, the "Coach" key has to be same as the "Roles.coach" (above) value
export const RolesFromApi = {
	doctor: 'doctor',
	clinicAdmin: 'clinic-admin',
	Coach: 'coach',
	customerSupport: 'customer-support',
};

export const PhysicallyActiveStatus = {
	active: 'Physically active',
	moderate: 'Moderately active',
	bedridden: 'Bedridden',
	desk_work: 'Desk work',
	sedentary: 'Desk work',
};

export const BP = 'BP';

export const NIL = '---';

export const PENDING_SIGN_UP = 'Pending sign-up';

export const vitalKeys = {
	systolic: 'SYSTOLIC',
	diastolic: 'DIASTOLIC',
	temperature: 'TEMPERATURE',
	heartRate: 'HEART_RATE',
	respiratoryRate: 'RESPIRATORY_RATE',
	oxygenSaturation: 'OXYGEN_SATURATION',
};

export const snackBarErrorTypes = {
	info: 'info',
	error: 'Error',
	success: 'Success',
	warning: 'Warning',
};

export const doctorLabel = 'Dr. ';

export const invalidException = [
	'Transport failure: CPS_UPSTREAM_PER_CONNECTION (status: 0, code: 0)',
	'Transport failure: CPS_UPSTREAM_PER_CONNECTION',
	'Unable to connect: Access Token expired or expiration date invalid',
	'Unable to connect: Access Token expired',
	'disconnected',
	'Twilsock: request timeout',
];

export default {
	patientVault: {
		details: '#details',
		health: '#healthvault',
		clinicalNotes: '#clinicalnotes',
		clinicalDocs: '#clinicaldocs',
		sleep: '#sleep',
		mood: '#mood',
		vitals: '#vitals',
		lab: '#labs',
		abnormality: '#abnormality',
		weights: '#weights',
		vitalDetails: '#vitaldetails',
		labDetails: '#labdetails',
		activityJournal: '#activityjournal',
		insulinRBS: '#insulinrbs',
	},
};

export const ActivityList = {
	OTHERS: 'Others',
	WALK_RUN: 'Walk/Run',
	WORKOUT: 'Workout',
	YOGA: 'Yoga',
	SWIMMING: 'Swimming',
	GAME: 'Game',
	MISC: 'Misc',
	CYCLING: 'Cycling',
	INACTIVE: 'Inactive',
	SPORTS: 'Sports',
	BRISK_WALK: 'Brisk walk',
};

export const Activity = {
	EXERCISE: {
		label: 'Exercise',
		text: 'EXERCISE',
		icon: ExerciseIconSVG,
		className: { exerciseCard: true },
		node: 'exercise',
		activityDate: 'activityDate',
		headerIcon: ExerciseHeaderIconSVG,
		title: 'Active',
		titles: 'Active',
		subTitle: 'day',
		subTitles: 'days',
		inActive: {
			nodeToCheck: 'activity',
			matchingValue: ActivityList.INACTIVE.toLowerCase(),
		},
	},
	DIET: {
		label: 'Diet',
		text: 'DIET',
		icon: DietIconSVG,
		className: { dietCard: true },
		node: 'diet',
		activityDate: 'intakeDate',
		headerIcon: DietHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'on diet plan',
		subTitles: 'on diet plan',
		inActive: {
			nodeToCheck: 'following',
			matchingValue: false,
		},
	},
	MEDICATIONS: {
		label: 'Medication',
		text: 'MEDICATIONS',
		icon: MedicationIconSVG,
		className: { medicationCard: true },
		node: 'medication',
		activityDate: 'intakeDate',
		headerIcon: MedicationHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'on medication',
		subTitles: 'on medication',
		inActive: {
			nodeToCheck: 'following',
			matchingValue: false,
		},
	},
	FLUID_INTAKE: {
		label: 'Water and fluid intake',
		text: 'FLUID_INTAKE',
		icon: FluidIntakeIconSVG,
		className: { waterFluidCard: true },
		node: 'fluid',
		activityDate: 'intakeDate',
		headerIcon: FluidHeaderIconSVG,
		title: '',
		titles: '',
		subTitle: 'Glasses',
		subTitles: 'Glasses',
		inActive: {
			nodeToCheck: 'isCompleted',
			matchingValue: false,
		},
	},
	MOOD: {
		label: 'Moods',
		text: 'MOOD',
		icon: MoodIconSVG,
		className: { moodsCard: true },
		node: 'moods',
		activityDate: 'date',
		headerIcon: MoodHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'logged',
		subTitles: 'logged',
		inActive: {
			nodeToCheck: '',
			matchingValue: '',
		},
	},
	SLEEP: {
		label: 'Sleep',
		text: 'SLEEP',
		icon: SleepIconSVG,
		className: { sleepCard: true },
		node: 'sleep',
		activityDate: 'date',
		headerIcon: SleepHeaderIconSVG,
		title: 'day',
		titles: 'days',
		subTitle: 'Good sleep',
		subTitles: 'Good sleep',
		inActive: {
			nodeToCheck: 'lookupkey',
			matchingValue: 'rested',
		},
	},
};

export const Time = {
	'MINS_60+': '1+ hours',
	MINS_15: '15 mins',
	MINS_45: '45 mins',
	MINS_30: '30 mins',
	MINS_60: '1 hour',
};

export const Intensity = {
	HARD: { text: 'Hard', class: { Hard: true } },
	MODERATE: { text: 'Moderate', class: { Moderate: true } },
	EASY: { text: 'Easy', class: { Easy: true } },
};

export const Days = {
	SUN: 'Sun',
	MON: 'Mon',
	TUE: 'Tue',
	WED: 'Wed',
	THU: 'Thu',
	FRI: 'Fri',
	SAT: 'Sat',
};

export const DayName = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];

export const CountryList = [
	{
		code: '+95',
		flag: MyanmarFlag,
		name: 'Myanmar',
	},
	{
		code: '+91',
		flag: IndianFlag,
		name: 'India',
	},

	{
		code: '+251',
		flag: EthiopiaFlag,
		name: 'Ethiopia',
	},
	{
		code: '+234',
		flag: NigeriaFlag,
		name: 'Nigeria',
	},
	{
		code: '+84',
		flag: VientaFlag,
		name: 'Vietnam',
	},
	{
		code: '+66',
		flag: ThaiFlag,
		name: 'Thailand',
	},
	{
		code: '+51',
		flag: PeruFlag,
		name: 'Peru',
	},
	{
		code: '+998',
		flag: UzbekistanFlag,
		name: 'Uzbekistan',
	},
	{
		code: '+233',
		flag: GhanaFlag,
		name: 'Ghana',
	},
	{
		code: '+63',
		flag: PhilippinesFlag,
		name: 'Philippines',
	},
	{
		code: '+60',
		flag: MalaysiaFlag,
		name: 'Malaysia',
	},
	{
		code: '+260',
		flag: ZambiaFlag,
		name: 'Zambia',
	},
	{
		code: '+81',
		flag: JapanFlag,
		name: 'Japan',
	},
	{
		code: '+254',
		flag: KenyaFlag,
		name: 'Kenya',
	},
	{
		code: '+57',
		flag: ColombiaFlag,
		name: 'Colombia',
	},
	{
		code: '+255',
		flag: TanzaniaFlag,
		name: 'Tanzania',
	},
	{
		code: '+256',
		flag: UgandaFlag,
		name: 'Uganda',
	},
];

export const diabeticTypesList = [
	{
		type: 'S_TYPE1_DIABETES',
		label: 'type1',
		name: 'Type 1',
	},
	{
		type: 'S_TYPE2_DIABETES',
		label: 'type2',
		name: 'Type 2',
	},
	{
		type: 'S_GESTATIONAL_DIABETES',
		label: 'gestational',
		name: 'Gestational diabetes',
	},
];

export const InsulinDosageTime = [
	{
		lookupKey: 'BREAKFAST',
		icon: InsulinMorningIcon,
	},
	{
		lookupKey: 'LUNCH',
		icon: InsulinNoonIcon,
	},
	{
		lookupKey: 'CASUAL',
		icon: InsulinSnacksIcon,
	},
	{
		lookupKey: 'DINNER',
		icon: InsulinDinnerIcon,
	},
	{
		lookupKey: 'BEDTIME',
		icon: InsulinNightIcon,
	},

	{
		lookupKey: null,
		icon: InsulinNoMealIcon,
	},
];

export const MealContext = {
	FASTING: 'fasting',
	PREPRANDIAL: 'preprandial',
	POSTPRANDIAL: 'postprandial',
};
