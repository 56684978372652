import HeartRateSvg from '../../../../stories/assets/images/icons/Heart-Rate.svg';
import TemperatureSvg from '../../../../stories/assets/images/icons/Temperature.svg';
import BloodPressureSvg from '../../../../stories/assets/images/icons/Blood-Pressure.svg';
import RespiratoryRateSvg from '../../../../stories/assets/images/icons/Respiratory-Rate.svg';
import OxygenSaturationSvg from '../../../../stories/assets/images/icons/Oxygen-Saturation.svg';
import { formatDate } from '../Vitals/graph.vitals.functions';
import { MONTHS_IN_A_YEAR } from '../../../../helpers/Constants';
import { getMealContext } from '../../../../helpers/Utilities';

export const InsulinTime = {
	breakfast: 'Breakfast',
	lunch: 'Lunch',
	dinner: 'Dinner',
	bedtime: 'Bedtime',
};

export const vitalCategory = {
	bp: 'bp',
	oxygen: 'oxygen',
	respiratory: 'respiratory',
	heart: 'heart',
	temperature: 'temperature',
};

export function vitalKeysMapper(vitalKeys) {
	return {
		[vitalKeys.systolic]: 'Systolic',
		[vitalKeys.diastolic]: 'Diastolic',
		[vitalKeys.temperature]: 'Temperature',
		[vitalKeys.heartRate]: 'Heart rate',
		[vitalKeys.respiratoryRate]: 'Respiratory rate',
		[vitalKeys.oxygenSaturation]: 'Oxygen saturation',
	};
}

export default {
	bp: {
		heading: 'Blood pressure',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true },
		image: {
			src: BloodPressureSvg,
			alt: 'Blood pressure marker',
		},
	},
	temperature: {
		heading: 'Temperature',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true },
		image: {
			src: TemperatureSvg,
			alt: 'Temperature marker',
		},
	},
	heart: {
		heading: 'Heart rate',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true },
		image: {
			src: HeartRateSvg,
			alt: 'Heart rate marker',
		},
	},
	respiratory: {
		heading: 'Respiratory rate',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true },
		image: {
			src: RespiratoryRateSvg,
			alt: 'Respiratory rate marker',
		},
	},
	oxygen: {
		heading: 'Oxygen saturation',
		panelProps: { BGWithShadow: true, HealthBlocks: true, HealthBlocksLink: true, margin: '0px' },
		image: {
			src: OxygenSaturationSvg,
			alt: 'Oxygen saturation marker',
		},
	},
};

function isLeapYear(year) {
	return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}

export function daysInAYear(year) {
	return isLeapYear(year) ? 366 : 365;
}

export const activities = {
	diet: 'Diet',
	exercise: 'Exercise',
	medication: 'Medication',
	fluid: 'Fluid',
};

export function getInterval(interval = 0, period = Date.now()) {
	const date = new Date(period);
	date.setDate(date.getDate() - interval);
	return date;
}

export function startTimeOffset(date) {
	const arrivedDate = new Date(date);
	arrivedDate.setHours(0);
	arrivedDate.setMinutes(0);
	arrivedDate.setSeconds(0);

	return arrivedDate.toISOString();
}

function sortData(entries = []) {
	entries.sort((testA, testB) => parseFloat(testA.value) - parseFloat(testB.value));

	return entries;
}

export function endTimeOffset(date) {
	const arrivedDate = new Date(date);
	arrivedDate.setHours(23, 59, 59, 999);

	return arrivedDate.toISOString();
}

// function getFormattedData({ category, entries = [], dateFormatter, dateSeparatorConfig }) {
// 	if (entries.length === 1) {
// 		return [
// 			{
// 				name: category,
// 				value: Number(entries[0].value),
// 				measurementUnit: entries[0].unit,
// 				max: {
// 					at: dateFormatter(entries[0].dateTime, dateSeparatorConfig.defaultRecentDatetime),
// 					value: Number(entries[0].value),
// 					label: 'Highest reading',
// 				},
// 			},
// 		];
// 	}
// 	if (entries.length > 1) {
// 		return [
// 			{
// 				name: category,

// 				value: Number(entries[0].value),
// 				measurementUnit: entries[0].unit,
// 				min: {
// 					at: dateFormatter(entries[0].dateTime, dateSeparatorConfig.defaultRecentDatetime),
// 					value: Number(entries[0].value),
// 					label: 'Lowest reading',
// 				},
// 				max: {
// 					at: dateFormatter(
// 						entries[entries.length - 1].dateTime,
// 						dateSeparatorConfig.defaultRecentDatetime
// 					),
// 					value: Number(entries[entries.length - 1].value),
// 					label: 'Highest reading',
// 				},
// 			},
// 			{
// 				name: category,
// 				value: Number(entries[entries.length - 1].value),
// 				measurementUnit: entries[entries.length - 1].unit,
// 				min: {
// 					at: dateFormatter(entries[0].date, dateSeparatorConfig.defaultRecentDatetime),
// 					value: Number(entries[0].value),
// 					label: 'Lowest reading',
// 				},
// 				max: {
// 					at: dateFormatter(
// 						entries[entries.length - 1].dateTime,
// 						dateSeparatorConfig.defaultRecentDatetime
// 					),
// 					value: Number(entries[entries.length - 1].value),
// 					label: 'Highest reading',
// 				},
// 			},
// 		];
// 	}
// 	if (entries.length === 0) {
// 		return [
// 			// {
// 			// 	name: vital,
// 			// 	data: [
// 			// 		{
// 			// 			category: month,
// 			// 			value: 0,
// 			// 		},
// 			// 	],
// 			// },
// 		];
// 	}
// }

const to24HourFormat = (time) => {
	const [hour, minute] = time.split(':');
	let hourInt = parseInt(hour, 10);
	const isPM = time.includes('PM');

	if (isPM && hourInt !== 12) {
		hourInt += 12;
	} else if (!isPM && hourInt === 12) {
		hourInt = 0;
	}

	return `${hourInt.toString().padStart(2, '0')}:${minute}`;
};

export function extractDailyVaultChart({
	values,
	formatters: { dateFormatter, dateSeparatorConfig },
}) {
	const { data = [], start, end } = values;
	const graphs = [];
	const weekObj = {};
	let yMaximum = 0;

	const newData = data.map((x) => ({
		...x,
		value: Number(x.value),
	}));

	const currentDate = new Date(
		new Date(start).getFullYear(),
		new Date(start).getMonth(),
		new Date(start).getDate()
	);

	currentDate.setHours(0);
	currentDate.setMinutes(0);
	currentDate.setSeconds(0);

	const endDateValue = new Date(
		new Date(end).getFullYear(),
		new Date(end).getMonth(),
		new Date(end).getDate()
	);

	endDateValue.setHours(23);
	endDateValue.setMinutes(59);
	endDateValue.setSeconds(59);

	const currentDayRecords = newData.filter((newRecord) =>
		dateFormatter(currentDate, dateSeparatorConfig.defaultDate, false).includes(
			dateFormatter(newRecord.dateTime, dateSeparatorConfig.defaultDate, false)
		)
	);

	for (let step = 0; step < 4; step += 1) {
		const timeStart = dateFormatter(currentDate, dateSeparatorConfig.hh_mm_a, false);
		const timeEnd =
			step !== 3
				? dateFormatter(
						currentDate.setHours(currentDate.getHours() + 6),
						dateSeparatorConfig.hh_mm_a,
						false
					)
				: '12:00 AM';
		const key = `${timeStart}-${timeEnd}`;
		weekObj[key] = [];
		for (let i = 0; i < currentDayRecords.length; i += 1) {
			const dateNext = dateFormatter(
				new Date(currentDayRecords[i].dateTime),
				dateSeparatorConfig.hh_mm_a,
				false
			);
			// const date = newData[i].date.split('T')[0];
			if (yMaximum < currentDayRecords[i].value) {
				yMaximum = currentDayRecords[i].value;
			}

			if (
				to24HourFormat(timeStart) <= to24HourFormat(dateNext) &&
				to24HourFormat(timeEnd) > to24HourFormat(dateNext)
			) {
				weekObj[key].push(currentDayRecords[i]);
			}
		}
	}

	// for (let j = currentDate; j <= endDateValue; j.setHours(j.getHours() + 6)) {
	// 	const weekStart = dateFormatter(j, dateSeparatorConfig.hh_mm_a, false);
	// 	console.log('WEEKSTART', weekStart);
	// 	const key = `${weekStart}`;
	// 	weekObj[key] = [];
	// 	for (let i = 0; i < newData.length; i += 1) {
	// 		const dateNext = dateFormatter(
	// 			new Date(newData[i].dateTime),
	// 			dateSeparatorConfig.hh_mm_a,
	// 			false
	// 		);
	// 		// const date = newData[i].date.split('T')[0];
	// 		if (yMaximum < newData[i].value) {
	// 			yMaximum = newData[i].value;
	// 		}

	// 		if (weekStart === dateNext) {
	// 			weekObj[key].push(newData[i]);
	// 		}
	// 	}
	// 	// currentDate = addDays(currentDate, 1);
	// }
	let validDataCount = Object.keys(weekObj).length;

	for (const [month, entries] of Object.entries(weekObj)) {
		const data = sortData(entries);
		const category = month;

		if (data.length >= 1) {
			data.forEach((dataValue) => {
				const existDataIndex = graphs.findIndex(
					(graphData) => graphData[0].includes(category) && graphData[1] === dataValue.value
				);
				if (existDataIndex >= 0) {
					graphs[existDataIndex][2] = {
						name: category,
						value: Number(dataValue.value),
						measurementUnit: dataValue.unit,
						max: {
							at: dateFormatter(dataValue.dateTime, dateSeparatorConfig.defaultRecentDatetime),
							value: Number(dataValue.value),
							label: 'Highest reading',
							mealContext: getMealContext(dataValue.mealContext),
						},
					};
				} else
					graphs.push([
						category,
						dataValue.value,
						{
							name: category,
							value: Number(dataValue.value),
							measurementUnit: dataValue.unit,
							max: {
								at: dateFormatter(dataValue.dateTime, dateSeparatorConfig.defaultRecentDatetime),
								value: Number(dataValue.value),
								label: 'Highest reading',
								mealContext: getMealContext(dataValue.mealContext),
							},
						},
					]);
			});
		}

		if (data.length === 0) {
			validDataCount -= 1;
			// data.forEach((dataValue) => {
			graphs.push([
				category,
				null,
				{
					name: category,
					value: null,
					measurementUnit: null,
					max: {
						at: null,
						value: null,
						label: 'Highest reading',
						mealContext: null,
					},
				},
			]);
			// });
		}
	}

	return {
		data: graphs,
		isValidGraph: validDataCount > 0,
		yMax: yMaximum,
	};
}

export function extractWeekVaultChart({
	values,
	formatters: { dateFormatter, dateSeparatorConfig },
}) {
	const { data = [], start } = values;
	const graphs = [];

	let yMaximum = 0;

	const weekObj = {};

	const newData = data.map((x) => ({
		...x,
		value: Number(x.value),
	}));

	for (let i = 0; i < 7; i += 1) {
		const weekStartInterval = getInterval(-1 * i, new Date(start));
		const weekStart = dateFormatter(weekStartInterval, dateSeparatorConfig.defaultDate, false);

		const key = `${weekStart}`;
		weekObj[key] = [];

		for (let i = 0; i < newData.length; i += 1) {
			const date = dateFormatter(newData[i].dateTime, dateSeparatorConfig.defaultDate, false);
			if (yMaximum < newData[i].value) {
				yMaximum = newData[i].value;
			}

			if (weekStart === date) {
				weekObj[key].push(newData[i]);
			}
		}
	}

	let validDataCount = Object.keys(weekObj).length;

	for (const [month, entries] of Object.entries(weekObj)) {
		const data = sortData(entries);

		const category = dateFormatter(new Date(month), dateSeparatorConfig.d_m);

		if (data.length >= 1) {
			data.forEach((dataValue) => {
				const existDataIndex = graphs.findIndex(
					(graphData) => graphData[0].includes(category) && graphData[1] === dataValue.value
				);
				if (existDataIndex >= 0) {
					graphs[existDataIndex][2] = {
						name: category,
						value: Number(dataValue.value),
						measurementUnit: dataValue.unit,
						max: {
							at: dateFormatter(dataValue.dateTime, dateSeparatorConfig.defaultRecentDatetime),
							value: Number(dataValue.value),
							label: 'Highest reading',
							mealContext: getMealContext(dataValue.mealContext),
						},
					};
				} else
					graphs.push([
						category,
						dataValue.value,
						{
							name: category,
							value: Number(dataValue.value),
							measurementUnit: dataValue.unit,
							max: {
								at: dateFormatter(dataValue.dateTime, dateSeparatorConfig.defaultRecentDatetime),
								value: Number(dataValue.value),
								label: 'Highest reading',
								mealContext: getMealContext(dataValue.mealContext),
							},
						},
					]);
			});
		}

		if (data.length === 0) {
			validDataCount -= 1;
			// data.forEach((dataValue) => {
			graphs.push([
				category,
				null,
				{
					name: category,
					value: null,
					measurementUnit: null,
					max: {
						at: null,
						value: null,
						label: 'Highest reading',
						mealContext: null,
					},
				},
			]);
			// });
		}
	}

	return {
		data: graphs,
		isValidGraph: validDataCount > 0,
		yMax: yMaximum,
	};
}

export function extractMonthVaultChart({
	values,
	formatters: { dateFormatter, dateSeparatorConfig },
}) {
	const { data = [], start } = values;
	const graphs = [];
	let yMaximum = 0;

	const weekObj = {};

	for (let i = 0; i <= 3; i += 1) {
		const weekStartInterval = getInterval(-7 * i, new Date(start));
		const weekStart = dateFormatter(weekStartInterval, dateSeparatorConfig.defaultDate, false);
		const weekEndInterval = getInterval(-7, weekStart);
		const weekEnd = dateFormatter(weekEndInterval, dateSeparatorConfig.defaultDate, false);

		const key = `${weekStart}/${weekEnd}`;
		weekObj[key] = [];

		for (let i = 0; i < data.length; i += 1) {
			const date = dateFormatter(
				new Date(data[i].dateTime),
				dateSeparatorConfig.defaultDate,
				false
			);
			if (yMaximum < data[i].value) {
				yMaximum = data[i].value;
			}
			if (
				new Date(weekStart).getTime() <= new Date(date).getTime() &&
				new Date(date).getTime() <= new Date(weekEnd).getTime()
			) {
				weekObj[key].push(data[i]);
			}
		}
	}

	const ObjKeys = Object.keys(weekObj);

	ObjKeys.forEach((key) => {
		const [start, end] = key.split('/');
		const [startDate, startMonth] = [
			formatDate(new Date(start).getDate()),
			new Date(start).getMonth(),
		];
		const [endDate, endMonth] = [formatDate(new Date(end).getDate()), new Date(end).getMonth()];
		const displayDate =
			startMonth === endMonth
				? `${MONTHS_IN_A_YEAR[endMonth]} ${startDate}-${endDate}`
				: `${MONTHS_IN_A_YEAR[startMonth]} ${startDate} - ${MONTHS_IN_A_YEAR[endMonth]} ${endDate}`;

		weekObj[key].forEach((graphContent) => {
			const graphObj = {
				category: displayDate,
				measurementUnit: 'mg/dL',
			};

			// if (weekObj[key].length > 0) {
			graphObj.max = {
				at: dateFormatter(graphContent.dateTime, dateSeparatorConfig.defaultRecentDatetime),
				value: graphContent && graphContent.value,
				label: 'Latest reading',
				mealContext: getMealContext(weekObj[key][0].mealContext),
			};
			graphObj.value = graphContent && graphContent.value;
			// }

			graphs.push([displayDate, graphObj.value, graphObj]);
		});
	});
	return {
		data: graphs,
		isValidGraph: true,
		yMax: yMaximum,
	};
}

function generateMonth(start = new Date(), interval = 0) {
	return new Date(start).setMonth(new Date(start).getMonth() + interval);
}

export function extractYearVaultChart({
	values,
	formatters: { dateFormatter, dateSeparatorConfig, MONTHS_IN_A_YEAR },
}) {
	const graphs = [];
	const { data = [], start, end } = values;

	let startYear = start;

	if (new Date(start).getMonth() === new Date(end).getMonth()) {
		startYear = dateFormatter(generateMonth(start, 1), dateSeparatorConfig.defaultDate, false);
	}

	for (let i = 0; i < 12; i += 1) {
		const date = new Date(generateMonth(startYear, i));
		const firstDay = dateFormatter(
			new Date(date.getFullYear(), date.getMonth(), 1),
			dateSeparatorConfig.defaultDate,
			false
		);
		const lastDay = dateFormatter(
			new Date(date.getFullYear(), date.getMonth() + 1, 0),
			dateSeparatorConfig.defaultDate,
			false
		);

		const filteredData = data.filter((item) => {
			const recordedTime = new Date(item.date).getTime();
			return (
				recordedTime >= new Date(firstDay).getTime() && recordedTime <= new Date(lastDay).getTime()
			);
		});

		if (filteredData.length) {
			const dietPlans = filteredData
				.filter((item) => item[activities.diet.toLowerCase()])
				.map((item) => item[activities.diet.toLowerCase()])
				.filter((item) => item.following);
			const exercisePlans = filteredData
				.filter((item) => item[activities.exercise.toLowerCase()])
				.map((item) => item[activities.exercise.toLowerCase()])
				.filter((item) => item.activity !== 'INACTIVE');
			const medicationPlans = filteredData
				.filter((item) => item[activities.medication.toLowerCase()])
				.map((item) => item[activities.medication.toLowerCase()])
				.filter((item) => item.following);

			graphs.push({
				name: `${MONTHS_IN_A_YEAR[new Date(firstDay).getMonth()]}`,
				[activities.diet]: dietPlans.length,
				[activities.medication]: medicationPlans.length,
				[activities.exercise]: exercisePlans.length,
			});
		} else {
			graphs.push({
				name: `${MONTHS_IN_A_YEAR[new Date(firstDay).getMonth()]}`,
				[activities.diet]: 0,
				[activities.medication]: 0,
				[activities.exercise]: 0,
			});
		}
	}

	return graphs;
}
