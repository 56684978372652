import styled from 'styled-components';
import { fontMedium, fontRegular } from '../../assets/styles/typography';

const HeaderInfo = styled.div`
	display: flex;
	flex-direction: column;
	text-align: right;
	margin-right: 15px;
	h3 {
		font-size: 15px;
		line-height: 18px;
		font-family: ${fontMedium.familyName};
		font-weight: 500;
		margin-bottom: 4px;
		span {
			font-size: 15px;
			line-height: 18px;
			font-family: ${fontRegular.familyName};
			font-weight: normal;
		}
	}
	p {
		color: #a2abc1;
		font-size: 13px;
		line-height: 15px;
	}
	@media screen and (max-width: 767px) {
		display: none !important;
	}
`;

export default HeaderInfo;
