import { combineReducers } from 'redux';

import toastReducer from '../components/Toast/toast.store';
import chatReducer from '../pages/Chat/chat.store';
// import DietReducer from '../pages/Diets/diet.store';
import DietReducer from '../pages/DietManagement/diet.store';
import DashboardReducer from '../pages/DashBoard/dashboard.store';
import SnackBarReducer from '../components/Snackbar/snackbar.store';
import loginReducer, { APP_LOGOUT } from '../pages/Login/login.store';
import resetReducer from '../pages/ChangePassword/resetPassword.store';
import PatientListsReducer from '../pages/PatientList/patientList.store';
import HealthVaultReducer from '../pages/HealthVault/health.vault.store';
import clinicalNotesReducer from '../pages/ClinicalNotes/ClinicalNotes.store';
import doctorManagementReducer from '../pages/DoctorManagement/DoctorManagement.store';
import coachManagementReducer from '../pages/CoachManagement/coach.management.store';
import clinicManagementReducer from '../pages/ClinicManagement/clinic.management.store';
import patientInvitationReducer from '../pages/PatientInvitation/patient.invitation.store';
import patientManagementReducer from '../pages/PatientManagement/patient.management.store';
import profileReducer from '../pages/Profile/profile.store';
import healthCardReducer from '../pages/HealthCard/health.card.store';
import insulinRBSReducer from '../pages/InsulinRBSChart/insulinList.store';
import customerSupportManagementReducer from '../pages/CustomerSupportManagement/customerSupport.management.store';

import { APP_TOKEN, REFRESH_TOKEN, FCM_TOKEN } from '../helpers/Constants';

const reducers = {
	toastStore: toastReducer,
	DietStore: DietReducer,
	ChatStore: chatReducer,
	AuthStore: loginReducer,
	SnackBarStore: SnackBarReducer,
	DashboardStore: DashboardReducer,
	ResetPasswordStore: resetReducer,
	HealthVaultStore: HealthVaultReducer,
	PatientListsStore: PatientListsReducer,
	clinicalNotesStore: clinicalNotesReducer,
	doctorManagementStore: doctorManagementReducer,
	coachManagementStore: coachManagementReducer,
	clinicManagementStore: clinicManagementReducer,
	patientInvitationStore: patientInvitationReducer,
	patientManagementStore: patientManagementReducer,
	profileStore: profileReducer,
	healthCardStore: healthCardReducer,
	insulinRBSStore: insulinRBSReducer,
	customerSupportStore: customerSupportManagementReducer,
};

const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
	let appStates = { ...state };
	if (action.type === APP_LOGOUT) {
		[APP_TOKEN, REFRESH_TOKEN, FCM_TOKEN].forEach((item) => localStorage.removeItem(item));
		appStates = undefined;
	}

	return appReducer(appStates, action);
};

export default rootReducer;
