import styled from 'styled-components';

const FormGroup = styled.div`
	display: inline-block;
	width: 100%;
	position: relative;
	box-sizing: border-box;
	margin-bottom: ${(props) => (props.margin ? '15px' : '30px')};
	padding: ${(props) => props.padding};
	margin: ${(props) => props.margin};
	${({ floatingGroup }) => floatingGroup && `position: relative; `}
	${({ countryInput }) =>
		countryInput &&
		`
		position: relative;
		input,select {
			padding-left: 60px;
			pointer-events: none;
			@media screen and (max-width: 767px){
				padding-left: 50px;
			}
		}
		img{
			width: 40px;
			position: absolute;
			left: 10px;
			top: 11px;
			@media screen and (max-width: 767px){
				width:30px;
				left: 11px;
				top: 14px;
			}
		}
	`};
	${({ FormGroupButton }) =>
		FormGroupButton &&
		`position: relative;
		input, select{
			padding-right: 60px;
		}
		button{
			position: absolute;
			right: 12px;
			top: 8px;
		}
    `};
	${({ noMargin }) =>
		noMargin &&
		`margin: 0 0 0 10px;
		 select{
            padding: 4px 0 0 10px;
            height:32px;
		}
	`};
	${({ currencyInputField }) =>
		currencyInputField &&
		`position:relative;
		input{
			padding-right:50px;
		}
		span{
			position: absolute;
            left: 0px;
            bottom: -20px;
            padding-left: 17px;
            span {
               position: absolute;
                top: 8px;
                padding-left: 0px;
            }

		}
         .refundCurrency {
                position: absolute;
                right: 15px;
                left: auto;
                bottom: 15px;
                font-size: 20px;
                color: #6F81A2;
            }

            .questionUnit {
                position: absolute;
                right: 10px;
                left: auto;
                bottom: 10px;
                font-size: 15px;
                color: #6F81A2;
            }
			.passwordUnit {
                position: absolute;
                right: 0px;
                left: auto;
                bottom: 10px;
                font-size: 15px;
                color: #6F81A2;
            }

	`}
`;

export default FormGroup;
