import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';

import { getTwilioToken } from '../chat.state.selectors';
import {
	fetchChannels,
	saveChatObject,
	memberIsTyping,
	fetchTwilioToken,
	saveChatToken,
	saveLastMessageDetails,
	removeChannel,
	addNewUser,
	reArrangeChannels,
	removeMessageDetails,
	// saveMessageHistory,
} from '../chat.store';

import ChatService from '../chat.service';
import { isChatAuthExpired } from '../../../service/manageToken';

function useUsers(isLoggedIn) {
	const dispatch = useDispatch();
	const [clientChat, setClientChat] = useState(null);

	const twilioToken1 = useSelector(({ ChatStore }) => getTwilioToken(ChatStore));

	function subscribeToListeners(chatObject) {
		const Chat = chatObject || clientChat;
		if (Chat) {
			Chat.on('typingStarted', (member) => {
				dispatch(memberIsTyping(member, true));
			});
			Chat.on('typingEnded', (member) => {
				dispatch(memberIsTyping(member, false));
			});
			Chat.on('connectionError', async (error) => {
				if (error.message.includes('CPS_UPSTREAM_PER_CONNECTION')) {
					try {
						const { data = {} } = await fetchTwilioToken();
						const { twilioToken = '' } = data;
						Chat.updateToken(twilioToken);
					} catch (error) {
						return false;
					}
					// console.log('CATCH---- chat connectionError', error);
				}
			});
			Chat.on('tokenAboutToExpire', async () => {
				try {
					const { data = {} } = await fetchTwilioToken();
					const { twilioToken = '' } = data;
					Chat.updateToken(twilioToken);
				} catch (error) {
					// console.log('CATCH---- chat tokenAboutToExpire', error);
					return false;
				}
			});
			Chat.on('disconnected', () => {
				// Handle disconnection
				// You can add your logic here, such as notifying the user or attempting to reconnect
			});
			Chat.on('connectionStateChanged', (state) => {
				if (state === 'disconnected') {
					// Handle disconnection
					// You can add your logic here, such as notifying the user or attempting to reconnect
				}
			});
			Chat.on('tokenExpired', async () => {
				try {
					const { data = {} } = await fetchTwilioToken();
					dispatch(saveChatToken(data));
					const { twilioToken = '' } = data;
					Chat.updateToken(twilioToken);
				} catch (error) {
					// console.log('CATCH---- chat tokenExpired', error);
					return false;
				}
			});
			Chat.on('messageRead', async (messageInfo) => {
				await dispatch(saveLastMessageDetails(messageInfo));
				// await dispatch(reArrangeChannels());
			});
			Chat.on('messageUpdated', async (messageInfo) => {
				await dispatch(saveLastMessageDetails(messageInfo));

				// await dispatch(reArrangeChannels());
			});
			Chat.on('messageRemoved', async (messageInfo) => {
				// await dispatch(saveMessageHistory(messageInfo));
				await dispatch(removeMessageDetails(messageInfo));
				// dispatch(reArrangeChannels());
			});
			Chat.on('messageAdded', async (messageInfo) => {
				await dispatch(saveLastMessageDetails(messageInfo));
				// await dispatch(reArrangeChannels());
			});
			Chat.on('channelAdded', async (channel) => {
				await dispatch(addNewUser(channel));
				dispatch(reArrangeChannels());
			});
			// Chat.on('conversationAdded', async (channel) => {
			// 	await dispatch(addNewUser(channel));
			// });
			Chat.on('memberLeft', async (memberInfo) => {
				await dispatch(removeChannel(memberInfo));
				// dispatch(reArrangeChannels());
			});
			Chat.on('participantLeft', async (memberInfo) => {
				await dispatch(removeChannel(memberInfo));
				dispatch(reArrangeChannels());
			});
			Chat.on('error', (error) => {});
		}
	}

	async function initiateChat() {
		try {
			dispatch(fetchChannels(true));
			// const chat = await ChatService.initializeChat(twilioToken);

			ChatService.initializeChat(twilioToken1)
				.then((chatData) => {
					if (chatData) {
						ChatService.getSubscribedChannels()
							.then((channelDetails) => {
								setClientChat(chatData);
								subscribeToListeners(chatData);
								dispatch(saveChatObject(chatData, channelDetails));
								dispatch(fetchChannels(false));
							})
							.catch((error) => {
								setClientChat(null);
								dispatch(fetchChannels(false));
								dispatch(saveChatObject(null));
							});
					}
				})
				.catch((error) => {
					setClientChat(null);
					dispatch(fetchChannels(false));
					dispatch(saveChatObject(null));
				});

			// if (chat) {
			// 	const channels = await ChatService.getSubscribedChannels();
			// 	// await getChannels(chat);
			// 	setClientChat(chat);
			// 	subscribeToListeners(chat);
			// 	dispatch(saveChatObject(chat, channels));
			// 	dispatch(fetchChannels(false));
			// }
		} catch (error) {
			// console.log('CATCH---- chat initialise', error);
			setClientChat(null);
			dispatch(fetchChannels(false));
			dispatch(saveChatObject(null));
		}
	}

	/*  Additionally added to check the auth token is expired or not if near to expiry then fetch new token and updates the chat instant */

	const manageToken = () => {
		if (isChatAuthExpired(twilioToken1)) {
			fetchTwilioToken().then((tokRes) => {
				if (tokRes && tokRes.data) {
					dispatch(saveChatToken(tokRes.data));
					const { twilioToken = '' } = tokRes.data;
					if (clientChat) clientChat.updateToken(twilioToken);
					else initiateChat(twilioToken);
				}
			});
		}
	};

	// Expiry checks on every 10min
	useEffect(() => {
		// Call the function initially

		// Set up a timer to call the function every 30 minutes
		const intervalId = setInterval(manageToken, 10 * 60 * 1000); // 10 minutes

		// Clear the interval on component unmount to avoid memory leaks
		return () => clearInterval(intervalId);
	}, []);

	//-------------------------------------------------------------------------------------

	const memomizedCall = useCallback(() => {
		if (!clientChat) initiateChat();
	});

	useEffect(() => {
		if (isLoggedIn && twilioToken1) memomizedCall();
		return () => {
			setClientChat(null);
		};
	}, [twilioToken1]);

	return clientChat;
}

export default useUsers;
