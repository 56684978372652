import styled from 'styled-components';
import { colors } from '../colors';

const ModalToggle = styled.button`
	border: 0px;
	background: transparent;
	padding: 8px;
	border-radius: 4px;
	color: ${colors.Grey69};
	&:hover,
	:focus {
		background: ${colors.GreyEC};
	}
	span {
		font-size: 16px;
	}
`;

export default ModalToggle;
