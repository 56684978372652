import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import DropdownWrapper from './dropdownWrapper';
import DropdownMenu from './dropdownMenu';
import { colors } from '../colors';
import FormLabel from '../formGroup/formLabel';

const DropdownToggle = styled.button`
	border: 0px;
	background: transparent;
	padding: 8px;
	border-radius: 4px;
	color: ${colors.Grey69};

	&:hover,
	:focus {
		background: ${colors.GreyEC};
	}
	span {
		font-size: 16px;
	}
	${({ large }) =>
		large &&
		`width:48px;
		height:48px;
    `}
	${({ floatingInput }) =>
		floatingInput &&
		`color: ${colors.BlueBase};
		&:focus + ${FormLabel}, :not(:placeholder-shown) ~ ${FormLabel}{
            position: absolute;
            top: -6px;
            left: 12px;
            opacity: 1;
            font-size: 12px;
            line-height: 14px;
            background: ${colors.White};
            width: auto;
            padding:0 5px;
            color: ${colors.BlueBase};
          }
          &:focus, {
              border-color: ${colors.BlueBase};
			  color: ${colors.BlueBase};
		  }		

	`}
	${({ noHover }) =>
		noHover &&
		`&:hover,
	:focus {
		background: ${colors.White};
	}
    padding-top:11px !important;
    `}
	${({ noInsulinHover }) =>
		noInsulinHover &&
		`&:hover,
	&:focus {
		background: ${colors.White};
		border: 1px solid ${colors.BlueBase};
	}
    padding-top:11px !important;
    `}
	${({ disable }) => disable && `pointer-events: none;`}
`;

function Dropdown(props) {
	const wrapperRef = useRef(null);
	const {
		open = false,
		noHover = false,
		children,
		reverse = false,
		disable = false,
		floatingInput = false,
		height = 'auto',
		overflow = null,
		...attrs
	} = props;
	let { name } = props;
	const [isOpen = false, setIsOpen] = useState(open);
	const toggleOpen = () => setIsOpen(!isOpen);

	if (reverse && isOpen) {
		name = reverse;
	}

	const handleClickOutside = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, false);
		return () => document.removeEventListener('click', handleClickOutside, false);
	}, []);

	return (
		<DropdownWrapper ref={wrapperRef}>
			<DropdownToggle
				noHover={noHover}
				onClick={toggleOpen}
				primary
				disable={disable}
				floatingInput={floatingInput}
			>
				{name}
			</DropdownToggle>
			<DropdownMenu
				onClick={toggleOpen}
				open={isOpen}
				{...attrs}
				height={height}
				overFlow={overflow || null}
			>
				{children}
			</DropdownMenu>
		</DropdownWrapper>
	);
}

export default Dropdown;
