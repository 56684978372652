import { createGlobalStyle } from 'styled-components';
import './iconFont.scss';

import BoldWoff from '../fonts/inter/Inter-Bold.woff2';
import LightBetaWoff from '../fonts/inter/Inter-Light.woff2';
import MediumWoff from '../fonts/inter/Inter-Medium.woff2';
import RegularWoff from '../fonts/inter/Inter-Regular.woff2';

import { colors } from '../../components/colors';
import { fontBold, fontLight, fontMedium, fontRegular } from './typography';

const GlobalStyle = createGlobalStyle`
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border-radius: 10px;
  background: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 14px;
  -webkit-box-shadow: none;
  background-color: ${colors.ScrollbarColor};
}
::-webkit-file-upload-button {
  cursor: pointer;
}
input[type=file] {
  cursor:pointer;
}
// .sb-show-main{
//   padding:0px;
// }
*:focus{
  outline: none;
}
@font-face {
    font-family: ${fontLight.familyName};
    src: local('☺'), local('Inter-Light.woff2'),
    url(${LightBetaWoff}) format('woff2');
   font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: ${fontMedium.familyName};;
    src: local('☺'), local('Inter-Medium.woff2'),
    url(${MediumWoff}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;

}
@font-face {
    font-family: ${fontRegular.familyName};;
    src: local('☺'), local('Inter-Regular.woff2'),
    url(${RegularWoff}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: ${fontBold.familyName};;
    src: local('☺'), local('Inter-Bold.woff2'),
    url(${BoldWoff}) format('woff2');
   font-weight: bold;
    font-style: normal;
    font-display: swap;
}

::-webkit-input-placeholder {
  color: ${colors.PlaceholderColor};
  font-family: ${fontRegular.familyName};
  font-size: 14px;
}
::-moz-placeholder {
  color: ${colors.PlaceholderColor};
  font-family: ${fontRegular.familyName};
  font-size: 14px;
}
:-ms-input-placeholder {
  color: ${colors.PlaceholderColor};
  font-family: ${fontRegular.familyName};
  font-size: 14px;
}
:-moz-placeholder {
  color: ${colors.PlaceholderColor};
  font-family: ${fontRegular.familyName};
  font-size: 14px;
}

html {
    position: relative;
    min-height: 100%;
    line-height: 1.15;
    width: 100%;
    margin: 0;
    padding: 0;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: ${fontRegular.familyName}, sans-serif;
    scrollbar-color: ${colors.ScrollbarColor};
	  scrollbar-width: thin;
  }

  body {
    min-height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    letter-spacing: normal;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${fontRegular.familyName}, sans-serif;
    scrollbar-color: ${colors.ScrollbarColor};
    scrollbar-width: thin;
    font-size: 14px;
  }
  div,
  span,
  p,
  label,
  h1,
  h2,
  h3,
  h4,
  h5,
  input,
  select,
  textarea,
  button {
    margin: 0;
    font-family: ${fontRegular.familyName}, sans-serif;
    overflow-wrap: break-word;
    font-weight: normal;
  }
button{
  cursor: pointer;
  text-decoration: none!important;
}
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  * {
    -webkit-overflow-scrolling: touch;
  }

  :after,
  :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  :focus {
    outline: 0 !important;
  }
  input[type="password"]{
    padding-top: 19px;
    font-size: 16px;
    font-family: ${fontBold.familyName};
    letter-spacing: .5px;
  }
  a{
    color: ${colors.BlueBase};
    text-decoration: underline;
    font-size: 14px;
    line-height: 22px;
    cursor:pointer;
    transition: ease-in all 0.4s;
    word-break: break-all;
    text-decoration: none!important;
    &:hover {
      text-decoration: none;
      color: ${colors.BlueLight};
    }
    button{
      cursor: pointer;
      text-decoration: none;
    }
  }
  b,
  strong {
    font-weight: 900;
  }

  #ToggleButton {
  background-color: lightgray;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  height: 25px;
  position: relative;
  width: 45px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
#ToggleButton {
  top:10px;
}
#ToggleButton:hover { background-color: lightgray; }
#ToggleButton:focus,
#ToggleButton:active { outline: none; }
#ToggleButton::-moz-focus-inner { border: 0; }

.pin {
  background-color: white;
  border-radius: 98px;
  height: 25px;
  left: 1px;
  position: absolute;
  top: 0px;
  width: 25px;
  transition: left ease .5s;
}

#ToggleButton.on {
  background-color: #3263D6;
}

#ToggleButton.on .pin {
  left: 20px;
}

  @media (min-width: 1366px) {
    .container {
      max-width: 1280px;
    }
  }

  ::selection {
    color: ${colors.White};
    background: ${colors.Black};;
  }

  ::-moz-selection {
    color:  ${colors.White};
    background:  ${colors.Black};;
  }
  .jodit-container{width:100% !important;}

  .DayPickerJournal {
    display: flex;
        align-items: center;
        position:relative;
        margin-bottom:24px;
        border:  1px solid #1e326959;
padding: 5px;
padding-right: 15px;
border-radius: 8px;
        img{
            position:absolute;
            z-index:1;
            right:10px;


        }
        &:hover {
            img{
                opacity:0.5;
            }

            }
        h1{
            font-size: 17px;
            color: #0F255F;
            font-family: ${fontMedium.familyName} !important;
            margin-right:5px;
        }
        .DayPickerInput{
            width: 17px;
            z-index: 99;
            cursor: pointer;
            .DayPickerInput-OverlayWrapper {
              .DayPickerInput-Overlay{
                right: -15px !important;
                left: auto !important;
                top: 10px !important;
              }
            }
             input{
                border:none;
                padding:0;
                font-size: 16px;
                color: #0F255F;
                font-family: ${fontMedium.familyName} !important;
                padding-left: 10px;
                opacity: 0;
                width: 20px;
                background: transparent;
                cursor: pointer;

            }
        }
  }

  .DayPickerReminder {
    display: flex;
        align-items: center;
        position:relative;
        /* margin-bottom:24px; */
        border:  1px solid #1e326959;
padding: 15px;
padding-top:10px;
padding-bottom:10px;
padding-left: 15px;
border-radius: 4px;
        img{
            position:absolute;
            z-index:1;
            right:10px;


        }
        &:hover {
            img{
                opacity:0.5;
            }

            }
        h1{
            font-size: 17px;
            color: #0F255F;
            font-family: ${fontMedium.familyName} !important;
            margin-right:5px;
        }
        .DayPickerInput{
            width: 170px;
            z-index: 99;
            cursor: pointer;
            .DayPickerInput-OverlayWrapper {
              .DayPickerInput-Overlay{
                left: 15px !important;
                right: auto !important;
                /* top: 10px !important; */
                z-index: 9999 !important ;
                bottom: -25px;
                position:absolute ;
              }
            }
             input{
                border:none;
                padding:0;
                font-size: 16px;
                color: #0F255F;
                font-family: ${fontMedium.familyName} !important;
                padding-left: 10px;
                opacity: 0;
                width: 170px;
                background: transparent;
                cursor: pointer;

            }
        }
  }

  .DayPickerInput-Overlay {
    z-index:9999999 !important;
   
  }

  .DayPickerInput-OverlayWrapper{
 right: 60px !important;
  }

  .DayPickerInputBlock{
        display: flex;
        align-items: center;
        position:relative;
        margin-bottom:24px;
        img{
            position:absolute;
            z-index:1;
            left:0;


        }
        &:hover {
            img{
                opacity:0.5;
            }

            }
        h1{
            font-size: 17px;
            color: #0F255F;
            font-family: ${fontMedium.familyName} !important;
            margin-left:10px;
        }
        .DayPickerInput{
            width: 17px;
            z-index: 99;
            cursor: pointer;
           
              .DayPickerInput-OverlayWrapper {
              .DayPickerInput-Overlay{
                right: -15px !important;
                left: auto !important;
                top: 10px !important;
              }
            }
            
             input{
                border:none;
                padding:0;
                font-size: 16px;
                color: #0F255F;
                font-family: ${fontMedium.familyName} !important;
                padding-left: 10px;
                opacity: 0;
                width: 20px;
                background: transparent;
                cursor: pointer;

            }
        }

    }
    .DayPicker-Footer {
        width: auto;
	position: absolute;
	top: 10px;
	right: 66px;
    }
`;

export default GlobalStyle;
