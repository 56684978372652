import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';

const Label = styled.label`
	font-size: 14px;
	${({ boldLabel }) =>
		boldLabel &&
		`font-family: ${fontMedium.familyName};
		font-weight:500;
		color: ${colors.BlueBase};
    `};

	${({ overFlow }) =>
		overFlow &&
		`    display: inline-block;
            width: 200px;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
    `};
	color: ${(props) => props.color};
`;

export default Label;
