import styled from 'styled-components';
import { fontBold } from '../../../../stories/assets/styles/typography';

const Container = styled.div`
	position: absolute;
	top: 40px;
	right: 0;
	width: 350px;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.11);
	border-radius: 3px;
	padding 10px;
	// height: 500px;
	// overflow-y: scroll;
	&:before {
		content: ' ';
		position: absolute;
		bottom: 100%;
		right: 2px;
		margin-left: -5px;
		border-width: 10px;
		border-style: solid;
		border-color: transparent transparent #dfe1e5 transparent;

		@media screen and (max-width: 768px) {
			right: 100px;
		}
	}
	@media screen and (max-width: 768px) {
		top: 45px;
		right: -100px;
	}
`;

export const NotificationContainer = styled.div`
	height: 500px;
	overflow-y: scroll;
`;

export const NotificationHolder = styled.div`
	margin: 10px auto;
	width: 300px;
`;

export const NotificationBox = styled.div`
	margin: 10px auto;
	padding: ${(props) => props.padding || 0};
	/* height: 89px; */
	height: auto;
	width: 100%;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0);
	// box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.11);
	${(props) => props.unread && `border: 1px solid #7e9bff;`}
	box-shadow: 0px 1px 9px rgba(126, 155, 255, 0.11);
	border-radius: 8px;
	display: flex;
	align-items: start;
	${({ HealthBlocksLink }) =>
		HealthBlocksLink &&
		`&:hover{
				box-shadow: 0px 2px 21px rgba(69,102,189,0.35);
				border: 1px solid #2C4DA2;
			}
		a{
			padding-right: 10px;
			text-decoration: none;
			display: flex;
			width: 100%;
		}
	`};
`;

export const Text = styled.p`
	text-align: ${(props) => props.textAlign};
	padding: ${(props) => props.padding || 0};
	color: ${(props) => props.colour || '#000'};
	font-size: ${(props) => props.fontSize || '12px'};
	${({ small }) => small && `font-size: 10px;`}
	font-family: ${(props) => props.fontFamily || `${fontBold.familyName},sans-serif`};
	font-weight: 800;
`;

export default Container;
