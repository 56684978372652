import styled from 'styled-components';
import { colors } from '../colors';
import FormLabel from '../formGroup/formLabel';
// import Heading from '../typography/heading';
// eslint-disable-next-line import/no-cycle
import { Col, Row } from '../..';
import { fontBold, fontMedium, fontRegular } from '../../assets/styles/typography';
// import DownArrowIcon from '../../assets/images/icons/down_arrow.svg';
// import BulletPointIcon from '../../assets/images/icons/bullet_point.svg';
// import AdminLoginBgIcon from '../../assets/images/icons/adminLoginBg.svg';

const Section = styled.div`
	position: relative;
	box-sizing: border-box;
	margin: ${(props) => props.margin}!important;
	text-align: ${(props) => props.textRight && 'right'}!important;
	text-align: ${(props) => props.text_left && 'left'}!important;
	padding: ${(props) => props.padding}!important;
	height: ${(props) => props.height}!important;
	width: ${(props) => props.width}!important;
	min-height: ${(props) => props.MinHeight};
	overflow: ${(props) => props.overflow};
	float: left;
	padding-bottom: ${(props) => props.paddingBottom}!important;
	${({ InfoImg }) =>
		InfoImg &&
		`@media screen and (max-width:480px) {
			flex: 0 0 24px;
		}
	`}
	${({ textCenter }) =>
		textCenter &&
		`text-align:center;
	`}
	${({ languageList }) =>
		languageList &&
		`font-weight:bold;
	`}
	${({ flexAlignCenter }) =>
		flexAlignCenter &&
		`display: flex;
		flex-direction: column;
		align-items: center;
	`}
	${({ videoContainer }) =>
		videoContainer &&
		`width:100%;
		}
	`}
	${({ videoLargeView }) =>
		videoLargeView &&
		`position: relative;
		width:100%;
		height:100%;
		video{
			object-fit: contain;
		}
	`}
    	${({ browserWarning }) =>
		browserWarning &&
		`width:870px;
        height:auto;
        background:white;
        border-radius:6px;
        padding:72px 100px 86px;
        display:flex;
        flex-direction:column;
       			@media (max-width: 900px) {

			width:100%;
            justify-content:flex-start;
            text-align:left !important;
            align-items:flex-start;
            padding:36px 16px;
             border-radius:0;

		}
        h1,h2, h3 {
            font-weight:normal;
            width:100%;
            text-align:center;
           			@media (max-width: 900px) {


            text-align:left !important;
		}
        }
        h1{
            font-size: 28px;
            line-height: 33px;
            font-family: ${fontBold.familyName};
			font-weight:800;
             text-align: center;
            margin-top:25px;
            color: #0F255F;
           			@media (max-width: 900px) {

			font-size: 24px !important;
            line-height:30px !important;
		}
        }
        h2{
            font-size: 16px;
            line-height: 22px;
            margin-top:20px;
            color: #3B496D;

        }

        h3 {
            font-size: 16px;
            line-height: 19px;
            color: #DC4F44;
            margin-top:36px;
            margin-bottom:34px;
        }
	`}
    ${({ browserList }) =>
		browserList &&
		`width:100%;
        display:flex;
        align-item:center;
        justify-content:center;
        div{
            display:flex;
            flex-direction:column;
            justify-content:center;
            margin:0 31px;
            align-items:flex-start;
             line-height:30px !important;

            label{
                margin-top:15px;
                font-family: ${fontMedium.familyName};
				font-weight:500;
                text-align:center;
                font-size: 14px;
                line-height: 16px;

                color: #3067FA;

            }
           			@media (max-width: 900px) {
            margin:0 20px;


                img {
                    height:48px;
                }
            }

        }
	`}
     ${({ appList }) =>
		appList &&
		`width:100%;
        height:auto;
        border-radius:5px;
        background: #E5EAEF;
        padding:30px 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:32px;
    display:none;
           			@media (max-width: 900px) {
                                display: flex;

                       }
        h4{
            font-size: 13px;
            line-height: 24px;
            text-transform: uppercase;
            color: #53627C;
        }

        h5{
            font-family: ${fontBold.familyName};
			font-weight:800;
            font-weight: bold;
            font-size: 22px;
            line-height: 25px;
            margin:6px 0 24px 0;
            text-align: center;

            color: #3263D6;
        }
        div{
            img{
                margin:0 4px;
            }
        }
	`}
	${({ videoThumbView }) =>
		videoThumbView &&
		`width: 180px;
		height: 120px;
		position: absolute;
		top: 20px;
		right: 20px;
		border-radius: 8px;
		overflow: hidden;
		padding: 5px;
		background:  ${colors.White};
		@media screen and (max-width:640px) {
			width: 100px;
			height: 80px;
			padding: 2px;
		}
		img{
			border-radius: 8px;
		}
		video{
			width: 170px!important;
			float: left;
			height: 110px!important;
			object-fit: cover;
			border-radius: 8px;
			@media screen and (max-width:640px) {
				width: 96px!important;
				height: 76px!important;
			}
		}
	`}
	${({ videoBlur }) =>
		videoBlur &&
		`:before{
			content:'';
			position:absolute;
			width:170px;
			height:110px;
			border-radius: 8px;
			background: rgba(34, 35, 41, 0.4);
			backdrop-filter: blur(5px);
			@media screen and (max-width:640px) {
				width: 96px;
				height: 76px;

			}
		}
	`}
	${({ videoButtonBlock }) =>
		videoButtonBlock &&
		`position: absolute;
		bottom: 24px;
		width: 100%;
		float: left;
		display: flex;
		align-items: center;
		justify-content: center;
		button{
			span{
			background: transparent;
			}
		}
	`}
	${({ videoCallButtonBlock }) =>
		videoCallButtonBlock &&
		`position: relative!important;
		bottom: 88px;
		@media screen and (max-width:640px) {
			bottom:44px;
		}
	`}
	${({ videoIconHolder }) =>
		videoIconHolder &&
		`position: absolute!important;
		bottom: 10px;
		left: 16px;
		img{
			width:30px!important;
			margin-right:6px;
		}
	`}
	${({ recentDropdown }) =>
		recentDropdown &&
		`background:  ${colors.White};
		padding:0!important;
		button {
			width:auto;
			height:auto;
			background: ${colors.White};
			padding:0px 14px 0 14px;
			border: 1px solid ${colors.Blue99};
			color: ${colors.BlueBase};
			border-radius: 50px;
			text-transform: none;
			text-decoration:none;
			margin-right:8px;
			div{
				font-family: ${fontMedium.familyName};
				font-weight:500;
				padding: 7px 0 6px 0;
				font-size:13px;
			}
			&:hover{
				background: ${colors.BlueDE};
			}
			span{
				font-size:9px;
				margin-left:8px;
			}
		}
	`}
	${({ CountryCodeDropdown }) =>
		CountryCodeDropdown &&
		`background:  ${colors.White};
		padding:0!important;
		@media screen and (max-width:360px) {
			width:100%;
			div{
				width:100%;
			}
		}
		button {
			background: ${colors.White};
			border:1px solid ${colors.VioletDark}!important;
			border-radius: 6px!important;
			height:44px;
			width:116px;
			padding: 0px 8px 0 8px;
			color: ${colors.VioletDark};
			border-radius: 66px;
			text-transform: none;
			text-decoration:none;
			margin-right:10px;
			font-size:16px;
			font-family: ${fontMedium.familyName};
			font-weight:500;
			@media screen and (max-width:360px) {
				width:100%;
				margin-right:0;
			}
			div{
				font-family: ${fontMedium.familyName};
				font-weight:500;
				display: flex;
				align-items: center;
				position:relative;
				width: 100%;
				img{
					margin-right:8px;
				}
			}
			&:hover{
				background: ${colors.White};
			}
			span{
				font-size:9px;
				position:absolute;
				right:0;
			}
			+ div{
				width:calc(100% - -20px);
				padding:0 0 5px 0;
				left: 0;

				img{
					width: 20px;
					max-width: fit-content;
					margin-right:10px;
				}
			}
		}

	`}

	${({ CountryCodeDropdownProfile }) =>
		CountryCodeDropdownProfile &&
		`background:  ${colors.White};
		button {
			height:44px!important;
			width:122px!important;
			padding-right:17px!important;
			border: 1px solid ${colors.GreyDF}!important;
			background:${colors.White}!important;
			margin-right: 17px;
			&:focus, &:active, &:visited{
				border: 1px solid ${colors.VioletDark}!important;
			}
			@media screen and (max-width: 360px){
				width:100%!important;
			}
		}
	`}
	${({ CountryCodeDropdownLogin }) =>
		CountryCodeDropdownLogin &&
		`background:  ${colors.White};
		button {
			padding:0!important;
			border:0!important;
			border-radius:0px!important;
			border-bottom: 1px solid ${colors.VioletDark}!important;
			background:${colors.White}!important;
			&:focus, &:active, &:visited{
				border-bottom: 1px solid ${colors.BlueBase}!important;
			}
			@media screen and (max-width: 360px){
				width:100%!important;
			}
		}
	`}

    ${({ LanguageCodeDropdownLogin }) =>
		LanguageCodeDropdownLogin &&
		`background:  ${colors.White};
        width:100%;
        div{
            width:100%;
        }
		button {
			border-radius:0px!important;
			border: 1px solid #DFDFDF !important;
            border-radius: 6px !important;
            					font-family: ${fontRegular.familyName};
								font-weight:normal;

            height:44px;
			background:${colors.White}!important;
            width:100%;
			&:focus, &:active, &:visited{
				border-bottom: 1px solid ${colors.BlueBase}!important;
			}
			@media screen and (max-width: 360px){
				width:100%!important;
			}
            div{
                	font-family: ${fontRegular.familyName};
					font-weight:normal;
            }
		}
	`}

	
	${({ locationDropdownDetails }) =>
		locationDropdownDetails &&
		`width: 100%;
		height: auto;
		padding:0 0 0 0;
		box-shadow: none!important;
		border-radius: 8px!important;
		z-index: 9999;
		div{
			color: ${colors.VioletDark};
		}
	`}
	${({ datePicker }) =>
		datePicker &&
		`width: auto;
		height: auto;
		@media screen and (max-width:991px) {
			.DayPickerInput{
				margin-right: 0;
			}
		};
	`};
	${({ videoProfileImage }) =>
		videoProfileImage &&
		`display: flex;
		align-items: center;
		justify-content: center;
		background: ${colors.Grey222};
		border-radius: 8px;
		width:100%!important;
		height:100%!important;
		img{
			width: 100px!important;
			height: 100px!important;
			border-radius: 50px!important;
			flex: 0 0 100px;
			border: 6px solid rgba(50, 99, 214, 0.5);
			@media screen and (max-width:640px) {
				width:60px!important;
				height: 60px!important;
				flex: 0 0 60px;
			}
		}
	`}
	${({ specialtyDropdownListHolder }) =>
		specialtyDropdownListHolder &&
		`width: 360px;
		height: auto;
		padding:24px 0 0 0;
		@media screen and (max-width:640px) {
			width: 250px;
		}
		@media screen and (max-width:480px) {
			width: 230px;
		}
		@media screen and (max-width:430px) {
			margin-bottom:10px;
		};
	`};
	${({ videoMuted }) =>
		videoMuted &&
		`position: absolute;
		width: 94px!important;
		height: 94px!important;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		button{
			position: absolute;
			left: 2px;
			bottom: 5px;
			@media screen and (max-width:640px) {
				left: 10px;
				bottom: 16px;
				width: 22px;
   				height: 22px;
			}
		}
	`}
	${({ noDataFound }) =>
		noDataFound &&
		`width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		background: rgba(255,0,0,0.04);
    	color: #e0103b;
    	border: 1px solid rgba(243,33,76,0.12)!important;
		font-size: 13px;
		padding:10px;
	`}
	${({ profileImgBLock }) =>
		profileImgBLock &&
		`width: 112px;
		height: 112px;
		border-radius:50%;
		overflow: hidden;
		background: ${colors.GreyF6};
		position:relative;
		img{
			width: 112px;
			height: 112px;
			object-fit: cover;
			border-radius: 50px;
			max-width: 112xp;
		}
	`}
	${({ editProfileImage }) =>
		editProfileImage &&
		`position: absolute;
		bottom:0;
		left:0;
		width: 113px;
		background: rgba(0, 0, 0, 0.8);
		display: flex;
		align-items: center;
		justify-content: center;
		height: auto;
		padding:5px;
		color:  ${colors.White};
		// margin: 0 0 0 5px;
		span{
			font-size: 12px;
			width: 82px;
			text-align: center;
		}
		input[type="file"]{
			width: 90px;
			height: auto;
			position: absolute;
			left: 6px;
			top: 0;
			opacity: 0;
			padding: 0!important;
			border: none!important;
		}
	`}
	${({ loginButtonBlock }) =>
		loginButtonBlock &&
		`display: flex;
		width: 100%;
		justify-content: center;
	`}

	${({ specialtyDropdown }) =>
		specialtyDropdown &&
		`background:  ${colors.White};
		padding:0!important;
		height:30px;
		button {
			width:auto;
			height:30px;
			background: ${colors.White};
			padding:0px 14px 0 14px;
			border: 1px solid ${colors.Blue99};
			color: ${colors.BlueBase};
			border-radius: 50px;
			text-transform: none;
			text-decoration:none;
			margin-right:8px;
			@media screen and (max-width:480px) {
				margin-right:0px;
			};
			div{
				font-family: ${fontMedium.familyName};
				font-weight:500;
				padding: 0px;
				font-size:13px;
			}
			&:hover,
			:focus{
				background: ${colors.BlueBase};
				color: ${colors.White};
			}
			span{
				font-size:9px;
				margin-left:8px;
			}
		}
	`}
	${({ specialtyDropdownActive }) =>
		specialtyDropdownActive &&
		`button {
			background: ${colors.BlueBase};
			color: ${colors.White};
		}
	`}
	${({ floatingInputDatePicker }) =>
		floatingInputDatePicker &&
		`&:focus + ${FormLabel}, &:not(:placeholder-shown) ~ ${FormLabel}{
			position: absolute;
			top: -6px;
			left: 12px;
			opacity: 1;
			font-size: 12px!important;
			line-height: 14px;
			background: ${colors.White};
			width: auto;
			padding:0 5px;
			color: ${colors.VioletDark}
		}
		input{
			height:44px!important;
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				color: ${colors.VioletDark};
			}
			&::-moz-placeholder { /* Firefox 19+ */
				color: ${colors.VioletDark};
			}
			&:-ms-input-placeholder { /* IE 10+ */
				color: ${colors.VioletDark};
			}
			&:-moz-placeholder { /* Firefox 18- */
				color: ${colors.VioletDark};
			}
        }

		&:not(:placeholder-shown) ~ ${FormLabel}{
			color: ${colors.Grey3F};
		}
		&:focus {
			border-color: ${colors.VioletDark};;
		}

		@media screen and (max-width: 420px) {
			width:100%;
        }
        .react-date-picker__inputGroup__leadingZero {
            margin-top:14px;
        }

    `};
	${({ registerDateSelection }) =>
		registerDateSelection &&
		`.react-date-picker__inputGroup__leadingZero {
            margin-top:13px !important;
        }
	`}
	${({ loginStartupBlock }) =>
		loginStartupBlock &&
		`flex:auto;
		@media screen and (max-width: 764px) {
			margin-bottom:30px;
		}
	`}
	${({ loginContinueBlock }) =>
		loginContinueBlock &&
		`display:flex;
		width:100%;
		align-items:center;
		justify-content:center;
		button{
			min-width:113px;
		}
	`}
	${({ floatingSelect }) =>
		floatingSelect &&
		`width:100%;
	`}
	
	${({ receiverVoiceStatusHolder }) =>
		receiverVoiceStatusHolder &&
		`position: absolute;
		width: 100%;
		height:auto;
		display: flex;
		align-items: center;
		justify-content: center;
		padding:33px 0 0 0;
		z-index:99;
	`}
	${({ reconnectingHolder }) =>
		reconnectingHolder &&
		`position: absolute;
		width: 100%;
		height:auto;
		display: flex;
		align-items: center;
		justify-content: center;
		padding:0 0 0 0;
		z-index:99;
		top:50%;
	`}
	${({ noSchedulesBlock }) =>
		noSchedulesBlock &&
		`width: 100%;
		height:calc(100vh - 196px);
		display: flex;
		align-items: center;
		justify-content: center;
		h1{
			margin-top:48px;
		}
		@media screen and (max-width: 860px) {
			height:auto;
			padding:25px 0;
		}
	`}


	${({ onOffSwitch }) =>
		onOffSwitch &&
		`width: 62px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		input{
			position: absolute;
            opacity: 0;
            pointer-events: none;
		}
		label{
			display: block;
            overflow: hidden;
            cursor: pointer;
            border-radius: 20px;
		}
		span{
			display: block;
            width: 200%;
            margin-left: -100%;
			transition: margin 0.3s ease-in 0s;
			&:after,
			&:before{
				display: block;
				float: left;
				width: 50%;
				height: 30px;
				padding: 7px 0 0 0;
				line-height: 17px;
				font-size: 13px;
				color: white;
				box-sizing: border-box;
			}
		}
		span{
			&:before{
				content: "YES";
				padding-left: 8px;
				background-color: #34A7C1;
				color: #FFFFFF;
				text-align: left;

			}
		}
		span{
			&:after{
				content: "OFF";
				padding-right: 8px;
				background-color: #EEEEEE;
				color: #FFFFFF;
				text-align: right;
			}
		}
		div{
			display: block;
            width: 24px;
            margin: 3px;
            background: #FFFFFF;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 32px;
            border-radius: 20px;
            transition: all 0.3s ease-in 0s;
		}
		input:checked + label span{
			margin-left: 0;
		}
		input:checked + label div{
			right: 0px;
		}
	`}
	${({ receiverVoiceStatusBLock }) =>
		receiverVoiceStatusBLock &&
		`width: auto;
		height:auto;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		color:${colors.Violet4A};
		font-family: ${fontMedium.familyName};
		font-weight:500;
		background:${colors.GreyLight};
		padding: 7px 10px;
		span{
			font-size:15px;
			margin-right:5px;
			:before{
				color:${colors.White};
			}
		}
		h1{
			font-size:13px;
			color:${colors.White};
		}
	`}
	${({ scheduleListing }) =>
		scheduleListing &&
		`width: 100%;
		height:auto;
		border-radius: 5px;
	`}

	${({ notificationsPopup }) =>
		notificationsPopup &&
		`width:352px;
		box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.11);
		border-radius: 8px;
	`}
	${({ notificationsHeaderBlock }) =>
		notificationsHeaderBlock &&
		`display:flex;
		align-items: flex-end;
		justify-content: space-between;
		width: 100%;
		padding:24px 16px 13px;
		border-bottom: 1px solid ${colors.GreyF2};
		button{
			margin:0;
			height:auto;
			font-size: 13px;
			line-height: 16px;
			color:${colors.BlueBase};
		}
	`}
	${({ notificationsContentBlock }) =>
		notificationsContentBlock &&
		`width:100%;
		float:left;
		padding:16px;
		max-height:300px;
		overflow:auto;
		h1{
			color:${colors.Grey606};
		}
	`}
	
	${({ roasterNoDataContainer }) =>
		roasterNoDataContainer &&
		`width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: calc(100vh - 113px);
	`}
	${({ adminTableContainer }) =>
		adminTableContainer &&
		`background: ${colors.White};
		border: 1px solid  ${colors.Border2};
		border-radius: 0 6px 6px 0;
		margin:0 16px 16px;
	`}
	${({ noEventsBlock }) =>
		noEventsBlock &&
		`margin:83px 0 0;
	`}
	${({ slotBlocksListing }) =>
		slotBlocksListing &&
		`display: flex;
		flex-wrap: wrap;
		width: 100%;
		flex: 0 0 auto;
		max-height: 250px;
		overflow-y:auto;
		min-height:165px;
		align-content: flex-start;
		padding-left:20px;
		background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
		@media screen and (max-width:1200px) {
			padding-left:0px;
		}
		@media screen and (max-width:592px) {
			padding-left:24px;
		}
	`}
	${({ callConnectingScreen }) =>
		callConnectingScreen &&
		`width:100%;
		height:100vh;
		display:flex;
		background:${colors.Grey222};
		display: flex;
		align-items: center;
		justify-content: center;
	`}
	${({ callConnectingScreen }) =>
		callConnectingScreen &&
		`width:100%;
		height:100vh;
		display:flex;
		background:${colors.Grey222};
		display: flex;
		align-items: center;
		justify-content: center;
	`}
	
	${({ adminLoginHeader }) =>
		adminLoginHeader &&
		`width:100%;
		height:auto;
		display:flex;
		padding:32px 32px 0;
	`}
	${({ adminLoginBody }) =>
		adminLoginBody &&
		`width:100%;
		height:auto;
		display:flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap:wrap;
		height: calc(100vh - 118px);
		padding:0 130px;
		@media (max-width:1160px){
			padding:0 50px;
			flex-wrap: nowrap;
		}
		@media (max-width:860px){
			flex-direction: column;
			text-align: center;
			overflow: auto;
			height: calc(100vh - 86px);
			padding-top:20px;
		}
		@media (max-width:592px){
			padding:40px 10px 20px;
		}
	`}
	${({ adminLoginFooter }) =>
		adminLoginFooter &&
		`width:100%;
		height:auto;
		color:${colors.VioletDark};
		padding:32px 0;
		text-align:center;
		font-size: 13px;
		line-height: 15px;
		@media (max-width: 530px){
			padding:15px 0;
		}
	`}
	${({ LeftBlock }) =>
		LeftBlock &&
		`@media (max-width: 592px){
			display:none;
		}
	`}
	${({ rightBlock }) =>
		rightBlock &&
		`width:436px;
		@media (max-width: 992px){
			width:350px;
		}
		@media (max-width: 592px){
			width:100%;
		}
	`}
	${({ chooseCurrentLocation }) =>
		chooseCurrentLocation &&
		`left:9px;
	`}
	${({ chooseCurrentAddress }) =>
		chooseCurrentAddress &&
		`left: 0;
		top: -106px;
		ul{
			width: 100%;
			box-shadow: none;
			border-radius: 0 0 8px 8px;
			left:0;

		}

	`}
	${({ timePickerBlock }) =>
		timePickerBlock &&
		`position: absolute;
		top: auto;
		left: 0;
		z-index: 9;
		background:${colors.White};
		// margin:0!important;
		border: 1px solid ${colors.GreyFO};
		box-shadow: 1px 3px 20px rgba(0, 0, 0, 0.2);
   		border-radius: 6px;
		padding:25px!important;
		bottom:30px;
		div{
			margin:0!important;
		}
		${Row}{
			@media (max-width: 600px){
				flex-wrap: wrap;
				margin-top:0px !important;
				button {
					margin:3px 0 3px 0!important;
					width:68%;
				}
			}
		}
		${Col}{
			margin:0 16px 0 0!important;
			:last-child{
				margin:0 0 0 0!important;
			}
			div{
				margin:0!important;
			}
			@media (max-width: 600px){
				margin:3px 0 3px 0!important;
				:last-child{
					margin:3px 0 0 0!important;
				}
			}
		}
	`}
	${({ readOnly }) =>
		readOnly &&
		`pointer-events: none;
	`}
	${({ locationDropdownList }) =>
		locationDropdownList &&
		`margin-top:1px;
		width:346px;
		border: none!important;
		border-radius: 8px 8px 0 0;
		left: 9px;
		button{
			display:flex;
			border:none!important;
			margin:0!important;
			cursor:pointer;
			height:56px;
			width:100%;
			justify-content: flex-start;
			padding-left:18px;
			border-radius: 0;
			background:${colors.White};
			border-radius: 8px 8px 0 0;
			&:hover{
				background:${colors.BlueDE};
			}
			img{
				margin-right:10px!important;
			}
			h1{
				font-size: 15px!important;
				line-height: 20px!important;
				cursor:pointer;

			}
		}
	`}
	${({ findDoctorLocationDetails }) =>
		findDoctorLocationDetails &&
		`top: 39px;
		position: absolute;
		left: 0;
	`}
	${({ endSessionInfo }) =>
		endSessionInfo &&
		`background: ${colors.Grey06};
		border: 0.5px solid ${colors.Grey06};
		box-sizing: border-box;
		border-radius: 6px;
		padding:18px 30px;
		margin:16px 44px -10px;
		text-align: center;
		font-size: 14px;
		line-height: 20px;
		display: flex;
		align-items: flex-start;
		color: ${colors.VioletDark};
		img{
			margin: 0 5px 0 0;
		}
	`}
	${({ privilegedContentBlock }) =>
		privilegedContentBlock &&
		`position:relative;
		padding-left: 45px;
        padding-top: 20px;
        margin-top:5px;
		img{
			position: absolute;
            left: 0;
            top:10px;
		}
		span{
			font-weight: normal;
			font-size: 15px;
			line-height: 21px;
			color: ${colors.Grey53};
			@media screen and (max-width:480px) {
			word-break:break-all;
		}
		}
	`}
	${({ PaymentConsultation }) =>
		PaymentConsultation &&
		`background: ${colors.BlueE7};
		padding:20px 23px;
		border-radius: 10px 10px 0 0;
		width:100%;
	`}
	${({ PaymentMethod }) =>
		PaymentMethod &&
		`width:calc(100% - 46px);
		margin:25px 23px;
	`}
	${({ termsPoints }) =>
		termsPoints &&
		`width:100%;
		float:left;
		button{
			width: 152px;
			height: 48px;
			border-radius: 90px;
			font-family: ${fontBold.familyName};
			font-weight:800;
			font-size: 16px;
   			 margin: 24px auto 7px;
		}
		ul{
			margin:12px 0 0;
			padding:0 0 0 0;
			display: inline-block;
			li{
				display: flex;
				align-items: flex-start;
				font-size: 16px;
				float: left;
				width: 100%;
				padding: 0 0 0 0;
				margin: 0 0 24px 0;
				color:${colors.VioletDark};
				img{
					float:left;
				}
				a{
					float:left;
					font-family: ${fontMedium.familyName};
					font-weight:500;
					margin-top:7px;
					cursor:pointer;

				}
			}
		}
	`}
	${({ Filter }) =>
		Filter &&
		`width: 100%;
		border-bottom:1px solid ${colors.GreyED};
		padding:16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		float:none;
    	margin: 0 0 14px;
	`}
    ${({ PaymentConfirm }) =>
		PaymentConfirm &&
		`width: 100%;
        float:none;
        display:inline-block;
		div {
            width:100%;
        }
	`}
`;

export default Section;
