import styled from 'styled-components';
import { colors } from '../colors';
import DayImage from '../../assets/images/activityJournalIcon/dayImg.svg';
import DayImageActive from '../../assets/images/activityJournalIcon/dayImageActive.svg';
import DietDayActive from '../../assets/images/activityJournalIcon/dietDayImageActive.svg';
import MedicationDayActive from '../../assets/images/activityJournalIcon/medicationDayImageActive.svg';
import WaterDayActive from '../../assets/images/activityJournalIcon/waterDayImageActive.svg';
import MoodsDayActive from '../../assets/images/activityJournalIcon/moodsDayImageActive.svg';
import SleepDayActive from '../../assets/images/activityJournalIcon/sleepDayImageActive.svg';
import ExerciseInActive from '../../assets/images/activityJournalIcon/inactive-exercise.svg';
import DietInActive from '../../assets/images/activityJournalIcon/inactive-diet.svg';
import MedicationInActive from '../../assets/images/activityJournalIcon/inactive-medication.svg';
import InTakeInActive from '../../assets/images/activityJournalIcon/inactive-intake.svg';
import MoodInActive from '../../assets/images/activityJournalIcon/inactive-mood.svg';
import SleepInActive from '../../assets/images/activityJournalIcon/inactive-sleep.svg';
import { fontBold, fontMedium } from '../../assets/styles/typography';

const Cards = styled.div`
	display: inline-block;
	width: 100%;
	background: ${colors.White};
	border-radius: 4px;
	border: 1px solid ${colors.Border2};

	${({ noHover }) =>
		noHover &&
		`pointer-events: none;
        `}

	${({ scroll }) =>
		scroll &&
		`&: hover {
            border-radius: 0px;
            border: 0px;
            box-shadow: none !important;
            cursor: default !important;
        }`}

	${({ exerciseCard }) =>
		exerciseCard &&
		`background:${colors.White};
        cursor: pointer;
        border: 2px solid transparent !important;
        	border-radius: 0!important;

        box-sizing: border-box;
        box-shadow: none;
        cursor: default;
        padding:0;
        
        .activeCard {
            box-shadow: 2px 2px 2px 2px #D7F5E6; 
            border-radius: 8px 8px 8px 8px !important;
            cursor: pointer;
        }

        
        &: hover {
            border-radius: 8px 8px 8px 8px !important;
            border: 2px solid #6FDFB6 !important;
            box-shadow: 2px 2px 5px 2px #6FDFB6;
            cursor: pointer;
        }

        .cardHeader{
            border: none !important;
            background: #D7F5E6;
            padding:20px 16px;
            display: flex;
            align-items:Center;
            border-radius: 8px 8px 0 0;
           
            div{
                width:48px;
                height:48px;
                margin-right:12px;
                img{
                    width: 48px!important;
                    height: 48px!important;
                    max-width: 48px!important;
                }
                + div{
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
            h1{
                font-family: ${fontMedium.familyName} !important;
                font-weight:500;
                font-size: 16px;
                color: #284744;
                overflow: hidden;
                img {
                    width: 122px !important;
                     height: auto !important;
                    max-width: 22px !important;
                    +span +img {
                        margin-top:-4px;
                    }
                }
                span {
                   padding: 0 6px 0 8px;
                font-size: 22px;
                font-family: ${fontBold.familyName} !important;
                font-weight:800;
                margin-top: -10px;
                display: block;
                }
            }
            p{
                font-size: 14px;
                color: #284744;
                margin:2px 0 0 0;
                padding:0;
            }
            &.currentActivityHeader {
                div {
                    width:auto !important;
                    height:auto !important;
                }
            }
        }
       
        .card-body{
            border: 1px solid #E5E7E9;
            border-top: 0;
            box-sizing: border-box;
            padding:16px;
            border-radius:0 0 8px 8px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background:white;
            
            .dayList{
                width:32px;
                max-width:32px;
                flex:0 0 32px;
                margin:0 10px;
                display: flex;
                align-items:Center;
                min-height: 60px;
                position: relative;
                justify-content: center;
                &:before{
                    content:"";
                    background: url(${DayImage}) no-repeat;
                    width:32px;
                    max-width:32px;
                    flex:0 0 32px;
                    height:32px;
                    position: absolute;
                    top: 0;
                }
                @media screen and (max-width: 480px) {
                    width:26px;
                max-width:26px;
                flex:0 0 26px;
                margin:0 5px;
                 &:before{
                    width:26px;
                    max-width:26px;
                    flex:0 0 26px;
                    height:26px;
                    background-size: 26px;
                }
                }
                h1{
                    font-size: 16px;
                    color: #E6E5E5;
                    display:flex;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    width:22px;
                    height:22px;
                    align-items:flex-end;
                }
                &.active {
                    &:before{
                        background: url(${DayImageActive}) no-repeat;
                         @media screen and (max-width: 480px) {
                             background-size:26px;
                         }

                    }
                    h1{
                        color: #284744;
                    }
                }
                &.inactive {
                    &:before{
                        background: url(${ExerciseInActive}) no-repeat;
                    }
                    h1{
                        color: #284744;
                    }
                }
                &.activeSunday{
                    h1{
                        background: #EEEEEF;
                        border-radius: 50%;
                        color: #284744;
                    }
    `};
	${({ dietCard }) =>
		dietCard &&
		`background:${colors.White};
        cursor: pointer;
        border: 2px solid transparent !important;
        	border-radius: 0!important;

        box-sizing: border-box;
        box-shadow: none;
        cursor: default;
        padding:0;
        
        .activeCard {
            box-shadow: 2px 2px 2px 2px #FFEBD1; 
            border-radius: 8px 8px 8px 8px !important;
            cursor: pointer;
        }

        
        &: hover {
            border-radius: 8px 8px 8px 8px !important;
            border: 2px solid #e2caac !important;
            box-shadow: 2px 2px 5px 2px #e2caac;
            cursor: pointer;
        }

        .cardHeader{
            border: none !important;
            background: #FFEBD1;
            padding:20px 16px;
            display: flex;
            align-items:Center;
            border-radius: 8px 8px 0 0;


           
            div{
                width:48px;
                height:48px;
                margin-right:12px;
                img{
                    width: 48px!important;
                    height: 48px!important;
                    max-width: 48px!important;
                }
                + div{
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
            h1{
                font-family: ${fontMedium.familyName} !important;
                font-weight:500;
                font-size: 16px;
                color: #5F4728;
                overflow: hidden;
                img {
                    width: 122px !important;
                     height: auto !important;
                    max-width: 22px !important;
                    +span +img {
                        margin-top:-4px;
                    }
                }
                span {
                   padding: 0 6px 0 8px;
                font-size: 22px;
                font-family: ${fontBold.familyName} !important;
                font-weight:800;
                margin-top: -10px;
                display: block;
                }
            }
            p{
                font-size: 14px;
                color: #5F4728;
                margin:2px 0 0 0;
                padding:0;
            }
            &.currentActivityHeader {
                div {
                    width:auto !important;
                    height:auto !important;
                }
            }
        }
       
        .card-body{
            border: 1px solid #E5E7E9;
            border-top: 0;
            box-sizing: border-box;
            padding:16px;
            border-radius:0 0 8px 8px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background:white;
            
            .dayList{
                width:32px;
                max-width:32px;
                flex:0 0 32px;
                margin:0 10px;
                display: flex;
                align-items:Center;
                min-height: 60px;
                position: relative;
                justify-content: center;
                &:before{
                    content:"";
                     background: url(${DayImage}) no-repeat;
                    width:32px;
                    max-width:32px;
                    flex:0 0 32px;
                    height:32px;
                    position: absolute;
                    top: 0;
                }
                @media screen and (max-width: 480px) {
                    width:26px;
                max-width:26px;
                flex:0 0 26px;
                margin:0 5px;
                 &:before{
                    width:26px;
                    max-width:26px;
                    flex:0 0 26px;
                    height:26px;
                    background-size: 26px;
                }
                }
                h1{
                    font-size: 16px;
                    color: #5F4728;
                    display:flex;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    width:22px;
                    height:22px;
                    align-items:flex-end;
                }
                &.active {
                    &:before{
                        background: url(${DietDayActive}) no-repeat;
                         @media screen and (max-width: 480px) {
                             background-size:26px;
                         }

                    }
                    h1{
                        color: #5F4728;
                    }
                }
                &.inactive {
                    &:before{
                        background: url(${DietInActive}) no-repeat;
                    }
                    h1{
                        color: #5F4728;
                    }
                }
                &.activeSunday{
                    h1{
                        background: #EEEEEF;
                        border-radius: 50%;
                        color: #5F4728;
                    }
                }

            }
        }
    `};
	${({ medicationCard }) =>
		medicationCard &&
		`background:${colors.White};
        cursor: pointer;
        border: 2px solid transparent !important;
        	border-radius: 0!important;

        box-sizing: border-box;
        box-shadow: none;
        cursor: default;
        padding:0;
        
        .activeCard {
            box-shadow: 2px 2px 2px 2px #EAE5F9; 
            border-radius: 8px 8px 8px 8px !important;
            cursor: pointer;
        }

        
        &: hover {
            border-radius: 8px 8px 8px 8px !important;
            border: 2px solid #a59dc7 !important;
            box-shadow: 2px 2px 5px 2px #a59dc7;
            cursor: pointer;
        }

        .cardHeader{
            border: none !important;
            background: #EAE5F9;
            padding:20px 16px;
            display: flex;
            align-items:Center;
            border-radius: 8px 8px 0 0;


           
            div{
                width:48px;
                height:48px;
                margin-right:12px;
                img{
                    width: 48px!important;
                    height: 48px!important;
                    max-width: 48px!important;
                }
                + div{
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
            h1{
                font-family: ${fontMedium.familyName} !important;
                font-weight:500;
                font-size: 16px;
                color: #372C65;
                overflow: hidden;
                img {
                    width: 122px !important;
                     height: auto !important;
                    max-width: 22px !important;
                    +span +img {
                        margin-top:-4px;
                    }
                }
                span {
                   padding: 0 6px 0 8px;
                font-size: 22px;
                font-family: ${fontBold.familyName} !important;
                font-weight:800;
                margin-top: -10px;
                display: block;
                }
            }
            p{
                font-size: 14px;
                color: #372C65;
                margin:2px 0 0 0;
                padding:0;
            }
            &.currentActivityHeader {
                div {
                    width:auto !important;
                    height:auto !important;
                }
            }
        }
       
        .card-body{
            border: 1px solid #EAE5F9;
            border-top: 0;
            box-sizing: border-box;
            padding:16px;
            border-radius:0 0 8px 8px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background:white;
            
            .dayList{
                width:32px;
                max-width:32px;
                flex:0 0 32px;
                margin:0 10px;
                display: flex;
                align-items:Center;
                min-height: 60px;
                position: relative;
                justify-content: center;
                &:before{
                    content:"";
                     background: url(${DayImage}) no-repeat;
                    width:32px;
                    max-width:32px;
                    flex:0 0 32px;
                    height:32px;
                    position: absolute;
                    top: 0;
                }
                @media screen and (max-width: 480px) {
                    width:26px;
                max-width:26px;
                flex:0 0 26px;
                margin:0 5px;
                 &:before{
                    width:26px;
                    max-width:26px;
                    flex:0 0 26px;
                    height:26px;
                    background-size: 26px;
                }
                }
                h1{
                    font-size: 16px;
                    color: #372C65;
                    display:flex;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    width:22px;
                    height:22px;
                    align-items:flex-end;
                }
                &.active {
                    &:before{
                        background: url(${MedicationDayActive}) no-repeat;
                         @media screen and (max-width: 480px) {
                             background-size:26px;
                         }

                    }
                    h1{
                        color: #372C65;
                    }
                }
                &.inactive {
                    &:before{
                        background: url(${MedicationInActive}) no-repeat;
                    }
                    h1{
                        color: #284744;
                    }
                }
                &.activeSunday{
                    h1{
                        background: #EEEEEF;
                        border-radius: 50%;
                        color: #372C65;
                    }
                }

            }
        }
    `};
	${({ waterFluidCard }) =>
		waterFluidCard &&
		`background:${colors.White};
        cursor: pointer;
        border: 2px solid transparent !important;
        	border-radius: 0!important;

        box-sizing: border-box;
        box-shadow: none;
        cursor: default;
        padding:0;
        
        .activeCard {
            box-shadow: 2px 2px 2px 2px #CFEFFF; 
            border-radius: 8px 8px 8px 8px !important;
            cursor: pointer;
        }

        
        &: hover {
            border-radius: 8px 8px 8px 8px !important;
            border: 2px solid #a8def9 !important;
            box-shadow: 2px 2px 5px 2px #a8def9;
            cursor: pointer;
        }

        .cardHeader{
            border: none !important;
            background: #CFEFFF;
            padding:20px 16px;
            display: flex;
            align-items:Center;
            border-radius: 8px 8px 0 0;


           
            div{
                width:48px;
                height:48px;
                margin-right:12px;
                img{
                    width: 48px!important;
                    height: 48px!important;
                    max-width: 48px!important;
                }
                + div{
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
            h1{
                font-family: ${fontMedium.familyName} !important;
                font-weight:500;
                font-size: 16px;
                color: #103A5B;
                overflow: hidden;
                img {
                    width: 122px !important;
                     height: auto !important;
                    max-width: 22px !important;
                    +span +img {
                        margin-top:-4px;
                    }
                }
                span {
                   padding: 0 6px 0 8px;
                font-size: 22px;
                font-family: ${fontBold.familyName} !important;
                font-weight:800;
                margin-top: -10px;
                display: block;
                }
            }
            p{
                font-size: 14px;
                color: #103A5B;
                margin:2px 0 0 0;
                padding:0;
            }
            &.currentActivityHeader {
                div {
                    width:auto !important;
                    height:auto !important;
                }
            }
        }
       
        .card-body{
            border: 1px solid #E5E7E9;
            border-top: 0;
            box-sizing: border-box;
            padding:16px;
            border-radius:0 0 8px 8px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background:white;
            
            .dayList{
                width:32px;
                max-width:32px;
                flex:0 0 32px;
                margin:0 10px;
                display: flex;
                align-items:Center;
                min-height: 60px;
                position: relative;
                justify-content: center;
                &:before{
                    content:"";
                     background: url(${DayImage}) no-repeat;
                    width:32px;
                    max-width:32px;
                    flex:0 0 32px;
                    height:32px;
                    position: absolute;
                    top: 0;
                }
                @media screen and (max-width: 480px) {
                    width:26px;
                max-width:26px;
                flex:0 0 26px;
                margin:0 5px;
                 &:before{
                    width:26px;
                    max-width:26px;
                    flex:0 0 26px;
                    height:26px;
                    background-size: 26px;
                }
                }
                h1{
                    font-size: 16px;
                    color: #103A5B;
                    display:flex;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    width:22px;
                    height:22px;
                    align-items:flex-end;
                }
                &.active {
                    &:before{
                        background: url(${WaterDayActive}) no-repeat;
                         @media screen and (max-width: 480px) {
                             background-size:26px;
                         }

                    }
                    h1{
                        color: #103A5B;
                    }
                }
                &.inactive {
                    &:before{
                        background: url(${InTakeInActive}) no-repeat;
                    }
                    h1{
                        color: #103A5B;
                    }
                }
                &.activeSunday{
                    h1{
                        background: #EEEEEF;
                        border-radius: 50%;
                        color: #103A5B;
                    }
                }

            }
        }
    `};
	${({ moodsCard }) =>
		moodsCard &&
		`background:${colors.White};
        cursor: pointer;
        border: 2px solid transparent !important;
        	border-radius: 0!important;

        box-sizing: border-box;
        box-shadow: none;
        cursor: default;
        padding:0;
        
        .activeCard {
            box-shadow: 2px 2px 2px 2px #D8E3FF; 
            border-radius: 8px 8px 8px 8px !important;
            cursor: pointer;
        }

        
        &: hover {
            border-radius: 8px 8px 8px 8px !important;
            border: 2px solid #6781c5 !important;
            cursor: pointer;
            box-shadow: 2px 2px 5px 2px #6781c5;
        }

        .cardHeader{
            border: none !important;
            background: #D8E3FF;
            padding:20px 16px;
            display: flex;
            align-items:Center;
            border-radius: 8px 8px 0 0;


           
            div{
                width:48px;
                height:48px;
                margin-right:12px;
                img{
                    width: 48px!important;
                    height: 48px!important;
                    max-width: 48px!important;
                }
                + div{
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
            h1{
                font-family: ${fontMedium.familyName} !important;
                font-weight:500;
                font-size: 16px;
                color: #032780;
                overflow: hidden;
                img {
                    width: 122px !important;
                     height: auto !important;
                    max-width: 22px !important;
                    +span +img {
                        margin-top:-4px;
                    }
                }
                span {
                   padding: 0 6px 0 8px;
                font-size: 22px;
                font-family: ${fontBold.familyName} !important;
                font-weight:800;
                margin-top: -10px;
                display: block;
                }
            }
            p{
                font-size: 14px;
                color: #032780;
                margin:2px 0 0 0;
                padding:0;
            }
            &.currentActivityHeader {
                div {
                    width:auto !important;
                    height:auto !important;
                }
            }
        }
       
        .card-body{
            border: 1px solid #E5E7E9;
            border-top: 0;
            box-sizing: border-box;
            padding:16px;
            border-radius:0 0 8px 8px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background:white;
            
            .dayList{
                width:32px;
                max-width:32px;
                flex:0 0 32px;
                margin:0 10px;
                display: flex;
                align-items:Center;
                min-height: 60px;
                position: relative;
                justify-content: center;
                &:before{
                    content:"";
                     background: url(${DayImage}) no-repeat;
                    width:32px;
                    max-width:32px;
                    flex:0 0 32px;
                    height:32px;
                    position: absolute;
                    top: 0;
                }
                @media screen and (max-width: 480px) {
                    width:26px;
                max-width:26px;
                flex:0 0 26px;
                margin:0 5px;
                 &:before{
                    width:26px;
                    max-width:26px;
                    flex:0 0 26px;
                    height:26px;
                    background-size: 26px;
                }
                }
                h1{
                    font-size: 16px;
                    color: #032780;
                    display:flex;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    width:22px;
                    height:22px;
                    align-items:flex-end;
                }
                &.active {
                    &:before{
                        background: url(${MoodsDayActive}) no-repeat;
                         @media screen and (max-width: 480px) {
                             background-size:26px;
                         }

                    }
                    h1{
                        color: #032780;
                    }
                }
                &.inactive {
                    &:before{
                        background: url(${MoodInActive}) no-repeat;
                    }
                    h1{
                        color: #284744;
                    }
                }
                &.activeSunday{
                    h1{
                        background: #EEEEEF;
                        border-radius: 50%;
                        color: #032780;
                    }
                }

            }
        }
    `};
	${({ sleepCard }) =>
		sleepCard &&
		`background:${colors.White};
        cursor: pointer;
        border: 2px solid transparent !important;
        	border-radius: 0!important;

        box-sizing: border-box;
        box-shadow: none;
        cursor: default;
        padding:0;
        
        .activeCard {
            box-shadow: 2px 2px 2px 2px #DFE2EE; 
            border-radius: 8px 8px 8px 8px !important;
            cursor: pointer;
        }

        
        &: hover {
            border-radius: 8px 8px 8px 8px !important;
            border: 2px solid #838cbc !important;
            box-shadow: 2px 2px 5px 2px #838cbc;
            cursor: pointer;
        }

        .cardHeader{
            border: none !important;
            background: #DFE2EE;
            padding:20px 16px;
            display: flex;
            align-items:Center;
            border-radius: 8px 8px 0 0;


           
            div{
                width:48px;
                height:48px;
                margin-right:12px;
                img{
                    width: 48px!important;
                    height: 48px!important;
                    max-width: 48px!important;
                }
                + div{
                    width: auto;
                    height: auto;
                    margin-right: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }
            }
            h1{
                font-family: ${fontMedium.familyName} !important;
                font-weight:500;
                font-size: 16px;
                color: #303861;
                overflow: hidden;
                img {
                    width: 122px !important;
                     height: auto !important;
                    max-width: 22px !important;
                    +span +img {
                        margin-top:-4px;
                    }
                }
                span {
                   padding: 0 6px 0 8px;
                font-size: 22px;
                font-family: ${fontBold.familyName} !important;
                font-weight:800;
                margin-top: -10px;
                display: block;
                }
            }
            p{
                font-size: 14px;
                color: #0303861;
                margin:2px 0 0 0;
                padding:0;
            }
            &.currentActivityHeader {
                div {
                    width:auto !important;
                    height:auto !important;
                }
            }
        }
       
        .card-body{
            border: 1px solid #E5E7E9;
            border-top: 0;
            box-sizing: border-box;
            padding:16px;
            border-radius:0 0 8px 8px ;
            display: flex;
            align-items: center;
            justify-content: center;
            background:white;
            
            .dayList{
                width:32px;
                max-width:32px;
                flex:0 0 32px;
                margin:0 10px;
                display: flex;
                align-items:Center;
                min-height: 60px;
                position: relative;
                justify-content: center;
                &:before{
                    content:"";
                     background: url(${DayImage}) no-repeat;
                    width:32px;
                    max-width:32px;
                    flex:0 0 32px;
                    height:32px;
                    position: absolute;
                    top: 0;
                }
                @media screen and (max-width: 480px) {
                    width:26px;
                max-width:26px;
                flex:0 0 26px;
                margin:0 5px;
                 &:before{
                    width:26px;
                    max-width:26px;
                    flex:0 0 26px;
                    height:26px;
                    background-size: 26px;
                }
                }
                h1{
                    font-size: 16px;
                    color: #303861;
                    display:flex;
                    justify-content: center;
                    position: absolute;
                    bottom: 0;
                    width:22px;
                    height:22px;
                    align-items:flex-end;
                }
                &.active {
                    &:before{
                        background: url(${SleepDayActive}) no-repeat;
                         @media screen and (max-width: 480px) {
                             background-size:26px;
                         }

                    }
                    h1{
                        color: #032780;
                    }
                }
                &.inactive {
                    &:before{
                        background: url(${SleepInActive}) no-repeat;
                    }
                    h1{
                        color: #303861;
                    }
                }
                &.activeSunday{
                    h1{
                        background: #EEEEEF;
                        border-radius: 50%;
                        color: #303861;
                    }
                }

            }
        }
    `};
`;

export default Cards;
