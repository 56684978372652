import thunk from 'redux-thunk';
// import * as Sentry from '@sentry/react';
import { createStore, applyMiddleware, compose } from 'redux';

import AppReducers from './index.reducers';

// const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const middleWares = [thunk];

if (process.env.NODE_ENV === 'development') {
	const { logger } = require('redux-logger');

	middleWares.push(logger);
}

export const AppStore = createStore(AppReducers, compose(applyMiddleware(...middleWares)));
