import { POST, PATCH } from '../../service/HttpService';

import { getUserProfile, isUnauthorized } from '../Login/login.store';

import { errorHandling, showMessage } from '../../components/Toast/toast.store';
import { MixPanelService } from '../../service/MixpanelService';

const FILE = 'FILE://PROFILE';

const generateActionType = (type) => `${FILE}/${type}`;

// Action Types
const GET_PROFILE = generateActionType('GET_PROFILE');
const GET_BEFORE = generateActionType('GET_BEFORE');
const GET_STATUS = generateActionType('GET_STATUS');
const CLEAR_ALL = generateActionType('CLEAR_ALL');

export async function sendOTP({ data }) {
	const config = { data, url: `/accounts` };
	const response = await PATCH(config);
	return response;
}

export async function verifyOtp({ data }) {
	const config = { data, url: `/accounts/verify` };
	const response = await PATCH(config);
	return response;
}

export function showToaster({ message, type }) {
	return async function (dispatch) {
		dispatch(
			showMessage({
				isMessage: true,
				messageType: type || 'Success',
				messageContent: { 0: { message, key: '' } },
			})
		);
	};
}

// Reducer
const initialState = {
	fetching: false,
	data: {},
	statusCode: 0,
};

export function fetchUserProfile() {
	return async function (dispatch) {
		try {
			dispatch(getUserProfile());
		} catch (error) {
			isUnauthorized(dispatch, error);
		}
	};
}

export function editProfile(datas = {}) {
	const postConfig = { data: datas, url: `users` };
	return async function (dispatch) {
		try {
			await PATCH(postConfig);
			MixPanelService.track('user_profile', { context: 'update' });
			dispatch(getUserProfile());
			dispatch(
				showMessage({
					isMessage: true,
					messageType: 'Success',
					messageContent: { 0: { message: 'Profile successfully updated', key: '' } },
				})
			);
		} catch (error) {
			if (isUnauthorized(dispatch, error)) return;

			dispatch(errorHandling(error));
		}
	};
}

export async function fileUpload(image = {}) {
	const { type = '' } = image;
	if (type === '') return '';
	const config = {
		filename: 'dp',
		contentType: type,
	};

	const awsConfig = { url: 'users/avatar', data: config };
	const { data = {} } = await POST(awsConfig);
	const { fields } = data;
	const { key } = fields;
	const formData = new FormData();

	Object.keys(fields).forEach((key) => {
		formData.append(key, fields[key]);
	});

	formData.append('file', image);
	await POST({
		url: data.url,
		data: formData,
		appConfig: { baseURL: '', doNotNeedAuthorizationHeader: true },
		headers: { 'Content-Type': 'Multipart/form-data' },
	});

	return key;
}

function profileReducer(state = initialState, action = null) {
	switch (action.type) {
		case GET_STATUS: {
			return {
				...state,
				statusCode: action.status,
			};
		}
		case GET_BEFORE: {
			return {
				...state,
				fetching: action.fetching,
			};
		}
		case GET_PROFILE: {
			return {
				...state,
				data: action.data,
				fetching: action.fetching,
			};
		}
		case CLEAR_ALL:
			return {
				...state,
				statusCode: 0,
			};

		default:
			return state;
	}
}

export default profileReducer;
