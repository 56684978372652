export const getTwilioToken = (state) => {
	const { chatClientInfo = {} } = state;
	const { twilioToken = '' } = chatClientInfo;

	return twilioToken;
};

export const getChatInitiatingValue = (state) => {
	const {
		isChatInitiating = false,
		fetchingChannels = false,
		isChannelMessageUpdated = false,
	} = state;

	return { isChatInitiating, fetchingChannels, isChannelMessageUpdated };
};

export const getChannelFetchStatus = (state) => {
	const { fetchedAllChannels = false, changeMessageCount } = state;
	return { fetchedAllChannels, changeMessageCount };
};

export const getChatObject = (state) => {
	const { chatClientInfo = false } = state;
	const { chat = null } = chatClientInfo;

	return chat;
};

export const getChatIdentity = (state) => {
	const { chatClientInfo = false } = state;
	const { chatIdentity = null } = chatClientInfo;

	return chatIdentity;
};

export const getChannelsObject = (state) => {
	const { channelsData = {}, fetchNextSet } = state;

	return { channelsData, fetchNextSet };
};

export const getChannels = (state) => {
	const { channelsData = {}, userListedChannels = [] } = state;
	const { items = [] } = channelsData;

	return { channels: items, userListedChannels };
};

export const getActiveChannelConnectingState = (state) => {
	const { connectedChannel = {} } = state;
	const { isConnecting = false, isConnected = false } = connectedChannel;

	return { isConnecting, isConnected };
};

export const getChannelMessageDescriptors = (state) => {
	const { connectedChannel = {}, isReadReceiptUpdated = false } = state;
	const {
		messageDescriptors = {},
		activeChannel = {},
		fetchingMessages,
		channelToBeLoaded,
	} = connectedChannel;

	return {
		messageDescriptors,
		activeChannel,
		fetchingMessages,
		channelToBeLoaded,
		isReadReceiptUpdated,
	};
};

export const getUserDetails = (state, activeChannelId) => {
	const initialState = { avatar: '', dob: '', age: '', gender: '', location: '', name: 'No name' };
	const { userListedChannels = [] } = state;
	const userObject = userListedChannels.find((user) => user.channelSid === activeChannelId);

	if (userObject) {
		const {
			avatarThumb = '',
			dob = '',
			age = '',
			gender = '',
			location = '',
			name = 'No name',
			patientId,
		} = userObject;

		return { avatar: avatarThumb, dob, age, gender, location, name, patientId };
	}

	return initialState;
};

export function getChannelDetails(state, channel) {
	const { eachChannelDetails = {} } = state;

	const channelData = eachChannelDetails[channel.sid] || null;

	if (channelData) {
		channelData.isOnline = channelData.isOnline || false;
		channelData.isNotifiable = channelData.isNotifiable || false;
		channelData.lastMessage = channelData.lastMessage || {};
		channelData.isTyping = channelData.isTyping || false;
	}

	return (
		channelData || {
			isOnline: false,
			isNotifiable: false,
			lastMessage: {},
			isTyping: false,
		}
	);
}
