import styled from 'styled-components';
import { colors } from '../colors';

const HeaderRight = styled.div`
	display: flex;
	align-items: center;
	/* button {
		width: 36px;
		height: 36px;
		padding: 0px;
		border-radius: 100%;
		position: relative;
        margin-right: 20px;
        :after {
			content: '\\e900';
			position: absolute;
			right: -19px;
			top: 13px;
			font-family: 'iconFont' !important;
			color: #696969;
			font-size: 11px;
		}
		+ div {
			right: 0px;
			top: 60px;
			:before {
				content: '';
				position: absolute;
				top: -10px;
				right:25px;
				z-index: -1;
				width: 0px;
				height: 0px;
				border-left: 6px solid transparent;
				border-right: 6px solid transparent;
				border-bottom: 10px solid ${colors.White};
            }
            :after{
                content: '';
                position: absolute;
                top: -11px;
                right: 24px;
                z-index: -2;
                width: 0px;
                height: 0px;
                border-left: 7px solid transparent;
                border-right: 7px solid transparent;
                border-bottom: 11px solid #DFE1E5;
                }
            }
		}		 */
	}
	> div{
		display: flex;
	}
`;

export default HeaderRight;
