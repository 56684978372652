import BoldWoff from '../fonts/inter/Inter-Bold.woff2';
import LightBetaWoff from '../fonts/inter/Inter-Light.woff2';
import MediumWoff from '../fonts/inter/Inter-Medium.woff2';
import RegularWoff from '../fonts/inter/Inter-Regular.woff2';

export const fontRegular = {
	familyName: 'Inter',
	path: RegularWoff,
	locale: 'Inter-Regular.woff2',
	format: 'woff2',
};
export const fontMedium = {
	familyName: 'Inter',
	path: MediumWoff,
	locale: 'Inter-Medium.woff2',
	format: 'woff2',
};
export const fontBold = {
	familyName: 'Inter',
	path: BoldWoff,
	locale: 'Inter-Bold.woff2',
	format: 'woff2',
};
export const fontLight = {
	familyName: 'Inter',
	path: LightBetaWoff,
	locale: 'Inter-Light.woff2',
	format: 'woff2',
};
