import React, { useState, useEffect } from 'react';

import network from '../../stories/assets/images/icons/network.svg';

import NetworkBar from './NetworkBar';
import { Heading } from '../../stories';
import { fontBold } from '../../stories/assets/styles/typography';

function NetworkStatusBar() {
	let interval = null;
	const [counter, setCounter] = useState(0);
	const [isOffline, setIsOffline] = useState(false);

	useEffect(() => {
		if (isOffline)
			interval = setInterval(() => {
				setCounter((counter) => (counter > 10 ? 0 : counter + 1));
			}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [isOffline]);

	useEffect(() => {
		function updateOnlineStatus() {
			setIsOffline(!navigator.onLine);
		}

		window.addEventListener('offline', updateOnlineStatus);
		window.addEventListener('online', updateOnlineStatus);

		return () => {
			window.removeEventListener('offline', updateOnlineStatus);
			window.removeEventListener('online', updateOnlineStatus);
		};
	}, []);

	function reloadPage() {
		document.location.reload();
	}

	return (
		<NetworkBar open={isOffline}>
			<div style={{ width: '20%' }}>
				<img src={network} alt="" />
			</div>
			<div style={{ textAlign: 'left', width: '60%' }}>
				<Heading>
					Waiting for the network
					<span>{`...`.substring(0, (counter % 3) + 1)}</span>
				</Heading>
			</div>
			<div style={{ width: '20%' }}>
				<Heading>
					<a
						type="button"
						href="#"
						onClick={reloadPage}
						style={{
							color: '#80E0FF',
							cursor: 'pointer',
							marginRight: '15px',
							fontFamily: fontBold.familyName,
						}}
					>
						Retry
					</a>
				</Heading>
			</div>
		</NetworkBar>
	);
}

export default NetworkStatusBar;
