import styled from 'styled-components';
import { colors } from '../colors';

const SidebarSubMenuItems = styled.li`
	width: 100%;
	list-style: none;
	a {
		display: flex;
		align-items: center;
		color: ${colors.White};
		transition: ease-in all 0.4s;
		cursor: pointer;
		margin-bottom: 0px;
		text-decoration: none;
		overflow: hidden;
		padding: 8px 20px 8px 60px;
		background: ${colors.BlueDarkX};
		&:hover {
			background: ${colors.BlueDarkX};
			color: ${colors.BlueLight};
		}
	}
	${({ active }) =>
		active &&
		`
	a{
		color: ${colors.BlueLight};
		&:hover{
			color: ${colors.BlueLight};
		}
	}`}
`;

export default SidebarSubMenuItems;
