import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { Fragment, useEffect } from 'react';

import { getWebNotifications } from '../../dashboard.selectors';
import { dateSeparatorConfig } from '../../../../helpers/Constants';
import { dateFormatter } from '../../../../helpers/Utilities';

import Container, {
	NotificationBox,
	Text,
	NotificationHolder,
	NotificationContainer,
} from './notify.component';
import Shimmer from '../../../../components/Shimmer';
import NoDataPlaceHolder from '../../../../components/NoDataPlaceHolder';
import { Paragraph, Icon, Row } from '../../../../stories';

import imageMapper, { linkResolver } from './notify.data';
import { MixPanelService } from '../../../../service/MixpanelService';

function Notifications({ markAllAsRead }) {
	const { fetching, data: { results = [] } = {} } = useSelector(({ AuthStore }) =>
		getWebNotifications(AuthStore)
	);
	MixPanelService.track('notification_list_screen');

	useEffect(
		() => () => {
			markAllAsRead();
		},
		[]
	);

	const groups = results.reduce((groups, data) => {
		const date = data.createdAt.split('T')[0];
		if (!groups[date]) {
			groups[date] = [];
		}
		groups[date].push(data);
		return groups;
	}, {});

	const notificationList = [];

	function listGenerator(date, records) {
		const messages = [];
		records.map((record) => {
			const { id, isRead, data = {}, createdAt } = record;
			const { message, type, patientId, doctorId, coachId, clinicAdminId } = data || {};
			const href = linkResolver({ type, patientId, doctorId, coachId, clinicAdminId });

			function BoxContent() {
				return (
					<>
						<div style={{ textAlign: 'center', width: '20%', position: 'relative' }}>
							<img src={imageMapper(type)} alt="pic" />
							{!isRead && (
								<div
									style={{
										position: 'absolute',
										left: '45%',
										borderRadius: '50%',
										height: '7px',
										width: '7px',
										background: '#536DFE',
									}}
								/>
							)}
						</div>
						<div style={{ width: '80%', padding: '0 10px 0 0', textAlign: 'justify' }}>
							<Text colour="#0F255F">{message}</Text>
							<Text colour="#7985A5" small padding="5px 0 0 0">
								{dateFormatter(createdAt, dateSeparatorConfig.hh_mm_a)}
							</Text>
						</div>
					</>
				);
			}

			messages.push(
				<NotificationBox key={`${id}`} padding="15px 0 0 0" HealthBlocksLink={!!href.goto}>
					{href.goto && (
						<Link to={href.goto}>
							<BoxContent />
						</Link>
					)}
					{!href.goto && <BoxContent />}
				</NotificationBox>
			);

			return record;
		});

		notificationList.push(
			<Fragment key={new Date(date).getTime()}>
				<Text colour="#606E92" padding="0 0 0 10px">
					{dateFormatter(date, dateSeparatorConfig.defaultRecentDate)}
				</Text>
				{messages}
			</Fragment>
		);
	}

	for (const [date, records] of Object.entries(groups)) {
		listGenerator(date, records);
	}

	return (
		<Container>
			<Row
				margin="0 0 10px 0"
				style={{
					justifyContent: 'space-between',
					borderBottom: '1px solid #dfe1e5',
					padding: '10px',
				}}
			>
				<Paragraph>Notifications</Paragraph>
				<section style={{ cursor: 'pointer' }}>
					<Icon iconName="icon-close" />
				</section>
			</Row>
			{/* <p onClick={_ => closeNotification(false)}>Notifications</p> */}
			<NotificationContainer>
				{fetching && <Shimmer count={5} />}
				{!fetching && <NotificationHolder>{notificationList.map((r) => r)}</NotificationHolder>}
				{!fetching && notificationList.length === 0 && <NoDataPlaceHolder />}
			</NotificationContainer>
		</Container>
	);
}

export default Notifications;
