import { Roles } from '../../helpers/Constants';

export default [
	{
		to: '/dashboard',
		icon: 'icon-dashboard',
		label: 'Dashboard',
		visibleTo: [
			Roles.coach,
			Roles.doctor,
			Roles.weCareAdmin,
			Roles.clinicAdmin,
			Roles.customerSupport,
		],
	},
	{
		to: '/patient-invitation',
		icon: 'icon-patient-invitation',
		label: 'Patient invitations',
		visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin, Roles.customerSupport],
	},
	{
		to: '/patients',
		icon: 'icon-patient-management',
		label: 'Patient management',
		visibleTo: [Roles.coach, Roles.doctor, Roles.clinicAdmin, Roles.weCareAdmin],
	},
	{
		to: '/patients',
		icon: 'icon-patient-management',
		label: 'Patients',
		visibleTo: [Roles.customerSupport],
	},
	{
		to: '/chat',
		icon: 'icon-chat',
		label: 'Chat',
		visibleTo: [Roles.coach],
	},
	// {
	// 	to: '/patient-management',
	// 	icon: 'icon-patient-management',
	// 	label: 'Patient management',
	// 	visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin],
	// },
	{
		to: '/clinic-management',
		icon: 'icon-clinic-management',
		label: 'Clinic management',
		visibleTo: [Roles.weCareAdmin],
	},
	{
		to: '/clinic-management',
		icon: 'icon-clinic-management',
		label: 'Clinics',
		visibleTo: [Roles.customerSupport],
	},
	{
		to: '/doctor-management',
		icon: 'icon-doctor-management',
		label: 'Doctor management',
		visibleTo: [Roles.clinicAdmin, Roles.weCareAdmin],
	},
	{
		to: '/doctor-management',
		icon: 'icon-doctor-management',
		label: 'Doctors',
		visibleTo: [Roles.customerSupport],
	},
	{
		to: '/coach-management',
		icon: 'icon-coach-management',
		label: 'Coach management',
		visibleTo: [Roles.weCareAdmin],
	},
	{
		to: '/coach-management',
		icon: 'icon-coach-management',
		label: 'Coaches',
		visibleTo: [Roles.customerSupport],
	},

	{
		to: '/insulin-rbs-chart',
		icon: 'icon-Insulin_RBS_icon',
		label: 'Glucose',
		visibleTo: [Roles.coach, Roles.doctor],
	},
	{
		to: '/customer-support',
		icon: 'icon-icon-customer-support',
		label: 'Support admin',
		visibleTo: [Roles.weCareAdmin],
	},

	{
		to: '/diets',
		icon: 'icon-diet-apple',
		label: 'Diet management',
		visibleTo: [Roles.weCareAdmin, Roles.coach, Roles.doctor, Roles.customerSupport],
	},
	{
		to: '/health-card',
		icon: 'icon-health-card',
		label: 'Health Card',
		visibleTo: [],
	},

	{
		to: '/dashboard/view',
		icon: 'icon-admin-controls',
		label: 'Admin controls',
		visibleTo: [Roles.doctor],
		subMenus: [
			{
				to: '/dashboard/view',
				icon: 'icon-dashboard',
				label: 'Dashboard',
				visibleTo: [Roles.doctor],
			},
			{
				to: '/patients/invitees/view',
				icon: 'icon-patient-invitation',
				label: 'Patient invitations',
				visibleTo: [Roles.doctor],
			},
			{
				to: '/patients/view',
				icon: 'icon-patient-management',
				label: 'Patient management',
				visibleTo: [Roles.doctor],
			},
			{
				to: '/doctors/view',
				icon: 'icon-doctor-management',
				label: 'Doctor management',
				visibleTo: [Roles.doctor],
			},
		],
	},
];
