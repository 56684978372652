const Conversations = require('@twilio/conversations');

const Twilio = {
	ClientChat: null,

	async initiate(token) {
		// await new Conversations.Client(token)
		// 	.then((response) => {
		// 		this.ClientChat = response;
		// 	})
		// 	.catch((error) => {
		// 		this.ClientChat = null;
		// 	});
		try {
			this.ClientChat = await new Conversations.Client(token);
			return this.ClientChat;
		} catch (error) {
			this.ClientChat = null;
			return this.ClientChat;
		}
		// return this.ClientChat;
	},

	async getSubscribedChannels(channelConfig) {
		if (this.ClientChat) {
			const channels = this.ClientChat.getSubscribedConversations(channelConfig);
			return channels;
		}

		return {};
	},

	async getMembers(channel) {
		if (channel) return channel.getParticipants();

		return null;
	},

	getCurrentUser() {
		if (this.ClientChat) {
			return this.ClientChat.user;
		}
		return null;
	},
	getClientInfo() {
		if (this.ClientChat) {
			return this.ClientChat;
		}
		return null;
	},

	async getUser(member) {
		if (member) return member.getUser();
		return null;
	},

	getMessages(channel, messageLimit = 30) {
		if (channel) {
			return channel.getMessages(messageLimit);
		}
		return null;
	},

	getUnconsumedMessagesCount(channel) {
		if (channel) {
			return channel.getUnreadMessagesCount();
		}
		return 0;
	},

	setTyingIndicator(channel) {
		if (channel) channel.typing();
	},

	async sendMessage(activeChannel, message, attributes = {}) {
		// console.log('MEDIA FILE --- SEND MESSAGE--Last');
		if (activeChannel) return activeChannel.sendMessage(message, attributes);
	},

	async advanceLastConsumedMessageIndex(activeChannel, messageIndex) {
		if (activeChannel) return activeChannel.advanceLastReadMessageIndex(messageIndex);
	},

	async setAllMessagesConsumed(activeChannel) {
		if (activeChannel) return activeChannel.setAllMessagesRead();
	},

	async updateLastConsumedMessageIndex(activeChannel, messageIndex) {
		if (activeChannel) return activeChannel.updateLastReadMessageIndex(messageIndex);
	},

	async getPreviousMessages(messageHeaders = {}) {
		if (messageHeaders.hasPrevPage) return messageHeaders.prevPage();
	},

	async getMember(channel, memberSid) {
		if (channel) return channel.getParticipantBySid(memberSid);
	},

	async shutdown() {
		if (this.ClientChat) this.ClientChat.shutdown();
	},

	async setPushRegistrationId({ channelType = 'fcm', token }) {
		if (this.ClientChat) return this.ClientChat.setPushRegistrationId(channelType, token);
	},
	async getPushRegistrationId({ channelType = 'fcm', token }) {
		if (this.ClientChat) return this.ClientChat.getPushRegistrationId(channelType, token);
	},

	async unsetPushRegistrationId(channelType = 'fcm') {
		if (this.ClientChat) return this.ClientChat.unsetPushRegistrationId(channelType);
	},

	handlePushNotification(payload) {
		if (this.ClientChat) return this.ClientChat.handlePushNotification(payload);
	},
};
export default Twilio;
