import styled from 'styled-components';
import { fontMedium, fontRegular } from '../../assets/styles/typography';
import { colors } from '../colors';

const Paragraph = styled.p`
	line-height: 16px;
	font-size: 16px;
	margin: 0px;

	${({ storyInfo }) =>
		storyInfo &&
		`font-size: 14px;
		color: ${colors.Grey6};
		margin:15px 0 5px;
	`};
	${({ patientInfo }) =>
		patientInfo &&
		`font-size: 16px;
		font-weight:600;
		margin-bottom:5px;
		color: #0F255F;
	`};
	${({ mediaDetailsUpdate }) =>
		mediaDetailsUpdate &&
		`@media screen and (max-width: 750px) {
			flex-direction: column;
		}
`}
	${({ AverageFBS }) =>
		AverageFBS &&
		`font-weight: 900;
		color: #0F255F;
		font-size: 13px;
	`};
	${({ lastUpdateDate }) =>
		lastUpdateDate &&
		`background:#E1E5ED !important;
		border-radius: 6px;
		padding:4px;
		color:#0F255F !important;
		font-style: italic;
    `}
	${({ textCenter }) =>
		textCenter &&
		`text-align: center;
    `};
	${({ headerNameColor }) =>
		headerNameColor &&
		`color: #77819A !important;
    `};
	${({ RecommendRange }) =>
		RecommendRange &&
		`display: flex !important;
        align-items: center !important;
        justify-content: flex-start !important;
        text-align: left !important;
        margin-right: auto !important;
        margin-top: 10px !important;
        font-size: 13px !important;
    `};
	${({ noWhiteSpace }) =>
		noWhiteSpace &&
		`white-space: nowrap; padding-right: 8px; overflow: hidden; text-overflow: ellipsis;`};

	${({ chatBeginInfo }) =>
		chatBeginInfo &&
		`font-size: 14px;
		color: rgba(15, 37, 95, 0.5);
		margin:20px 0 5px;
		max-width: 400px;
		text-align: center;
		line-height: 18px;
		`};
	${({ dashBlockInfo }) =>
		dashBlockInfo &&
		`font-size: 13px;
		line-height: 15px;
		color: ${colors.BlueBase};
		opacity: 0.6;
		margin-top:6px;
		font-family: ${fontMedium.familyName};
		font-weight:500;
		`};
	${({ chatInfo }) =>
		chatInfo &&
		`font-size: 14px;
		color: ${colors.BlueBase};
		margin:8px 0 0 0;
		line-height: 16px;
		white-space:nowrap;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		span{
			padding-right: 7px; color:#536DFE; font-size: 16px;
		}`};
	${({ textBlue }) =>
		textBlue &&
		`font-size: 14px;
		color: ${colors.BlueBase};
		margin:8px 0 0 0;
		line-height: 16px;
		span{
			padding-right: 7px; color:#536DFE; font-size: 16px;
		}`};
	${({ textBlack }) =>
		textBlack &&
		`font-size: 14px;
		color: ${colors.Black};
		margin:8px 0 0 0;
		line-height: 16px;
		span{
			padding-right: 7px; color:#536DFE; font-size: 16px;
		}`};
	${({ textBlueNM }) =>
		textBlueNM &&
		`font-size: 14px;
		color: ${colors.BlueBase};
		margin:0 0 5px 0;
		line-height: 20px;
		font-family: ${fontRegular.familyName};
		font-weight:normal;
		span{
			padding-right: 7px; color:#536DFE; font-size: 16px;
		}`};
	${({ textBlueSM }) =>
		textBlueSM &&
		`font-size: 13px;
		color: ${colors.BlueBase};
		margin:0 0 5px 0;
		line-height: 22px;
		font-family: ${fontRegular.familyName};
		font-weight:normal;
		`};
	${({ textBlueLG }) =>
		textBlueLG &&
		`font-size: 16px;
		color: ${colors.BlueBase};
		margin:0;
		line-height: 22px;
		span{
			font-family: ${fontMedium.familyName};
			font-weight:500;
		}
		`};
	${({ chatTime }) =>
		chatTime &&
		`font-size: 12px;
		color: #8A91A3;
		margin: 6px 0 4px;
		line-height: 14px;
		white-space: nowrap;
		span{
            padding-left: 6px;
            color:${colors.BlueBase};
			&.chatUnread{
				font-size: 10px;
			}
			&.Unread{
				color:${colors.GreyC4};
			}
		}
		`};
	${({ ChartInfoLabel }) =>
		ChartInfoLabel &&
		`font-size: 14px;
		line-height: 16px;
		color:${colors.BlueBase};
		position: absolute;
		top: 140px;
		padding-top: 4px;
		margin-left:-5px;
		`};
	${({ ReportsInfoTime }) =>
		ReportsInfoTime &&
		`font-size: 13px;
		line-height: 15px;
        color:#334677;
		`}
	${({ Lowercase }) => Lowercase && `text-transform: lowercase;`};
	${({ ReportsInfoDetails }) =>
		ReportsInfoDetails &&
		`font-size: 14px;
		line-height: 22px;
		margin-top: 10px;
        text-align: justify;
        font-family: ${fontRegular.familyName} !important;
		font-weight:normal;
		color:${colors.BlueBase};
		`};
	${({ ChartDetailsLabel }) =>
		ChartDetailsLabel &&
		`font-size: 14px;
		line-height: 16px;
		color:${colors.BlueBase};
		padding-top: 4px;
		font-family: ${fontRegular.familyName} !important;
		font-weight:normal;
		`};
	${({ dietIconText }) =>
		dietIconText &&
		`font-size: 14px;
		line-height: 16px;
		color:#585858;
		padding-left: 10px;
		white-space:nowrap;
		`};
	${({ dietIconTextChange }) =>
		dietIconTextChange &&
		`font-size: 14px;
		line-height: 16px;
		color:${colors.BlueLight};
		padding-left: 10px;
		white-space:nowrap;
		font-family: ${fontMedium.familyName};
		font-weight:500;
        `};
	${({ wrapTextMultiLine, expanded }) =>
		wrapTextMultiLine &&
		`width: auto;
		overflow: hidden;
        text-overflow: ellipsis;
        ${!expanded && `display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;`}
    `};
	${({ BoldParagraph }) =>
		BoldParagraph &&
		`font-family: ${fontMedium.familyName} !important;
		font-weight:500;
		font-weight:500;
		`};
	${({ smallText }) =>
		smallText &&
		`font-size: 13px;
		color: ${colors.Black};
		margin:15px 0 0px;
		opacity:0.6;
    `}
	${({ AverageFBSSmallText }) =>
		AverageFBSSmallText &&
		`font-size: 13px;
		color: ${colors.Black};
		// margin:15px 0 0px;
		opacity:0.6;
    `}
	${({ textBGRed }) =>
		textBGRed &&
		`justify-content: center;
		display: flex;
flex-direction: row;
align-text: center;
padding: 1px 6px;
background: #F5A6A6;
border-radius: 4px;
    `}
	${({ weightTrack }) =>
		weightTrack &&
		`font-weight: normal;
font-size: 13px;
text-align:right;

color: #87888B;
    `}
	${({ fontChatRegular }) =>
		fontChatRegular &&
		`font-family: ${fontRegular.familyName} !important;
		font-weight:normal;
    `}
		font-family: ${fontRegular.familyName};
	font-weight: normal;
	color: ${(props) => props.color};
	font-style: ${(props) => props.fontstyle};
	margin: ${(props) => props.margin};
	padding: ${(props) => props.padding};
	padding-bottom: ${(props) => props.paddingBottom};
	margin-bottom: ${(props) => props.marginBottom};
	${({ textCase }) => textCase && `text-transform: ${textCase} !important;`}
	${({ wrapText }) =>
		wrapText &&
		`width: 315px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@media screen and (max-width: 600px) {
			width: 150px;
		}
    `};
`;

export default Paragraph;
