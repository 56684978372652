import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Link,
	//  useLocation, useNavigation, useParams
} from 'react-router-dom';

import SideBarList from './sidebar.router';
import { getProfileDetails } from '../../pages/DashBoard/dashboard.selectors';

import { Icon, Label } from '../../stories';
import {
	SidebarListItems,
	SidebarSubMenu,
	SidebarSubMenuItems,
} from '../../stories/components/sidebar';
import { removeChannelToBeLoad } from '../../pages/Chat/chat.store';

function SideBarItem(props) {
	const { location = {} } = props;
	const activeItem = location.pathname;
	const dispatch = useDispatch();

	const profileDetails = useSelector(({ AuthStore }) => getProfileDetails(AuthStore));
	const { role = '', roles = [], enableInsulinRBS = false } = profileDetails;

	return SideBarList.filter((list) => {
		const permissions = list.visibleTo || [];
		return permissions.length !== 0
			? (permissions.includes(role) && !list.label.includes('Insulin')) ||
					(permissions.includes(role) && list.label.includes('Insulin') && enableInsulinRBS)
			: false;
	}).map((link, index) => {
		const activeLink = activeItem === link.to ? { active: true } : {};
		const hasSubMenus = !!link.subMenus;

		return (
			<SidebarListItems
				key={index}
				{...activeLink}
				SubMenu={hasSubMenus}
				style={{ background: activeItem === link.to ? '#536DFE' : '' }}
			>
				{/* Currently, length > 1 means the user has more than 1 role */}
				{hasSubMenus && roles.length > 1 && (
					<>
						<div>
							<Icon iconName={link.icon} />
							<Label>{link.label}</Label>
						</div>
						<SidebarSubMenu show>
							{link.subMenus.map((menu, i) => (
								<SidebarSubMenuItems
									key={`SIDE_MENU-${index}-${i}`}
									active={activeItem === menu.to}
								>
									<Link
										to={{ pathname: menu.to, refresh: true }}
										onClick={() => dispatch(removeChannelToBeLoad())}
									>
										{menu.label}
									</Link>
								</SidebarSubMenuItems>
							))}
						</SidebarSubMenu>
					</>
				)}
				{!hasSubMenus && (
					<Link
						to={{ pathname: link.to, refresh: true }}
						onClick={() => dispatch(removeChannelToBeLoad())}
					>
						<Icon iconName={link.icon} />
						<div>{link.label}</div>
					</Link>
				)}
			</SidebarListItems>
		);
	});
}

// function withRouter(Component) {
// 	function ComponentWithRouterProp(props) {
// 		const location = useLocation();
// 		const navigate = useNavigation();
// 		const params = useParams();
// 		return <Component {...props} router={{ location, navigate, params }} />;
// 	}

// 	return ComponentWithRouterProp;
// }

export default memo(SideBarItem);
