import styled from 'styled-components';
import { fontMedium } from '../../assets/styles/typography';
import { colors } from '../colors';
import FormLabel from '../formGroup/formLabel';

const Textarea = styled.textarea`
	display: inline-block;
	width: 100%;
	background: ${colors.White};
	border: 1px solid ${colors.BorderColorTB};
	border-radius: 6px;
	padding: 16px;
	font-size: 14px;
	transition: ease-in all 0.4s;
	box-sizing: border-box;
	font-family: ${fontMedium.familyName};
	font-weight: 500;
	resize: none;
	${colors.BlueBase}${colors.BlueBase}
	border-color: ${(props) => props.error && colors.Red};
	border-color: ${(props) => props.warning && colors.Yellow};

	background: ${(props) => props.disabled && colors.Grey100};
	opacity: ${(props) => props.disabled && '.5'};
	pointer-events: ${(props) => props.disabled && 'none'};
	color: ${colors.BlueBase};
	:focus {
		outline: none;
		border: 1px solid #8ba3e2;
	}
	${({ floatingTextarea }) =>
		floatingTextarea &&
		`&:focus + ${FormLabel}, &:not(:placeholder-shown) ~ ${FormLabel}{
            position: absolute;
            top: -6px;
            left: 12px;
            opacity: 1;
            font-size: 12px;
            line-height: 14px;
            background: ${colors.White};
            width: auto;
            padding:0 5px;
            color: ${colors.BlueBase};
          }
          &:focus {
              border-color: ${colors.BlueBase};
          }
	`}
	${({ InputActive }) =>
		InputActive &&
		`+ ${FormLabel} {
            position: absolute;
            top: -6px;
            left: 12px;
            opacity: 1;
            font-size: 12px;
            line-height: 14px;
            background: ${colors.White};
            width: auto;
            padding:0 5px;
			color: ${colors.BlueBase};
		  }
		  border-color: ${colors.BlueBase};
    `}
`;

export default Textarea;
